import { ReviewsProps } from "api/Reviews";
import React, { useState } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import {
  EditOutlined,
  StarFilled,
  PlusCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
import { Button } from "antd";
import { Space } from "shared/ui";
import LightboxShow from "./LightboxShow";
import ModalEdit from "./ModalEdit";
const Wrap = styled.div`
  margin-bottom: 24px;
`;
const Content = styled.div<{ status?: string }>`
  padding: 12px 24px;
  &:hover {
    box-shadow: rgba(30, 31, 33, 0.12) 0px 5px 25px;
  }
  background-color: ${(props) =>
    props.status === "PROCESSED" ? "rgb(58, 175, 169, 0.05)" : ""};
  border-radius: 8px;
`;
const Header = styled.div`
  position: relative;
`;

const StatusPlate = styled.div<{ status: string }>`
  position: absolute;
  padding: 2px 4px;
  font-size: 12px;
  border-radius: 12px;
  right: 0;
  top: 0;
  color: white;
  background-color: ${(props) =>
    props.status === "PROCESSED" ? "rgb(58, 175, 169)" : "#ccc"};
`;

const ReviewPartWrap = styled.div`
  position: relative;
  border: 1px solid rgba(125, 125, 125, 0.2);
  border-left: 0px;
  border-right: 0px;
  padding: 4px 160px 4px 4px;
`;

const IconWrap = styled.div`
  margin-right: 8px;
`;
const Comment = styled.div`
  display: flex;
`;
const CommentText = styled.div``;

const AnswerPartWrap = styled.div`
  position: relative;
  padding-left: 80px;
`;

export const HeaderItem = (props: { data: ReviewsProps }) => {
  const date = dayjs(props.data.published_at).format(`DD.MM.YYYY HH:mm`);
  return (
    <Header>
      {date}
      <StarFilled style={{ color: "#ffd500", marginLeft: 16 }} />{" "}
      {props.data.rating} {props.data.author_name} <br />
      {props.data.article} {props.data.productName}
      <StatusPlate status={props.data.status!}>{props.data.status}</StatusPlate>
    </Header>
  );
};

export const ReviewItem = (props: { data: ReviewsProps }) => {
  const withoutText =
    !props.data.text.positive &&
    !props.data.text.negative &&
    !props.data.text.comment;
  return (
    <ReviewPartWrap>
      {withoutText && "Без комментария"}
      {props.data.text.positive && (
        <Comment>
          <IconWrap>
            <PlusCircleFilled style={{ color: "#3aafa9" }} />
          </IconWrap>
          <CommentText>{props.data.text.positive}</CommentText>
        </Comment>
      )}
      {props.data.text.negative && (
        <Comment>
          <IconWrap>
            <MinusCircleFilled style={{ color: "rgb(158, 175, 169,0.5)" }} />
          </IconWrap>
          <CommentText> {props.data.text.negative}</CommentText>
        </Comment>
      )}
      {props.data.text.comment && (
        <Comment>
          <IconWrap>
            <EditOutlined style={{ color: "rgb(158, 175, 169,0.8)" }} />
          </IconWrap>
          <CommentText> {props.data.text.comment}</CommentText>
        </Comment>
      )}
      {!!props.data.media_links?.photos?.length && (
        <LightboxShow images={props.data.media_links?.photos} />
      )}
    </ReviewPartWrap>
  );
};
export const AnswerItem = (props: {
  data: ReviewsProps;
  onUpdate: (id: number, data: Partial<ReviewsProps>) => Promise<void>;
}) => {
  return (
    <AnswerPartWrap>
      <div>{props.data.answer?.text}</div>
      {props.data.status === "WAITING" && (
        <div style={{ marginTop: 8 }}>
          <ActionItem data={props.data} onUpdate={props.onUpdate}></ActionItem>
        </div>
      )}
      {/* {props.data.answer?.status} */}
    </AnswerPartWrap>
  );
};

export const ActionItem = (props: {
  data: ReviewsProps;
  onUpdate: (id: number, data: Partial<ReviewsProps>) => Promise<void>;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleUpdate = async (text: string) => {
    await props.onUpdate(props.data.id, {
      answer: {
        ...(props.data.answer || {
          published_at: null,
          generated: false,
          status: "CREATED",
          created_at: new Date().toJSON(),
        }),
        text,
      },
    });
    setOpenModal(false);
  };
  const handleApproved = async (status: string) => {
    await props.onUpdate(props.data.id, {
      answer: {
        ...props.data.answer!,
        status,
      },
    });
  };
  return (
    <Header>
      <Button onClick={() => setOpenModal(true)}>
        {props.data.answer ? "Редактировать" : "Создать"}
      </Button>
      <Space size={16} style={{ display: "inline-block" }} />
      {["CREATED", "RESET"].includes(props.data.answer?.status || "") && (
        <Button onClick={() => handleApproved("APPROVED")} type="primary">
          Отправить
        </Button>
      )}
      {props.data.answer?.status === "APPROVED" && (
        <Button onClick={() => handleApproved("CREATED")} type="primary" danger>
          Отменить оправку
        </Button>
      )}
      {openModal && (
        <ModalEdit
          data={props.data}
          onClose={() => {
            setOpenModal(false);
          }}
          onUpdate={handleUpdate}
        />
      )}
    </Header>
  );
};

export const ReviewsItem = (props: {
  data: ReviewsProps;
  onUpdate: (id: number, data: Partial<ReviewsProps>) => Promise<void>;
}) => {
  return (
    <Wrap>
      <Content status={props.data.status}>
        <HeaderItem data={props.data} />
        <Space size={8} axis="vertical" />
        <ReviewItem data={props.data} />
        <Space size={8} axis="vertical" />
        <AnswerItem data={props.data} onUpdate={props.onUpdate} />
      </Content>
    </Wrap>
  );
};
