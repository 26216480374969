import React from "react";
import Layout from "../shared/Layout";
import Deliveries from "../Deliveries";

const DeliveriesPage = () => {
  return (
    <Layout>
      <Deliveries></Deliveries>
    </Layout>
  );
};

export default DeliveriesPage;
