import React, { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { fetchApi } from "../api/fetch";
import useAuthContext from "../App/useAuthContext";
const useLogin = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { setAuthorized } = useAuthContext();
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      const result = await fetchApi("/api/auth/login", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({ username: login, password }),
      });

      const data = await result.json();
      if (!data.statusCode) {
        const typedData: {
          accessToken: string;
          expiresIn: string;
          username: string;
        } = data;

        localStorage.setItem("auth-token", typedData.accessToken);
        setAuthorized(true);
        return;
      }
      setError(JSON.stringify(data));
    } catch (e) {
      console.log(e);
      setError(JSON.stringify(e));
    }
  };
  return { login, setLogin, password, setPassword, handleSubmit };
};
const LoginFrom = () => {
  const { login, setLogin, password, setPassword, handleSubmit } = useLogin();
  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Логин</Form.Label>
          <Form.Control
            type="login"
            placeholder="Логин"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Пароль</Form.Label>
          <Form.Control
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Войти
        </Button>
      </Form>
    </Container>
  );
};

export default LoginFrom;
