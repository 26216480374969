import fetchApiWithAuth from "../fetch";

export type ProductProps = {
  id: string;
  name: string;
  article: string;
};

export const getProducts = async () => {
  const result = await fetchApiWithAuth("/deliveries/api/products");
  const data = (await result.json()) as ProductProps[];
  return data;
};
