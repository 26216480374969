import React from "react";
import ReviewsWithoutAnswer from "../Answers/ReviewsWithoutAnswer";
import { Container } from "shared/ui";
import Layout from "../shared/Layout";

const AnswersPage = () => {
  return (
    <Layout>
      <Container>
        <ReviewsWithoutAnswer />
      </Container>
    </Layout>
  );
};

export default AnswersPage;
