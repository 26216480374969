import { Button } from "antd";
import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 100px);
  border: 1px solid #ccc;
  ${Row}:nth-child(odd) {
    background-color: rgba(50, 50, 50, 0.1);
  }
`;

export const Col = styled.div`
  display: flex;
  max-width: 200px;
  width: 100%;
  padding: 0 4px;
  border: 1px solid #ccc;
  /* border-left: 0; */
  border-right: 0;
  align-items: center;
  min-height: 32px;
`;

export const InnerCol = styled.div`
  display: flex;
  flex: 0 0 50%;
`;

export const ColValue = styled(Col)`
  &.need_update {
    background-color: #ffe0e2;
  }
`;

export const Header = styled.div`
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px;
  z-index: 1;
`;

export const SmallButton = styled(Button)`
  padding: 0px 4px;
  height: auto;
  margin: 4px 0;
`;
