import styled from "styled-components";
import React, { PropsWithChildren } from "react";
import { ReactComponent as SortIcon } from "./SortOrder.svg";

const SortHeaderContainer = styled.div`
  cursor: pointer;
`;

const Sort = styled(SortIcon)<{ direction: string }>`
  display: block;
  ${(props) =>
    props.direction === "descending" &&
    "transform: rotate(-180deg) scaleX(-1)"};
  path {
    fill-opacity: ${(props) => (props.direction ? 0.6 : 0.35)};
  }
`;

const SortHeader = ({
  children,
  onClick,
  direction,
}: PropsWithChildren<{ onClick: () => void; direction: string }>) => (
  <SortHeaderContainer>
    {children}
    <Sort direction={direction} onClick={onClick} />
  </SortHeaderContainer>
);

export default SortHeader;
