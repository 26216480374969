const columnOptions = ["wb", "ozon", "ya", "sber", "ali"]
  .map((el) => [
    { value: el + "_price", label: "Базовая цена " + el.toUpperCase() },
    {
      value: el + "_discont_price",
      label: "Цена со скидкой " + el.toUpperCase(),
    },
  ])
  .flat();

export default columnOptions;
