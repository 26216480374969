import React from "react";
import { Container, Row } from "react-bootstrap";
import PriceMonitoring from "../PriceMonitoring";
import Layout from "../shared/Layout";

const PriceMonitoringPage = () => {
  return (
    <Layout>
      <PriceMonitoring />
    </Layout>
  );
};

export default PriceMonitoringPage;
