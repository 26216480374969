import React from "react";
import Answers from "../Answers";
import { Container } from "shared/ui";
import Layout from "../shared/Layout";

const AnswersPage = () => {
  return (
    <Layout>
      <Container>
        <Answers />
      </Container>
    </Layout>
  );
};

export default AnswersPage;
