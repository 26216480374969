import { useState, useEffect } from "react";
import { getSales, getSalesXlsx } from "../api/Sales";
import { SeriasFilterState } from "./useSeriasFilterState";
import FileSaver from "file-saver";
const useChartData = (
  serias: SeriasFilterState[],
  dateFrom: Date | null,
  dateTo: Date | null
) => {
  const [state, setState] = useState<
    {
      title: string;
      values: { date: number; orderCount: number; revenue: number }[];
    }[]
  >([]);
  const fetchData = async () => {
    const result = await Promise.all(
      serias.map(async (el) => {
        const result = await getSales({
          brand: el.brand,
          article: el.name.map((el) => el.article),
          dateFrom: dateFrom
            ? dateFrom.toJSONWithoutTimezone().slice(0, 10)
            : undefined,
          dateTo: dateTo
            ? dateTo.toJSONWithoutTimezone().slice(0, 10)
            : undefined,
          marketplace: el.marketplace,
          categories: el.category.map((el) => el.id),
        });
        return { title: el.title, values: result };
      })
    );
    setState(result);
  };

  const fetchXlsx = async () => {
    const response = await getSalesXlsx(
      serias.map((el) => ({
        title: el.title,
        brand: el.brand,
        article: el.name.map((el) => el.article),
        dateFrom: dateFrom
          ? dateFrom.toJSONWithoutTimezone().slice(0, 10)
          : undefined,
        dateTo: dateTo
          ? dateTo.toJSONWithoutTimezone().slice(0, 10)
          : undefined,
        marketplace: el.marketplace,
        categories: el.category.map((el) => el.id),
      }))
    );

    if (!response.ok) {
      return;
    }
    const blobPart = await response.blob();
    const blob = new Blob([blobPart], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(blob);
  };

  useEffect(() => {
    fetchData();
  }, [serias, dateFrom, dateTo]);
  return { state, fetchXlsx };
};

export default useChartData;
