import React, { useState } from "react";
import { SmallTd90, FlexWrap, SmallTd190 } from "./RemnantsStyled";
import { Button, Form } from "react-bootstrap";
import { ColumnControl } from "./useColumnControl";
import SortHeaderContainer from "./SortHeader";
import { SortConfig } from "./useSortableData";
import { RemnantRowProps } from "./common";
import { Paginator, usePaginator } from "../shared/Paginator";
import { useFilter } from "./useFilter";
import { Space } from "shared/ui";
import FileSaver from "file-saver";
import { getRemnantsXlsx } from "api/ProductRemnants";

const fetchXlsx = async () => {
  const response = await getRemnantsXlsx();

  if (!response.ok) {
    return;
  }
  const blobPart = await response.blob();
  const blob = new Blob([blobPart], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(blob);
};

const FullRemnantsHeader = ({
  columnControl,
  toggleSort,
  sortConfig,
  paginator,
  pageSize,
  setPageSize,
  filters,
}: {
  columnControl: ColumnControl;
  toggleSort: (key: keyof RemnantRowProps) => void;
  paginator: ReturnType<typeof usePaginator>;
  pageSize: number;
  setPageSize: (v: number) => void;
  sortConfig:
    | SortConfig<
        RemnantRowProps & {
          useInCombo: boolean;
        }
      >
    | undefined;
  filters: ReturnType<typeof useFilter>;
}) => {
  const colSpanCount =
    1 +
    (columnControl.remnantsChecked ? 1 : 0) +
    (columnControl.reservedChecked ? 6 : 0) +
    (columnControl.comboDistributionChecked ? 3 : 0) +
    (columnControl.distributionChecked ? 5 : 0) +
    (columnControl.calculationChecked ? 5 : 0) +
    (columnControl.actionsChecked ? 1 : 0) +
    (columnControl.disabledSendByMarketplaceChecked ? 5 : 0);
  const [pageSizeLocal, setPageSizeLocal] = useState(pageSize);
  return (
    <thead style={{ position: "sticky", top: 0 }}>
      <tr>
        <th colSpan={colSpanCount}>
          Фильтры
          <FlexWrap>
            <Form.Check
              type={"checkbox"}
              checked={filters.showCombo}
              onChange={(e) => filters.setShowCombo(!filters.showCombo)}
              label={`Кобмо`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={filters.showSingle}
              onChange={(e) => filters.setShowSingle(!filters.showSingle)}
              label={`Единичные`}
              style={{ marginRight: 32 }}
            ></Form.Check>
            <Form.Group style={{ marginBottom: "8px", marginRight: 32 }}>
              <Form.Label>Количество элементов</Form.Label>
              <Form.Control
                placeholder="Название"
                type="number"
                value={pageSizeLocal}
                onChange={(e) => setPageSizeLocal(+e.target.value)}
                onBlur={(e) => setPageSize(pageSizeLocal)}
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "8px" }}>
              <Form.Label>Фильтр по артикулу </Form.Label>
              <Form.Control
                placeholder="артикулу"
                value={filters.search}
                onChange={(val) => {
                  filters.setSearch(val.target.value);
                }}
                // onBlur={(e) => setPageSize(pageSizeLocal)}
              />
            </Form.Group>
          </FlexWrap>
          <FlexWrap>
            <Paginator
              items={paginator.paginatorItems}
              currentPageNumber={paginator.currentPageNumber}
              setCurrentPageNumber={paginator.setCurrentPageNumber}
              style={{ marginTop: 8, display: "inline-flex" }}
            />
            <Space />
            <Button onClick={fetchXlsx}>Скачать форму</Button>
          </FlexWrap>
        </th>
      </tr>
      <tr>
        <th colSpan={colSpanCount}>
          Столбцы
          <FlexWrap>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.remnantsChecked}
              onChange={(e) =>
                columnControl.update("remnants", !columnControl.remnantsChecked)
              }
              label={`Остаток`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.reservedChecked}
              onChange={(e) =>
                columnControl.update("reserved", !columnControl.reservedChecked)
              }
              label={`Резерв`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.comboDistributionChecked}
              onChange={(e) =>
                columnControl.update(
                  "combo_distribution",
                  !columnControl.comboDistributionChecked
                )
              }
              label={`Распределение на комбо`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.distributionChecked}
              onChange={(e) =>
                columnControl.update(
                  "distribution",
                  !columnControl.distributionChecked
                )
              }
              label={`Распределение`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.calculationChecked}
              onChange={(e) =>
                columnControl.update(
                  "calculation",
                  !columnControl.calculationChecked
                )
              }
              label={`Результат`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.actionsChecked}
              onChange={(e) =>
                columnControl.update("actions", !columnControl.actionsChecked)
              }
              label={`Действия`}
              style={{ marginRight: 16 }}
            ></Form.Check>
            <Form.Check
              type={"checkbox"}
              checked={columnControl.disabledSendByMarketplaceChecked}
              onChange={(e) =>
                columnControl.update(
                  "disabled_send_by_marketplace",
                  !columnControl.disabledSendByMarketplaceChecked
                )
              }
              label={`Блокировка отправки по маркетплейсам`}
              style={{ marginRight: 16 }}
            ></Form.Check>
          </FlexWrap>
        </th>
      </tr>
      <tr>
        <th style={{ minWidth: 250 }}>Товар</th>
        {columnControl.remnantsChecked && (
          <th style={{ width: 90 }}>Остаток</th>
        )}
        {columnControl.reservedChecked && <th colSpan={6}>Резерв</th>}
        {columnControl.comboDistributionChecked && (
          <th colSpan={3}>Распределение на комбо</th>
        )}
        {columnControl.distributionChecked && (
          <th colSpan={5}>Распределение</th>
        )}
        {columnControl.calculationChecked && <th colSpan={5}>Результат</th>}
        {columnControl.actionsChecked && <th>Действия</th>}
        {columnControl.disabledSendByMarketplaceChecked && (
          <th colSpan={5}>Блокировка отправки</th>
        )}
      </tr>
      <tr>
        <th style={{ minWidth: 250 }}>
          <SortHeaderContainer
            onClick={() => {
              toggleSort("article");
            }}
            direction={
              sortConfig?.key === "article" ? sortConfig.direction : ""
            }
          />
        </th>
        {columnControl.remnantsChecked && (
          <th style={{ width: 90 }}>
            <SortHeaderContainer
              onClick={() => {
                toggleSort("remnant");
              }}
              direction={
                sortConfig?.key === "remnant" ? sortConfig.direction : ""
              }
            />
          </th>
        )}
        {columnControl.reservedChecked && (
          <>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_wildberries");
                }}
                direction={
                  sortConfig?.key === "reserved_in_wildberries"
                    ? sortConfig.direction
                    : ""
                }
              >
                wb
              </SortHeaderContainer>
            </SmallTd90>

            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_ozon");
                }}
                direction={
                  sortConfig?.key === "reserved_in_ozon"
                    ? sortConfig.direction
                    : ""
                }
              >
                ozon
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_ya");
                }}
                direction={
                  sortConfig?.key === "reserved_in_ya"
                    ? sortConfig.direction
                    : ""
                }
              >
                ya
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_sber");
                }}
                direction={
                  sortConfig?.key === "reserved_in_sber"
                    ? sortConfig.direction
                    : ""
                }
              >
                sber
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_ali");
                }}
                direction={
                  sortConfig?.key === "reserved_in_ali"
                    ? sortConfig.direction
                    : ""
                }
              >
                ali
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("reserved_in_combo");
                }}
                direction={
                  sortConfig?.key === "reserved_in_combo"
                    ? sortConfig.direction
                    : ""
                }
              >
                combo
              </SortHeaderContainer>
            </SmallTd90>
          </>
        )}
        {columnControl.comboDistributionChecked && (
          <>
            <SmallTd90 data-column="combo" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_combo");
                }}
                direction={
                  sortConfig?.key === "share_in_combo"
                    ? sortConfig.direction
                    : ""
                }
              >
                на кобмо
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("useInCombo");
                }}
                direction={
                  sortConfig?.key === "useInCombo" ? sortConfig.direction : ""
                }
              >
                остаток на комбо
              </SortHeaderContainer>
            </SmallTd90>
            <SmallTd90>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("useInCombo");
                }}
                direction={
                  sortConfig?.key === "useInCombo" ? sortConfig.direction : ""
                }
              >
                остаток шт
              </SortHeaderContainer>
            </SmallTd90>
          </>
        )}
        {columnControl.distributionChecked && (
          <>
            <SmallTd190 data-column="wb" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_wildberries");
                }}
                direction={
                  sortConfig?.key === "share_in_wildberries"
                    ? sortConfig.direction
                    : ""
                }
              >
                wb
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190 data-column="ozon" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_ozon");
                }}
                direction={
                  sortConfig?.key === "share_in_ozon"
                    ? sortConfig.direction
                    : ""
                }
              >
                ozon
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190 data-column="ya" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_ya");
                }}
                direction={
                  sortConfig?.key === "share_in_ya" ? sortConfig.direction : ""
                }
              >
                ya
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190 data-column="sber" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_sber");
                }}
                direction={
                  sortConfig?.key === "share_in_sber"
                    ? sortConfig.direction
                    : ""
                }
              >
                sber
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190 data-column="ali" data-row="header">
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("share_in_ali");
                }}
                direction={
                  sortConfig?.key === "share_in_ali" ? sortConfig.direction : ""
                }
              >
                ali
              </SortHeaderContainer>
            </SmallTd190>
          </>
        )}
        {columnControl.calculationChecked && (
          <>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("calculeted_wildberries");
                }}
                direction={
                  sortConfig?.key === "calculeted_wildberries"
                    ? sortConfig.direction
                    : ""
                }
              >
                wb
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("calculeted_ozon");
                }}
                direction={
                  sortConfig?.key === "calculeted_ozon"
                    ? sortConfig.direction
                    : ""
                }
              >
                ozon
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("calculeted_ya");
                }}
                direction={
                  sortConfig?.key === "calculeted_ya"
                    ? sortConfig.direction
                    : ""
                }
              >
                ya
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("calculeted_sber");
                }}
                direction={
                  sortConfig?.key === "calculeted_sber"
                    ? sortConfig.direction
                    : ""
                }
              >
                sber
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("calculeted_ali");
                }}
                direction={
                  sortConfig?.key === "calculeted_ali"
                    ? sortConfig.direction
                    : ""
                }
              >
                ali
              </SortHeaderContainer>
            </SmallTd190>
          </>
        )}
        {columnControl.actionsChecked && <SmallTd90></SmallTd90>}
        {columnControl.disabledSendByMarketplaceChecked && (
          <>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("disabled_send_to_wildberries");
                }}
                direction={
                  sortConfig?.key === "disabled_send_to_wildberries"
                    ? sortConfig.direction
                    : ""
                }
              >
                wb
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("disabled_send_to_ozon");
                }}
                direction={
                  sortConfig?.key === "disabled_send_to_ozon"
                    ? sortConfig.direction
                    : ""
                }
              >
                ozon
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("disabled_send_to_ya");
                }}
                direction={
                  sortConfig?.key === "disabled_send_to_ya"
                    ? sortConfig.direction
                    : ""
                }
              >
                ya
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("disabled_send_to_sber");
                }}
                direction={
                  sortConfig?.key === "disabled_send_to_sber"
                    ? sortConfig.direction
                    : ""
                }
              >
                sber
              </SortHeaderContainer>
            </SmallTd190>
            <SmallTd190>
              <SortHeaderContainer
                onClick={() => {
                  toggleSort("disabled_send_to_ali");
                }}
                direction={
                  sortConfig?.key === "disabled_send_to_ali"
                    ? sortConfig.direction
                    : ""
                }
              >
                ali
              </SortHeaderContainer>
            </SmallTd190>
          </>
        )}
      </tr>
    </thead>
  );
};

export default FullRemnantsHeader;
