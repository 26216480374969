import React, { useEffect, useState } from "react";
import { Table, Container, Form, Button } from "react-bootstrap";
import { RemnatsProps } from "../api/ProductRemnants";
export type MPData = {
  last_stock?: number;
  need_update_stock?: boolean;
  last_update?: Date;
};
export type DisabledSend = {
  disabled_send_to_wildberries: boolean | null;
  disabled_send_to_ozon: boolean | null;
  disabled_send_to_ya: boolean | null;
  disabled_send_to_sber: boolean | null;
  disabled_send_to_ali: boolean | null;
};
export type PrepareRemnantRowProps = Pick<
  RemnatsProps,
  | "article"
  | "is_combo"
  | "name"
  | "wb_data"
  | "ozon_data"
  | "ya_data"
  | "sber_data"
  | "ali_data"
> &
  RemnatsProps["product_remnants"] & {
    useInCombo: boolean;
    comboComposition?:
      | {
          article: string;
          value: number;
        }[]
      | undefined;
  };
export type RemnantRowProps = PrepareRemnantRowProps & {
  remnantValue: number;
  rowNotError: boolean;
  remnantToCombo: string | number | null;
  remnantForSingle: number;
  calculeted_wildberries: string | number | null;
  calculeted_ozon: string | number | null;
  calculeted_ya: string | number | null;
  calculeted_sber: string | number | null;
  calculeted_ali: string | number | null;
  subRows: ReturnType<typeof getSubRowsData>;
} & DisabledSend;

type FieldProps = {
  value: string;
  onUpdate: (v: string) => Promise<boolean>;
};

export const Field = (props: FieldProps) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleBlur = async () => {
    const result = await props.onUpdate(value);
    if (!result) {
      setValue(props.value);
    }
  };

  return (
    <Form.Control
      className="input"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleBlur}
    />
  );
};

export const FieldWithButton = (props: FieldProps) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleClick = async () => {
    if (!value) return;
    const result = await props.onUpdate(value);
    setValue(props.value);
  };

  return (
    <>
      <Form.Control
        className="input"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <Button size={"sm"} onClick={handleClick} disabled={!value}>
        ОК
      </Button>
      <Button size={"sm"} onClick={handleClick} disabled={!value}>
        очистить
      </Button>
    </>
  );
};

export const getEval = (value: string | null, freeRemnant: number) => {
  if (value === "" || !value) return null;

  if (value.match(/[^0-9x\*\/\+\-\.\(\)]/)) return "noValid";
  try {
    const result = eval(value.replace(/x/g, freeRemnant + ""));
    return parseInt(result) ? Math.floor(parseInt(result)) : Math.floor(result);
  } catch (e) {
    return "noValid";
  }
};

export const getDinamicRemnant = (remnant: PrepareRemnantRowProps) => {
  const freeRemnant =
    (remnant.remnant || 0) -
    (remnant.reserved_in_wildberries || 0) -
    (remnant.reserved_in_ozon || 0) -
    (remnant.reserved_in_ya || 0) -
    (remnant.reserved_in_sber || 0) -
    (remnant.reserved_in_ali || 0) -
    (remnant.reserved_in_combo || 0);

  const remnantToCombo = getEval(
    remnant.share_in_combo || "0",
    freeRemnant < 0 ? 0 : freeRemnant
  );
  const remnantForSingle =
    typeof remnantToCombo === "number" && freeRemnant - remnantToCombo > 0
      ? freeRemnant - remnantToCombo
      : 0;

  return { freeRemnant, remnantToCombo, remnantForSingle };
};

export const getSubRowsData = (props: {
  comboComposition?: {
    article: string;
    value: number;
  }[];
  remnants: PrepareRemnantRowProps[];
}) => {
  return (
    props.comboComposition?.map((el) => {
      const data = props.remnants.find((rem) => rem.article === el.article);
      if (!data) {
        return {
          name: `${el.article} не найден`,
          finded: false,
          remnantToCombo: 0,
          remnantDevValue: 0,
          value: el.value,
        };
      }
      const values = getDinamicRemnant(data);
      return {
        name: `${data.article} ${data.name}`,
        finded: true,
        ...values,
        remnantDevValue:
          typeof values.remnantToCombo === "number"
            ? Math.floor(values.remnantToCombo / el.value)
            : 0,
        value: el.value,
      };
    }) || []
  );
};
