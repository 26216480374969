import { GoodsWithPriceForUpdate, GoodsWithPriceProps } from "api/PriceLoader";
import React from "react";
import { Col, ColValue, InnerCol, Row, SmallButton } from "./styled";

const PriceItem = (props: {
  data: GoodsWithPriceProps;
  update: (props: GoodsWithPriceForUpdate) => void;
}) => {
  const onClick = () => {
    props.update({
      article: props.data.article,
      wb_data: { need_update_price: true },
      ozon_data: { need_update_price: true },
      ya_data: { need_update_price: true },
      sber_data: { need_update_price: true },
      ali_data: { need_update_price: true },
    });
  };
  return (
    <Row>
      <Col>{props.data.article}</Col>
      <ColValue
        className={
          (props.data.wb_data?.need_update_price && "need_update") || ""
        }
      >
        <InnerCol>{props.data.wb_data?.price}</InnerCol>
        <InnerCol>{props.data.wb_data?.discounted_price}</InnerCol>
      </ColValue>
      <ColValue
        className={
          (props.data.ozon_data?.need_update_price && "need_update") || ""
        }
      >
        <InnerCol>{props.data.ozon_data?.price}</InnerCol>
        <InnerCol>{props.data.ozon_data?.discounted_price}</InnerCol>
      </ColValue>
      <ColValue
        className={
          (props.data.ya_data?.need_update_price && "need_update") || ""
        }
      >
        <InnerCol>{props.data.ya_data?.price}</InnerCol>
        <InnerCol>{props.data.ya_data?.discounted_price}</InnerCol>
      </ColValue>
      <ColValue
        className={
          (props.data.sber_data?.need_update_price && "need_update") || ""
        }
      >
        <InnerCol>{props.data.sber_data?.price}</InnerCol>
        <InnerCol>{props.data.sber_data?.discounted_price}</InnerCol>
      </ColValue>
      <ColValue
        className={
          (props.data.ali_data?.need_update_price && "need_update") || ""
        }
      >
        <InnerCol>{props.data.ali_data?.price}</InnerCol>
        <InnerCol>{props.data.ali_data?.discounted_price}</InnerCol>
      </ColValue>
      <Col>
        <SmallButton type="default" onClick={onClick}>
          Обновить на МП
        </SmallButton>
      </Col>
    </Row>
  );
};

export default PriceItem;
