export const markeplaceOptions = [
  { value: "wildberries", label: "Wildberries" },
  { value: "ozon", label: "Ozon" },
  { value: "ozon_fresh", label: "Ozon fresh" },
  { value: "detmir", label: "Детский мир" },
];

export const ratingOptions = new Array(5).fill(0).map((el, i) => i + 1);
export const statusOptions = [
  { label: "Без ответа", value: "no_answer" },
  { label: "Создан", value: "created" },
  { label: "Отправка", value: "sending" },
  { label: "Возврат", value: "reset" },
  { label: "Обработан", value: "processed" },
  { label: "Архив", value: "archived" },
];

export const sortingOptions = [
  { label: "По дате (по убыванию)", value: "date_desc" },
  { label: "По дате (по возрастанию)", value: "date_asc" },
  { label: "По рейтингу (по возрастанию)", value: "rating_asc" },
  { label: "По рейтингу (по убыванию)", value: "rating_desc" },
  { label: "По артикулу (по возрастанию)", value: "article_asc" },
  { label: "По артикулу (по убыванию)", value: "article_desc" },
];
