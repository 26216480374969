import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { AnswerTemplateProps } from "../api/AnswerTemplates";
import { ItemAnswerTemplateProps } from "./useAnswerTemplates";
import * as actions from "../api/Features";
import { Props } from "../api/Features";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";
const FieldWrap = styled.div`
  margin-bottom: 16px;
`;
const FieldLabel = styled.div`
  margin-bottom: 4px;
`;
const Wrap = styled.div`
  display: flex;
`;
const FeatureItem = (
  props: Props & {
    editable: boolean;
    update: (name: string, value: boolean) => void;
  }
) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.editable]);

  return (
    <FieldWrap>
      <FieldLabel>
        {props.translate} {!props.editable && value.toString()}
      </FieldLabel>
      {props.editable && (
        <Wrap>
          <Select
            style={{ width: "100%", marginRight: 24 }}
            value={"" + value}
            onChange={(e: any) =>
              setValue(e.target.value === "true" ? true : false)
            }
          >
            <MenuItem value={"false"}>{"false"}</MenuItem>
            <MenuItem value={"true"}>{"true"}</MenuItem>
          </Select>
          <Button onClick={() => props.update(props.name, value)}>
            Сохранить
          </Button>
        </Wrap>
      )}
    </FieldWrap>
  );
};

const FeatureFlags = () => {
  const [values, setValues] = useState<Props[]>([]);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await actions.getAllFeatureFlags();
      setValues(result);
    };
    fetchData();
  }, []);

  const update = async (name: string, value: boolean) => {
    await actions.updateFeatureFlagsValue(name, value);
    setValues(
      values.map((el) => ({
        ...el,
        value: el.name === name ? value : el.value,
      }))
    );
  };
  return (
    <>
      <Row>
        <Col xs={6} md={3}>
          {values.map((el) => (
            <FeatureItem
              key={el.name}
              {...el}
              editable={editable}
              update={update}
            />
          ))}
        </Col>
      </Row>

      <Row>
        <Col xs={6} md={3}>
          {editable ? (
            <Button onClick={() => setEditable(false)}>Отменить</Button>
          ) : (
            <Button onClick={() => setEditable(true)}>Изменить</Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FeatureFlags;
