import fetchApiWithAuth from "./fetch";
type Mp = {
  price: number | null;
  discounted_price: number | null;
  need_update_price: boolean | null;
};
export type GoodsWithPriceProps = {
  article: string;
  name: string;
  wb_data?: Mp | null;
  ozon_data?: Mp | null;
  ya_data?: Mp | null;
  sber_data?: Mp | null;
  ali_data?: Mp | null;
};
export type GoodsWithPriceForUpdate = {
  article: string;
  name?: string;
  wb_data?: Partial<Mp> | null;
  ozon_data?: Partial<Mp> | null;
  ya_data?: Partial<Mp> | null;
  sber_data?: Partial<Mp> | null;
  ali_data?: Partial<Mp> | null;
};

export type ScheduleTask = {
  id: number;
  name: string;
  started: boolean;
  goods: { article: string }[];
  startMinute: number;
  startHour: string;
  discountAmount: number;
};

export const updatePriceFromXlsx = async (props: Record<string, any>[]) => {
  const result = await fetchApiWithAuth(
    "/api/price-loader/update_price_from_xlsx",
    { method: "POST", body: JSON.stringify({ data: props }) }
  );
  const data = (await result.json()) as GoodsWithPriceProps[];
  return data;
};

export const updatePrices = async (props: GoodsWithPriceForUpdate[]) => {
  const result = await fetchApiWithAuth("/api/price-loader/update_price", {
    method: "POST",
    body: JSON.stringify({ data: props }),
  });
  const data = (await result.json()) as GoodsWithPriceProps[];
  return data;
};

export const getPrices = async () => {
  const result = await fetchApiWithAuth("/api/price-loader");
  const data = (await result.json()) as GoodsWithPriceProps[];
  return data;
};

export const getPriceXlsxTemplate = async () => {
  const result = await fetchApiWithAuth("/api/price-loader/get_xlsx_template", {
    method: "get",
  });

  return result;
};

export const getScheduleTasks = async () => {
  const result = await fetchApiWithAuth("/api/discount-schedule");
  const data = (await result.json()) as ScheduleTask[];

  return data;
};

export const createScheduleTask = async (
  props: Omit<ScheduleTask, "id">
): Promise<ScheduleTask> => {
  const result = await fetchApiWithAuth("/api/discount-schedule", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ScheduleTask;

  return data;
};

export const updateScheduleTask = async (
  id: number,
  props: Omit<ScheduleTask, "id">
): Promise<ScheduleTask> => {
  const result = await fetchApiWithAuth("/api/discount-schedule/" + id, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ScheduleTask;

  return data;
};

export const deleteScheduleTask = async (
  id: number
): Promise<{ error: boolean }> => {
  const result = await fetchApiWithAuth("/api/price-loader", {
    method: "DELETE",
  });
  // const data = (await result.json()) as GoodsWithPriceProps[];

  return { error: false };
};
