import { useState, useEffect } from "react";


export const useFilter = () => {
  const [showCombo, setShowCombo] = useState(true);
  const [showSingle, setShowSingle] = useState(true);
  const [search, setSearch] = useState("");

  return {
    showCombo,
    setShowCombo,
    showSingle,
    setShowSingle,
    search,
    setSearch,
  };
};
