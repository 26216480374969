import React from "react";
import Layout from "../shared/Layout";
import Settings from "../Settings";

const SettingsPage = () => {
  return (
    <Layout>
      <Settings></Settings>
    </Layout>
  );
};

export default SettingsPage;
