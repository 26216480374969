import React, { useRef } from "react";
import { Button } from "antd";

const AttachmentButton = (
  props: React.PropsWithChildren<{
    onChooseFiles: (value: File[]) => void;
    multiple?: boolean;
  }>
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelectFile = () => {
    inputRef.current?.click();
  };
  const onChooseFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }
    props.onChooseFiles?.(Array.from(e.target.files));
  };

  return (
    <Button onClick={onSelectFile} type="primary">
      <input
        type="file"
        hidden
        multiple={props.multiple}
        ref={inputRef}
        onChange={onChooseFiles}
        value={[]}
      />
      {props.children}
    </Button>
  );
};

export default AttachmentButton;
