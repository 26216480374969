import { Product, ProductGroup } from "PriceMonitoring/modal";
import React, { useContext } from "react";
export type context = {
  groups: ProductGroup[];
  selectedGroupId: number | null;
  selectedProductId: number | null;
  changeSelectedGroup: (value: number) => void;
  changeSelectedProduct: (value: number) => void;
  addGroup: (data: ProductGroup) => void;
  editGroup: (id: number, data: ProductGroup) => void;
  removeGroup: (id: number) => void;
};
export const Context = React.createContext<context | null>(null);

export const usePriceMonitoringContext = () => useContext(Context)!;
