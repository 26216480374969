import { ProductEdit } from "PriceMonitoring/modal";
import useFormStateWithErrors from "shared/utils/useFormStateWithErrors";

const defaultValue: ProductEdit = {
  brand: "",
  name: "",
  internal_product: false,
  quantity:'',
  groups: [],
  boundary_enable: false,
  boundary_lower_bound: "",
  boundary_upper_bound: "",
};

const errors: Record<keyof ProductEdit, string> = {
  brand: "",
  name: "",
  internal_product: "",
  quantity:'',
  groups: "",
  boundary_enable: "",
  boundary_lower_bound: "",
  boundary_upper_bound: "",
};

export const useProductEditorState = () => {
  return useFormStateWithErrors<ProductEdit>(defaultValue, errors);
};
