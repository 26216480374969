import React, { Children, ReactComponentElement } from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import TreeNode, { Props } from "./TreeNode";
import { ClassifierProps } from "api/Classifier";

type Node = {
  id: number;
  name: string;
  children?: Node[];
};
type Props1 = {
  nodes: (Node & { children?: Node[] })[];
  nodeComponent: React.FunctionComponent<Props>;
  onCreate: (props: { parentId: number }) => {};
  onUpdate: (props: { id: number; title: string }) => {};
};
type Elem = { path: number[]; title: string; id: number };
const Tree = (props: Props1) => {
  const render = (node: Node, parent: number[]): Elem[] => {
    const path = [...parent, node.id];
    const child = node.children
      ? node.children.reduce((acc, el) => {
          return [...acc, ...render(el, path)];
        }, [] as Elem[])
      : [];

    return [{ path, title: node.name, id: node.id }, ...child];
  };

  const nodesForRender = props.nodes.reduce((acc, el) => {
    return [...acc, ...render(el, [])];
  }, [] as Elem[]);

  return (
    <Container>
      <Row>
        <Col>
          {nodesForRender.map((el: Elem) => (
            <TreeNode
              space={el.path.length}
              title={el.title}
              id={el.id}
              onEdit={props.onUpdate}
              onCreate={props.onCreate}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Tree;
