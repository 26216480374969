import { Button } from "antd";
import styled from "styled-components";

export const Col = styled.div`
  display: flex;
  max-width: 200px;
  width: 100%;
  padding: 0 4px;

  align-items: center;
  min-height: 32px;
`;

export const Row = styled.div`
  display: flex;
  margin: 8px 0;
  ${Col}:nth-child(1) {
    width: 58px;
  }
  ${Col}:nth-child(2) {
    width: 250px;
  }
  ${Col}:nth-child(3) {
    width: 150px;
  }
  ${Col}:nth-child(4) {
    width: 100px;
  }
  ${Col}:nth-child(5) {
    width: 100px;
  }
  ${Col}:nth-child(6) {
    max-width: none;
    width: 450px;
    max-height: 100px;
    overflow: auto;
  }
`;

export const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 100px);
`;

export const Header = styled.div`
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px;
  z-index: 1;
`;
