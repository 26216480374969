import React, { useEffect, useState } from "react";
import AttachmentButton from "shared/GoodDataUploader/AttachmentButton";
import { Container } from "react-bootstrap";
import { uploadXlsx } from "api/Goods";
import ModalGoodsData from "shared/GoodDataUploader/ModalGoodsData";
import PriceList from "./components/PriceList";
import columnOptions from "./components/columnOptions";
import {
  GoodsWithPriceForUpdate,
  GoodsWithPriceProps,
  getPriceXlsxTemplate,
  getPrices,
  updatePriceFromXlsx,
  updatePrices,
} from "api/PriceLoader";
import { Space } from "shared/ui";
import FileSaver from "file-saver";
import { Button } from "antd";

const PriceLoader = () => {
  const [state, setState] = useState<{
    headers: string[];
    rows: (string | null)[][];
  } | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [rows, setRows] = useState<GoodsWithPriceProps[]>([]);

  const handleLoad = async (files: File[]) => {
    if (files.length) {
      const result = await uploadXlsx({ data: files[0] });
      const rows = result.rows.map((el) =>
        el.map((el) =>
          typeof el === "string" && !isNaN(+el) ? parseInt(el) + "" : el
        )
      );
      setState({ ...result, rows: rows });
      setShowModal(true);
    }
  };

  const handleLoadSubmit = async (props: {
    headers: string[];
    rows: (string | boolean | null)[][];
  }) => {
    const headers = ["article", ...props.headers.slice(1)];
    const result = await updatePriceFromXlsx(
      props.rows.map((el) =>
        el.reduce((acc, el, i) => ({ ...acc, [headers[i]]: el }), {} as any)
      )
    );

    setShowModal(false);
    fetchRows();
  };

  const fetchRows = async () => {
    const result = await getPrices();
    setRows(result);
  };

  const handleUpdate = async (props: GoodsWithPriceForUpdate) => {
    const result = await updatePrices([props]);
    setRows(
      rows.map((el) => result.find((r) => r.article === el.article) || el)
    );
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const fetchXlsx = async () => {
    const response = await getPriceXlsxTemplate();

    if (!response.ok) {
      return;
    }
    const blobPart = await response.blob();
    const blob = new Blob([blobPart], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    FileSaver.saveAs(blob, "Шаблон загрузки цен");
  };

  return (
    <Container>
      <div>
        <AttachmentButton onChooseFiles={handleLoad} multiple={false}>
          Загрузить данные из файла
        </AttachmentButton>
        <Space size={24} inline />
        <Button onClick={fetchXlsx}>Скачать шаблон</Button>
      </div>
      <Space axis="vertical" size={40} />
      {showModal && state ? (
        <ModalGoodsData
          data={state}
          columnOptions={columnOptions}
          onSubmit={handleLoadSubmit}
          onClose={() => setShowModal(false)}
        />
      ) : null}
      <PriceList data={rows} update={handleUpdate} fetchRows={fetchRows} />
    </Container>
  );
};
export default PriceLoader;
