import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ProductEdit from "./components/ProductEdit";
import {
  getAll,
  updateLog,
  getGroups,
  editGroup,
  createGroup,
  deleteGroup,
} from "api/PriceMonitoring";
import { Product, ProductGroup } from "./modal";

import Title from "antd/es/typography/Title";
import Actions from "./components/Actions";
import { Col, Row } from "antd";
import GroupsList from "./components/GroupsList";
import { Context } from "./components/Contex";
import { Space } from "shared/ui";
import { useSearchParams } from "react-router-dom";
import GroupEditor from "./components/GroupEditor";
import * as GoodsApi from "../api/Goods";

const PriceMonitoring = () => {
  const [pending, setPending] = useState(false);
  const [groups, setGroups] = useState<ProductGroup[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const [productCategories, setProductCategories] = useState<
    { id: number; name: string }[]
  >([]);

  const fetchGoods = async () => {
    setProductCategories(await GoodsApi.getCategories());
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const addNewGroup = async () => {
    const result = await createGroup();
    setGroups([result, ...groups]);
  };

  const hiddenGroup = async () => {
    const result = await createGroup();
    setGroups([result, ...groups]);
  };

  const removeGroup = async (id: number) => {
    const result = await deleteGroup(id);
    if (result) {
      setGroups(groups.filter((el) => el.id !== id));
      id === selectedGroupId && setSelectedGroupId(null);
    }
  };

  const changeSelectedGroup = (groupId: number) => {
    setSelectedGroupId(groupId);
    setSearchParams([["selectedGroupId", String(groupId)]]);
  };

  const changeSelectedProduct = (productId: number | null) => {
    console.log(searchParams, ...searchParams.entries());

    const array = [...searchParams.entries()].filter(
      (el) => el[0] !== "selectedProductId"
    );
    if (productId !== null) {
      array.push(["selectedProductId", String(productId)]);
    }
    setSelectedProductId(productId);
    setSearchParams(array);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const [result] = await Promise.all([await getGroups(), fetchGoods()]);

      setGroups(result);
      setPending(false);
    };

    fetchData();
  }, []);
  useEffect(() => {
    const selectedGroupId = searchParams.get("selectedGroupId");
    if (
      selectedGroupId &&
      String(parseInt(selectedGroupId)) === selectedGroupId
    ) {
      setSelectedGroupId(+selectedGroupId);
    } else {
      searchParams.delete("selectedGroupId");
      setSearchParams(searchParams);
    }
    const selectedProductId = searchParams.get("selectedProductId");
    if (
      selectedProductId &&
      String(parseInt(selectedProductId)) === selectedProductId
    ) {
      setSelectedProductId(+selectedProductId);
    } else {
      searchParams.delete("selectedProductId");
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <div>
      <Context.Provider
        value={{
          groups,
          selectedGroupId,
          selectedProductId,
          changeSelectedGroup,
          changeSelectedProduct,
          addGroup: () => {
            //
          },
          editGroup: async (id: number, value: ProductGroup) => {
            const result = await editGroup(id, value);

            setGroups(groups.map((el) => (el.id === id ? result : el)));
          },
          removeGroup: (id: number) => {
            removeGroup(id);
          },
        }}
      >
        <Title level={1}>Мониторинг цен</Title>
        <Actions addGroup={addNewGroup}></Actions>
        <Space axis="vertical" size={36}></Space>
        <Row gutter={[32, 32]}>
          <Col span={8}>
            <GroupsList />
          </Col>
          <Col span={16}>
            {selectedProductId ? (
              <ProductEdit
                id={selectedProductId}
                toPrev={() => changeSelectedProduct(null)}
              />
            ) : (
              selectedGroupId && (
                <GroupEditor
                  id={selectedGroupId}
                  productCategories={productCategories}
                />
              )
            )}
          </Col>
        </Row>
      </Context.Provider>
    </div>
  );
};
export default PriceMonitoring;
