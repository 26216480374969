import React, { useEffect, useState } from "react";

import { Container } from "./components/styled";

import { Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getBadGoods, BadGoods } from "api/Reviews";
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const columns = [
  {
    title: "Артикул",
    dataIndex: "article",
  },
  {
    title: "Количество",
    dataIndex: "count",
  },
  {
    title: "rating",
    dataIndex: "rating",
  },
  {
    title: "error",
    dataIndex: "error",
  },
];
const ReviewsWithoutAnswer = () => {
  const [goods, setGoods] = useState<BadGoods[]>([]);
  const [pending, setPending] = useState(false);

  const fetchGoods = async () => {
    setPending(true);
    try {
      const result = await getBadGoods();
      setGoods(result);
    } catch (e) {}
    setPending(false);
  };

  useEffect(() => {
    fetchGoods();
  }, []);

  return (
    <Container>
      {pending && (
        <Spin
          indicator={antIcon}
          style={{ position: "absolute", margin: 20 }}
        />
      )}
      {!goods.length ? (
        <>no bad goods in reviews</>
      ) : (
        <Table columns={columns} dataSource={goods} style={{ width: "100%" }} />
      )}
    </Container>
  );
};
export default ReviewsWithoutAnswer;
