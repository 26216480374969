import { useCallback, useEffect, useRef, useState } from "react";
const getTimestamp = () => new Date().getTime();
type Value = string | null;

export const useRowsData = (
  props: Value[][],
  onSave: (props: Value[][]) => void
) => {
  const ref = useRef<Value[][]>([]);
  const [timestampUpdate, setTimestapmUpdate] = useState(getTimestamp());

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const row = +e.target.dataset.row!;
    const cell = +e.target.dataset.cell!;
    ref.current[row][cell] = e.target.value || "";
  }, []);

  const onSubmit = () => {
    console.log(ref.current);
    onSave(ref.current);
  };

  useEffect(() => {
    ref.current = props;
    setTimestapmUpdate(getTimestamp());
  }, [props]);

  return { timestampUpdate, ref, onChange, onSubmit };
};
