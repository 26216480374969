import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import GroupEditor from "./components/GroupEditor";
import { useParams } from "react-router";

const PriceMonitoringProductView = () => {
  const { id } = useParams<{ id: string }>();
  const idVal = id && !isNaN(+id) ? +id : undefined;
  // return <GroupEditor id={idVal} />;
  return <></>;
};
export default PriceMonitoringProductView;
