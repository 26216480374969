import React from "react";
import { RemainsHeader } from "./RemainsHeader";
import { RemainsList } from "./components/RemainsList";

export const Remains = () => {
  return (
    <>
      <RemainsHeader></RemainsHeader>
      <RemainsList></RemainsList>
    </>
  );
};
