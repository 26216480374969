import React from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { ProductCategoiesItem } from "./ProductCategoiesItem";
import { useProductCategoies } from "./useProductCategoies";

const ProductCategoies = () => {
  const {
    categories,
    createCategory,
    showNew,
    setShowNew,
    deleteCategory,
    updateCategory,
    goods,
  } = useProductCategoies();

  return (
    <Container>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 0" }}
            onClick={() => setShowNew(!showNew)}
          >
            {!showNew ? "Создать категорию" : "Скрыть форму"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {showNew && (
            <ProductCategoiesItem
              onCreateCategory={createCategory}
              goodsOptions={goods}
            />
          )}
          {categories.map((el) => (
            <ProductCategoiesItem
              data={el}
              id={el.id}
              onUpdateCategory={updateCategory}
              onDeleteCategory={deleteCategory}
              goodsOptions={goods}
            />
          ))}
          {!categories.length && "Нет созданных категорий"}
        </Col>
      </Row>
    </Container>
  );
};

export default ProductCategoies;
