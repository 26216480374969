import { useMemo, useCallback, useReducer } from "react";
import useFormState from "./useFormState";
export type ErrorState<T> = { [key in keyof T]: string | null };

type Action<T, Key extends keyof T> =
  | { type: "all"; payload: ErrorState<T> }
  | {
      type: "set";
      payload: {
        field: Key;
        value: string | null;
      };
    };

const createReducer =
  <State>() =>
  (state: State, action: Action<State, keyof State>): State => {
    switch (action.type) {
      case "set":
        return { ...state, [action.payload.field]: action.payload.value };
      case "all":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };

function useFormStateWithErrors<T>(
  defaultValues: T,
  defaultErrors: ErrorState<T>
) {
  const formState = useFormState<T>(defaultValues);
  const reducer = createReducer<ErrorState<T>>();
  const [errors, dispatch] = useReducer(reducer, defaultErrors);

  const setError = useCallback(
    <Key extends keyof T>(field: Key) =>
      (value: string | null) => {
        dispatch({ type: "set", payload: { field, value } });
      },
    []
  );
  const setErrors = useCallback((newValues: ErrorState<T>) => {
    dispatch({ type: "all", payload: newValues });
  }, []);

  const setValue =
    <Key extends keyof T>(field: Key) =>
    (value: T[Key]) => {
      setError(field)(null);
      return formState.setValue(field)(value);
    };

  return useMemo(
    () => ({
      ...formState,
      setValue,
      errors,
      setError,
      setErrors,
    }),
    [formState, errors]
  );
}

export default useFormStateWithErrors;
