import React, { useEffect, useState } from "react";
import { Search, Rating, Markeplace, Status, Sorting } from "./FilterItems";
import { FilterState } from "./useFilterState";
import { getFilterOptions } from "api/Reviews";
import { markeplaceOptions as avalibelMarkeplaceOptions } from "./filterOptions";

export const Filter = (props: { filters: FilterState }) => {
  const [markeplaceOptions, setMarkeplaceOptions] = useState<
    { value: string; label: string }[]
  >([]);
  useEffect(() => {
    const fetchData = async () => {
      const result = await getFilterOptions();
      setMarkeplaceOptions(
        avalibelMarkeplaceOptions.filter((el) =>
          result.marketplaces.includes(el.value)
        )
      );
    };
    fetchData();
  }, []);
  return (
    <div>
      <Sorting filters={props.filters}></Sorting>
      <Search filters={props.filters}></Search>
      <Rating filters={props.filters}></Rating>
      <Markeplace
        filters={props.filters}
        markeplaceOptions={markeplaceOptions}
      ></Markeplace>
      <Status filters={props.filters}></Status>
    </div>
  );
};
