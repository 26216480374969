import fetchApi from "./fetch";

export type LogProps = {
  id: number;
  created_at: string;
  source: string;
  type: string;
  message: string;
};

export const getLogs = async (props: { after_id?: number }) => {
  const result = await fetchApi(
    `/api/logs${(props.after_id && `?after_id=` + props.after_id) || ""} `
  );
  const data = (await result.json()) as {
    logs: LogProps[];
    total_count: number;
  };
  return data;
};
