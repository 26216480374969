import React from "react";

import { RowWrap, Cell, SelectStyled } from "./styled";
export const HeaderRow = (props: {
  colunmNames: string[];
  columnOptions: { value: string; label: string }[];
  onChange: (props: [string, number]) => void;
}) => {
  return (
    <RowWrap className="header">
      <Cell>{props.colunmNames[0]}</Cell>
      {props.colunmNames.slice(1).map((el, i) => (
        <Cell>
          <SelectStyled
            onChange={(value: any) => {
              props.onChange([value, i + 1]);
            }}
            value={
              props.columnOptions.find((c) => c.value === el)?.label || null
            }
            options={props.columnOptions}
          />
        </Cell>
      ))}
    </RowWrap>
  );
};
