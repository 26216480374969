import React, { useEffect, useMemo, useState } from "react";
import { RowWrap, Cell, SelectStyled, InputCell } from "./styled";
import { validatorByFieldName, Validator } from "./validator";

export const Row = (props: {
  rowIndex: number;
  values: (string | null)[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  headers: (string | null)[];
}) => {
  const [values, setValues] = useState(props.values);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const cell = +e.target.dataset.cell!;
    setValues(values.map((el, i) => (i === cell ? value : el)));
    props.onChange(e);
  };

  const valids = useMemo(
    () =>
      (props.headers.length > values.length ? props.headers : values).map(
        (_, i) => {
          const name: string = props.headers[i] || "default";
          return (
            validatorByFieldName[name] || validatorByFieldName["default"]
          )(values[i] || null);
        }
      ),
    [props.headers, values]
  );

  return (
    <RowWrap>
      {values.map((el, i) => (
        <InputCell
          key={i}
          value={el?.toString()}
          onChange={onChange}
          maxLength={i > 0 ? 10 : undefined}
          data-row={props.rowIndex}
          data-cell={i}
          data-error={!valids[i].valid && "error"}
        ></InputCell>
      ))}
    </RowWrap>
  );
};
