import { useState, useEffect } from "react";
const keyDefault = "column_control";
type ColunmName =
  | "remnants"
  | "reserved"
  | "combo_distribution"
  | "distribution"
  | "calculation"
  | "actions"
  |"disabled_send_by_marketplace";
const record = (key: string, props: string[]) => {
  localStorage.setItem(key, JSON.stringify(props));
};
const defaultState: ColunmName[] = [
  "remnants",
  "reserved",
  "combo_distribution",
  "distribution",
  "calculation",
  "actions",
  "disabled_send_by_marketplace"
];
const read = (): ColunmName[] | null => {
  const value = localStorage.getItem(keyDefault);
  try {
    if (!value) throw new Error();
    const parsed = JSON.parse(value);
    if (!(parsed instanceof Array)) throw new Error();
    if (!parsed.every((el) => defaultState.includes(el))) throw new Error();
    return parsed as ColunmName[];
  } catch (e) {}
  return null;
};

export const useColumnControl = () => {
  const [state, setState] = useState(read() || defaultState);
  const update = (name: ColunmName, value: boolean) => {
    console.log("!!!", name, value, state);
    setState(value ? [...state, name] : state.filter((el) => el !== name));
  };

  useEffect(() => {
    record(keyDefault, state);
  }, [state]);

  return {
    remnantsChecked: state.includes("remnants"),
    reservedChecked: state.includes("reserved"),
    comboDistributionChecked: state.includes("combo_distribution"),
    distributionChecked: state.includes("distribution"),
    calculationChecked: state.includes("calculation"),
    actionsChecked: state.includes("actions"),
    disabledSendByMarketplaceChecked: state.includes("disabled_send_by_marketplace"),
    update,
  };
};

export type ColumnControl = ReturnType<typeof useColumnControl>;
