import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { ProductGroup } from "PriceMonitoring/modal";
import { ItemWrap, Title } from "./GroupsListStyled";

const GroupsListItem = (props: {
  data: ProductGroup;
  selected: boolean;
  edit: (id: number, data: ProductGroup) => void;
  delete: (id: number) => void;
  changeGroup: (value: number) => void;
}) => {
  return (
    <ItemWrap
      onClick={() => props.changeGroup(props.data.id)}
      selected={props.selected}
    >
      <Title>{props.data.name}</Title>
    </ItemWrap>
  );
};

export default GroupsListItem;
