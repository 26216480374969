import React from "react";
import { Row, Col } from "antd";
import PriceMonitoringGroupView from "../PriceMonitoring/groupViewPage";
import Layout from "../shared/Layout";

const PriceMonitoringPage = () => {
  return (
    <Layout>
      <Row>
        <Col span={24}>
          <PriceMonitoringGroupView />
        </Col>
      </Row>
    </Layout>
  );
};

export default PriceMonitoringPage;
