import { useEffect, useState } from "react";
import * as GoodsApi from "../api/Goods";
import { GoodsProps } from "../api/Goods";

export const useGoods = () => {
  const [showNew, setShowNew] = useState(false);
  const [goods, setGoods] = useState<GoodsProps[]>([]);
  const [productCategories, setProductCategories] = useState<
    { id: number; name: string }[]
  >([]);
  const [brands, setBrands] = useState<{ name: string }[]>([]);

  const fetchGoods = async () => {
    setGoods(await GoodsApi.getAll());
    setProductCategories(await GoodsApi.getCategories());
    setBrands(await GoodsApi.getBrands());
  };

  const createGoods = async (props: GoodsProps) => {
    const result = await GoodsApi.create(props);
    setGoods([result, ...goods]);
    setShowNew(false);
  };
  const updateGoods = async (props: GoodsProps) => {
    await GoodsApi.edit(props.article, props);
    setGoods(goods.map((el) => (el.article === props.article ? props : el)));
  };
  const deleteGoods = async (article: GoodsProps["article"]) => {
    await GoodsApi.remove(article);
    setGoods(goods.filter((el) => el.article !== article));
  };
  useEffect(() => {
    fetchGoods();
  }, []);
  return {
    goods,
    createGoods,
    showNew,
    setShowNew,
    deleteGoods,
    updateGoods,
    brands,
    productCategories,
  };
};
