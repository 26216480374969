import { right } from "@popperjs/core";
import { ProductGroup } from "PriceMonitoring/modal";
import { getGroups, editGroup } from "api/PriceMonitoring";
import { useEffect, useState } from "react";

const useTvView = () => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [groupList, setGroupList] = useState<ProductGroup[]>([]);
  const [timestamp, setTimestamp] = useState("");
  const updateGroupList = async () => {
    const result = await getGroups();
    result.sort(
      (a, b) =>
        (a.order?.position || Infinity) - (b.order?.position || Infinity)
    );
    setGroupList(result);
    setTimestamp(new Date().getTime() + "");
  };
  const startTimer = async () => {
    console.log("timer start");
    await updateGroupList();
    const timer = setTimeout(startTimer, 60 * 1000);
    setTimer(timer);
  };
  const stopTimer = () => {
    console.log("timer stop");
    if (timer !== null) {
      clearTimeout(timer);
    }
    setTimer(null);
  };
  useEffect(() => {
    startTimer();
  }, []);
  useEffect(() => {
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [timer]);
  const changeOrder = (id: number, targetId: number, position: string) => {
    if (id === targetId) return;
    const [left, right] = (() => {
      const targetIndex = groupList.findIndex((el) => el.id === targetId);
      const left =
        position === "right"
          ? groupList[targetIndex]
          : groupList[targetIndex - 1];
      const right =
        position === "right"
          ? groupList[targetIndex + 1]
          : groupList[targetIndex];
      return [left, right];
    })();
    if (left?.id === id || right?.id === id) return;
    let newPos = !right?.order?.position
      ? (left?.order?.position || 0) + 1000
      : (left?.order?.position || 0) +
        Math.ceil((right?.order?.position - (left?.order?.position || 0)) / 2);

    if (newPos === left?.order?.position) {
      alert("Беда, пишите Марку");
      return;
    }
    const group = groupList.find((el) => el.id === id);
    editGroup(id, {
      ...(group as any),
      order: { ...((group?.order as any) || {}), position: newPos },
    });
    const data = groupList.map((el) =>
      el.id === id
        ? {
            ...el,
            order: { ...((group?.order as any) || {}), position: newPos },
          }
        : el
    );
    data.sort((a, b) => a.order.position - b.order.position);
    setGroupList(data);
    return;
  };
  return {
    started: timer !== null,
    startTimer,
    stopTimer,
    groups: groupList,
    timestamp,
    changeOrder,
  };
};
export type TvView = ReturnType<typeof useTvView>;
export default useTvView;
