import { createTheme } from "@mui/material/styles";
const outerTheme = createTheme({
  spacing: 4,
  components: {
    // Name of the component
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
  },
});

export default outerTheme;
