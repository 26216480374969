import { useMemo, useCallback, useReducer } from 'react'

type Action<T, Key extends keyof T> =
  | { type: 'all'; payload: T }
  | {
      type: 'set'
      payload: {
        field: Key
        value: T[Key]
      }
    }

const createReducer =
  <State>() =>
  (state: State, action: Action<State, keyof State>): State => {
    switch (action.type) {
      case 'set':
        return { ...state, [action.payload.field]: action.payload.value }
      case 'all':
        return { ...state, ...action.payload }
      default:
        return state
    }
  }

function useFormState<T>(props: T) {
  const reducer = createReducer<T>()
  const [values, dispatch] = useReducer(reducer, props)

  const setValue = useCallback(
    <Key extends keyof T>(field: Key) =>
      (value: T[Key]) => {
        dispatch({ type: 'set', payload: { field, value } })
      },
    []
  )
  const setValues = useCallback((newValues: T) => {
    dispatch({ type: 'all', payload: newValues })
  }, [])

  return useMemo(
    () => ({
      values,
      setValue,
      setValues
    }),
    [values, setValues, setValue]
  )
}

export default useFormState
