import React from "react";
import { Container, Row } from "react-bootstrap";
import PriceMonitoringTv from "../PriceMonitoring/tvView";
import Layout from "../shared/Layout";

const PriceMonitorinTvViewPage = () => {
  return (
    <Layout>
      <PriceMonitoringTv />
    </Layout>
  );
};

export default PriceMonitorinTvViewPage;
