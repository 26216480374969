import styled from "styled-components";
import { Button, Col, Input, Row } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

export const FieldWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FieldTitle = styled.div`
  padding-right: 8px;
  min-width: 120px;
  display: inline-block;
`;

export const LinkTitle = styled.a`
  overflow: hidden;
  flex: 1 1 auto;
  white-space: nowrap;
  margin-right: 16px;
  text-overflow: ellipsis;
`;

export const Check = styled(CheckOutlined)``;
export const Close = styled(CloseOutlined)``;
export const Delete = styled(DeleteOutlined)``;
export const Edit = styled(EditOutlined)``;
export const EyeInvisible = styled(EyeInvisibleOutlined)``;
export const Eye = styled(EyeOutlined)``;

export const ColStyled = styled(Col)`
  padding: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
