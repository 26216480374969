import { useState, useEffect } from "react";
const keyDefailt = "pinned_";

const columns = ["wb", "ozon", "ya", "sber", "ali", "combo"];
const useSelectCell = (remnants: { article: string }[]) => {
  const [cells, setCells] = useState<{ column: string; row: string }[]>([]);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = (event.target as any).closest("[data-column]");
    console.log(target);
    const dataset = target?.dataset || {};
    const ctrlKey = event.metaKey || event.ctrlKey;
    if (
      !(event.metaKey || event.shiftKey || event.ctrlKey) &&
      dataset.selected !== "true"
    ) {
      setCells([]);
      return;
    }

    if (!(ctrlKey && dataset.column)) return;

    if (dataset.column === "all") {
      console.log(dataset);

      const data = cells.filter((el) => el.row !== dataset.row);

      if (cells.length - data.length === columns.length) {
        setCells(data);
      } else {
        setCells([
          ...data,
          ...columns.map((el) => ({
            row: dataset.row,
            column: el,
          })),
        ]);
      }

      return;
    }
    if (dataset.row === "header") {
      const data = cells.filter((el) => el.column !== dataset.column);
      if (cells.length - data.length === remnants.length) {
        setCells(data);
      } else {
        setCells([
          ...data,
          ...remnants.map((el) => ({
            row: el.article,
            column: dataset.column,
          })),
        ]);
      }
      return;
    }
    setCells(
      dataset.selected === "true"
        ? cells.filter(
            (el) => !(el.column === dataset.column && el.row === dataset.row)
          )
        : [...cells, { column: dataset.column, row: dataset.row }]
    );
  };
  return { onClick, cells };
};

export default useSelectCell;
