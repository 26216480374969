const colors = {
  white: "#ffffff",
  black: "#17252a",
  main: "#3aafa9",
  hover: "#2b7a78",
  second: "#def2f1",
  warning: "#FD7E85",
};

export default colors;
