import useFormStateWithErrors from "shared/utils/useFormStateWithErrors";

type GroupEditor = {
  name: string;
  category: { id: number; name: string } | null;
};

const defaultValue: GroupEditor = {
  name: "",
  category: null,
};

const errors: Record<keyof GroupEditor, string> = {
  name: "",
  category: "",
};

export const useGroupEditorState = () => {
  return useFormStateWithErrors<GroupEditor>(defaultValue, errors);
};
