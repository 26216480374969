import React, { useMemo, useState } from "react";
import PriceItem from "./PriceItem";
import { GoodsWithPriceForUpdate, GoodsWithPriceProps } from "api/PriceLoader";
import { Col, Header, Row, Wrap } from "./scheduleStyled";
import { Input } from "antd";

const ScheduleList = (props: React.PropsWithChildren<{}>) => {
  return (
    <Wrap>
      <Header>
        <Row>
          <Col></Col>
          <Col>Название</Col>
          <Col>Часы</Col>
          <Col>Минуты</Col>
          <Col>Изменение</Col>
          <Col>Товары</Col>
          <Col></Col>
        </Row>
      </Header>
      {props.children}
    </Wrap>
  );
};

export default ScheduleList;
