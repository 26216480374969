import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { usePriceMonitoringContext } from "./Contex";
import GroupsListItem from "./GroupsListItem";
import { Space } from "shared/ui";
import { ListTitle, ListWrap } from "./GroupsListStyled";
import { ProductGroup } from "PriceMonitoring/modal";

const GroupsList = () => {
  const context = usePriceMonitoringContext();

  const groupsByCategory = useMemo(() => {
    const result = context.groups.reduce((acc, el) => {
      const category = el.category || { id: 0, name: "Без категории" };
      return {
        ...acc,
        [category.id]: acc[category.id]
          ? { ...acc[category.id], groups: [...acc[category.id].groups, el] }
          : {
              categoryId: category.id,
              categoryName: category.name,
              groups: [el],
            },
      };
    }, {} as Record<number, { categoryId: number; categoryName: string; groups: ProductGroup[] }>);
    const withoutCategory = result[0];
    delete result[0];
    const filtered = Object.values(result);
    filtered.sort((a, b) => (a.categoryName < b.categoryName ? -1 : 1));
    if (withoutCategory) {
      filtered.push(withoutCategory);
    }
    return filtered;
  }, [context.groups]);
  return (
    <ListWrap>
      {groupsByCategory.map((el) => (
        <>
          <ListTitle>{el.categoryName}</ListTitle>
          <Space size={16} axis="vertical"></Space>
          {el.groups.map((el) => (
            <>
              <GroupsListItem
                data={el}
                selected={context.selectedGroupId === el.id}
                edit={context.editGroup}
                delete={context.removeGroup}
                changeGroup={context.changeSelectedGroup}
              />
              <Space size={16} axis="vertical"></Space>
            </>
          ))}
          <Space size={24} axis="vertical"></Space>
        </>
      ))}
    </ListWrap>
  );
};

export default GroupsList;
