export type Validator = (val: string | null) => {
  valid: boolean;
  error?: string;
};

const validateNumber: Validator = (value: string | null) => {
  return {
    valid: value === null || !value.match(/[^\d]/) || !isNaN(parseFloat(value)),
  };
};

export const validatorByFieldName: Record<string, Validator> = {
  wb_price: validateNumber,
  ozon_price: validateNumber,
  ya_price: validateNumber,
  sber_price: validateNumber,
  ali_price: validateNumber,
  wb_discont_price: validateNumber,
  ozon_discont_price: validateNumber,
  ya_discont_price: validateNumber,
  sber_discont_price: validateNumber,
  ali_discont_price: validateNumber,
  default: () => ({
    valid: true,
  }),
};
