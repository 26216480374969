import React, { useEffect } from "react";
import { FilterState } from "../Filters/useFilterState";
import { useReviewsState } from "./useReviewsState";
import { Pagination, Spin } from "antd";
import { ReviewsItem } from "./ReviewItem";
import { Space } from "shared/ui";
import { ReviewsProps } from "api/Reviews";
import { LoadingOutlined } from "@ant-design/icons";
import { DisablePage } from "../styled";

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
export const Reviews = (props: { filters: FilterState }) => {
  const { reviews, fetchData, total, totalPage, updateReview, pending } =
    useReviewsState();
  useEffect(() => {
    const options = {
      marketplace: props.filters.state.marketplace.map((el) => el.value),
      sorting: props.filters.state.sorting.value,
      rating: props.filters.state.rating,
      article: props.filters.state.search,
      status: props.filters.state.status.map((el) => el.value),
      page: props.filters.state.page,
    };
    fetchData(options);
  }, [props.filters]);

  const handleUpdate = async (id: number, data: Partial<ReviewsProps>) => {
    await updateReview(id, data);
  };

  return (
    <>
      <div>
        <Pagination
          current={props.filters.state.page}
          onChange={(value) =>
            value !== props.filters.state.page &&
            props.filters.update({ page: value })
          }
          total={totalPage}
          pageSize={1}
          showSizeChanger={false}
          style={{ display: "inline-block" }}
        />
        <Space size={24} inline />
        <span style={{ verticalAlign: "top", lineHeight: "35px" }}>
          Найдено: {total}
        </span>
      </div>
      <Space size={24} axis="vertical" />
      <>
        <DisablePage disabled={pending}>
          {pending && (
            <Spin
              indicator={antIcon}
              style={{ position: "absolute", margin: 20 }}
            />
          )}
          {reviews.map((el) => (
            <ReviewsItem key={el.id} data={el} onUpdate={handleUpdate} />
          ))}
        </DisablePage>
      </>
      <Space size={24} axis="vertical" />

      <Pagination
        current={props.filters.state.page}
        onChange={(value) =>
          value !== props.filters.state.page &&
          props.filters.update({ page: value })
        }
        total={totalPage}
        pageSize={1}
        showSizeChanger={false}
      />
    </>
  );
};
