export const fetchApi = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined
) => {
  return fetch(input, {
    ...init,
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      ...(init?.headers || {}),
    },
  });
};

type FetchProps = (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  removeContentType?: boolean
) => Promise<Response>;

const fetchApiWithAuth: FetchProps = async (input, init, removeContentType) => {
  const authToken = localStorage.getItem("auth-token");
  const result = await fetch(input, {
    ...init,
    headers: new Headers({
      ...(!removeContentType
        ? { "Content-Type": "application/json;charset=utf-8" }
        : {}),
      ...(init?.headers || {}),
      Authorization: `Bearer ${authToken}`,
    }),
  });
  if ((result?.status as any) === 401 && window.location.pathname !== "/") {
    window.location.pathname = "/";
  }
  if ((result?.status as any) >= 500) {
    alert(result?.status + " " + result?.statusText);
  }
  return result;
};

export default fetchApiWithAuth;
