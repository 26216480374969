import fetchApiWithAuth from "./fetch";

export type GoodsProps = {
  article: string;
  name: string;
  category: { id: number; name: string } | null;
  brand: { name: string } | null;
  product_remnants?: { show_for_distribution: boolean };
  is_combo: boolean;
  comboComposition?: { article: string; value: number }[];
  wb_data?: { alias?: string | null; barcode?: string | null } | null;
  ozon_data?: { alias?: string | null } | null;
  ya_data?: { alias?: string | null } | null;
  sber_data?: { alias?: string | null } | null;
  ali_data?: { alias?: string | null } | null;
};

export const getAll = async () => {
  const result = await fetchApiWithAuth("/api/goods");
  const data = (await result.json()) as GoodsProps[];
  return data;
};

export const create = async (props: GoodsProps) => {
  const result = await fetchApiWithAuth("/api/goods", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as GoodsProps;
  return data;
};

export const edit = async (article: string, props: Partial<GoodsProps>) => {
  const result = await fetchApiWithAuth(`/api/goods/?article=${article}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as GoodsProps;
  return data;
};

export const remove = async (article: string) => {
  const result = await fetchApiWithAuth(`/api/goods/?article=${article}`, {
    method: "DELETE",
  });

  return result;
};

export const getBrands = async () => {
  const result = await fetchApiWithAuth(`/api/goods/brands`, {
    method: "GET",
  });
  const data = (await result.json()) as { name: string }[];

  return data;
};

export const getCategories = async () => {
  const result = await fetchApiWithAuth(`/api/goods/categories`, {
    method: "GET",
  });
  const data = (await result.json()) as { id: number; name: string }[];

  return data;
};

export const uploadXlsx = async (props: { data: File }) => {
  const localVarFormData = new FormData();
  localVarFormData.append("file", props.data);
  const result = await fetchApiWithAuth(
    `/api/goods/upload_xlsx`,
    {
      method: "POST",
      body: localVarFormData,
    },
    true
  );

  return (await result.json()) as {
    headers: string[];
    rows: (string | null)[][];
  };
};
