import React from "react";
import { RemainsHeader } from "./DeliveriesHeader";
import DeliveriesTable from "./Table";
import { useStore } from "./hooks/useStore";

const Deliveries = () => {
  const {
    data,
    warehouseFilter,
    setWarehouseFilter,
    totalPage,
    currentPage,
    setCurrentPage,
  } = useStore();
  return (
    <>
      <RemainsHeader
        warehouseFilter={warehouseFilter}
        setWarehouseFilter={setWarehouseFilter}
        totalPage={totalPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <DeliveriesTable
        data={data}
        onSort={(sort) => {
          console.log(sort);
        }}
      />
    </>
  );
};

export default Deliveries;
