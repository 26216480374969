import React, { useMemo, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab, Form } from "react-bootstrap";
import { GoodsItem } from "./GoodsItems";
import { ProductCategoiesItem } from "./ProductCategoiesItem";
import { useGoods } from "./useGoods";

const Goods = () => {
  const [filter, setFilter] = useState("");
  const {
    goods,
    createGoods,
    showNew,
    setShowNew,
    deleteGoods,
    updateGoods,
    brands,
    productCategories,
  } = useGoods();
  const filteredGoods = useMemo(() => {
    return filter
      ? goods.filter(
          (el) =>
            el.article.includes(filter) ||
            el.wb_data?.barcode?.includes(filter) ||
            el.wb_data?.alias?.includes(filter) ||
            el.ozon_data?.alias?.includes(filter) ||
            el.ya_data?.alias?.includes(filter) ||
            el.sber_data?.alias?.includes(filter) ||
            el.ali_data?.alias?.includes(filter) ||
            el.name.includes(filter) ||
            (el.category?.name || "без категории").includes(filter)
        )
      : goods;
  }, [goods, filter]);
  console.log("filteredGoods, goods, filter", filteredGoods, goods, filter);
  return (
    <Container>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 0" }}
            onClick={() => setShowNew(!showNew)}
          >
            {!showNew ? "Создать товар" : "Скрыть товар"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {showNew && (
            <GoodsItem
              onCreate={createGoods}
              isNew
              brands={brands}
              productCategories={productCategories}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: 8, marginBottom: 8 }}>
        <Col md={6}>
          <Form.Control
            placeholder="Фильтр"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {filteredGoods.map((el) => (
            <GoodsItem
              key={el.article}
              data={el}
              article={el.article}
              onUpdate={updateGoods}
              onDelete={deleteGoods}
              brands={brands}
              productCategories={productCategories}
            />
          ))}
          {!goods.length && "Нет созданных товаров"}
          {goods.length && !filteredGoods.length ? "Товары не найдены" : null}
        </Col>
      </Row>
    </Container>
  );
};

export default Goods;
