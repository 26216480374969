import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import outerTheme from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App/App";
import "./utils/Date";

import { Button, ConfigProvider, Form, InputNumber } from "antd";
import colors from "shared/colors";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const defaultData = {
  colorPrimary: colors.main,
  colorWarning: colors.warning,
};

root.render(
  // <React.StrictMode>
  <ConfigProvider theme={{ token: { ...defaultData } }}>
    <ThemeProvider theme={outerTheme}>
      <App></App>
    </ThemeProvider>
  </ConfigProvider>
  // </React.StrictMode>
);
