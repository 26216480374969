import React from "react";
import styled from "styled-components";
import { ReactComponent as Star } from "./star.svg";
import { Select, Checkbox, Input } from "antd";
import {
  markeplaceOptions,
  ratingOptions,
  sortingOptions,
  statusOptions,
} from "./filterOptions";
import { FilterState } from "./useFilterState";

const ItemWrap = styled.div`
  margin-bottom: 16px;
`;

const ItemTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
`;

const ItemConeten = styled.div``;

const stars = new Array(5).fill(0).map((el, i) => <Star />);

export const Template = (props: React.PropsWithChildren<{ title: string }>) => {
  return (
    <ItemWrap>
      <ItemTitle>{props.title}</ItemTitle>
      <ItemConeten> {props.children}</ItemConeten>
    </ItemWrap>
  );
};

export const Rating = (props: { filters: FilterState }) => {
  const handleChange = (value: {
    target: { value?: number; checked: boolean };
  }) => {
    props.filters.update({
      rating: value.target.checked
        ? [...props.filters.state.rating, value.target.value!]
        : props.filters.state.rating.filter((el) => el !== value.target.value!),
    });
  };
  return (
    <Template title={"Рейтинг"}>
      {ratingOptions.map((el) => (
        <>
          <Checkbox
            key={el.toString()}
            checked={props.filters.state.rating.includes(el)}
            onChange={handleChange}
            value={el}
          >
            {stars.slice(0, el)}
          </Checkbox>
          <br key={el.toString() + "br"} />
        </>
      ))}
    </Template>
  );
};

export const Status = (props: { filters: FilterState }) => {
  const handleChange = (value: {
    target: { value?: { value: string; label: string }; checked: boolean };
  }) => {
    props.filters.update({
      status: value.target.checked
        ? [...props.filters.state.status, value.target.value!]
        : props.filters.state.status.filter((el) => el !== value.target.value!),
    });
  };
  return (
    <Template title={"Состояние"}>
      {statusOptions.map((el) => (
        <>
          <Checkbox
            key={el.value}
            checked={
              !!props.filters.state.status.find((m) => m.value === el.value)
            }
            onChange={handleChange}
            value={el}
          >
            {el.label}
          </Checkbox>
          <br />
        </>
      ))}
    </Template>
  );
};

export const Markeplace = (props: {
  filters: FilterState;
  markeplaceOptions: { value: string; label: string }[];
}) => {
  const handleChange = (value: {
    target: { value?: { value: string; label: string }; checked: boolean };
  }) => {
    props.filters.update({
      marketplace: value.target.checked
        ? [...props.filters.state.marketplace, value.target.value!]
        : props.filters.state.marketplace.filter(
            (el) => el !== value.target.value!
          ),
    });
  };
  return (
    <Template title={"Маркетплейс"}>
      {props.markeplaceOptions.map((el) => (
        <>
          <Checkbox
            key={el.value}
            checked={
              !!props.filters.state.marketplace.find(
                (m) => m.value === el.value
              )
            }
            onChange={handleChange}
            value={el}
          >
            {el.label}
          </Checkbox>
          <br />
        </>
      ))}
    </Template>
  );
};

export const Search = (props: { filters: FilterState }) => {
  return (
    <Template title={"Поиск по артикулу"}>
      <Input
        placeholder="Поиск"
        value={props.filters.state.search}
        style={{ width: "100%" }}
        onChange={(value) =>
          props.filters.update({
            search: value.target.value,
          })
        }
      />
    </Template>
  );
};

export const Sorting = (props: { filters: FilterState }) => {
  return (
    <Template title={"Сортировка"}>
      <Select
        style={{ width: "100%" }}
        value={props.filters.state.sorting.value}
        onChange={(_, value) =>
          props.filters.update({
            sorting: value as typeof props.filters.state.sorting,
          })
        }
        options={sortingOptions}
      ></Select>
    </Template>
  );
};
