import {
  GoodsWithPriceForUpdate,
  GoodsWithPriceProps,
  ScheduleTask,
} from "api/PriceLoader";
import React, { useState } from "react";
import { Col, Row } from "./scheduleStyled";
import { Button, Switch } from "antd";
import ScheduleItemModal from "./ScheduleItemModal";

const ScheduleItem = (props: {
  goodsOptions: { value: string; label: string }[];

  data: ScheduleTask;
  onUpdate: (id: number, props: ScheduleTask) => Promise<void>;
  onDelete: (id: number) => void;
}) => {
  const [edit, setEdit] = useState(false);
  const [pendingTurnOn, setPendingTurnOn] = useState(false);
  const onClick = () => {
    // props.update();
  };
  const turnOnTask = async (started: boolean) => {
    setPendingTurnOn(true);
    await props.onUpdate(props.data.id, { ...props.data, started });
    setPendingTurnOn(false);
  };

  const onChange = async (data: Omit<ScheduleTask, "id">) => {
    await props.onUpdate(props.data.id, { id: props.data.id, ...data });
    setEdit(false);
  };
  return (
    <>
      <Row>
        <Col>
          <Switch
            loading={pendingTurnOn}
            checked={props.data.started}
            onChange={() => {
              turnOnTask(!props.data.started);
            }}
          />
        </Col>
        <Col>{props.data.name}</Col>
        <Col>{props.data.startHour}</Col>
        <Col>{props.data.startMinute}</Col>
        <Col>{props.data.discountAmount}</Col>
        <Col>{props.data.goods?.map((el) => el.article).join(", ")}</Col>
        <Col>
          <Button onClick={() => setEdit(true)}>ред</Button>
        </Col>
      </Row>
      {edit && (
        <ScheduleItemModal
          goodsOptions={props.goodsOptions}
          data={props.data}
          onSubmit={onChange}
          onClose={() => setEdit(false)}
        />
      )}
    </>
  );
};

export default ScheduleItem;
