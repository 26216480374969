import fetchApi from "./fetch";

export type Props = {
  name: string;
  translate: string;
  value: boolean;
};

export const getAllFeatureFlags = async () => {
  const result = await fetchApi(`/api/features`);
  const data = (await result.json()) as Props[];
  return data;
};

export const updateFeatureFlagsValue = async (name: string, value: boolean) => {
  const result = await fetchApi(`/api/features/${name}`, {
    method: "PATCH",
    body: JSON.stringify({ value }),
  });

  return result;
};
