import React, { Children, useEffect, useMemo, useState } from "react";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";
import { ProductCategoiesItem } from "../ProductCategoiesItem";
import { Button } from "antd";
import { getAll, create, edit, ClassifierProps } from "../../api/Classifier";
import Tree from "./Tree";
import TreeNode from "./TreeNode";

const useClassifier = () => {
  const [state, setState] = useState<ClassifierProps[]>([]);
  const fetchData = async () => {
    const data = await getAll();
    setState(data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return { state, fetchData };
};

const Classifier = () => {
  const { state, fetchData } = useClassifier();
  const handleCreate = async (props: { parentId: number | null }) => {
    await create({
      name: "Категория",
      description: "",
      children: [],
      parentId: props.parentId,
    });
    fetchData();
  };
  const handleUpdate = async (props: { id: number; title: string }) => {
    await edit(props.id, {
      name: props.title,
    });
    fetchData();
  };
  const nodes = state;
  return (
    <Container>
      <Row>
        <Button onClick={() => handleCreate({ parentId: null })}>
          Добавить
        </Button>
        <Tree
          nodes={nodes}
          nodeComponent={TreeNode}
          onCreate={handleCreate}
          onUpdate={handleUpdate}
        />
      </Row>
    </Container>
  );
};

export default Classifier;
