import { useEffect, useState } from "react";
import * as AnswerTemplatesApi from "../api/AnswerTemplates";
import { AnswerTemplateProps } from "../api/AnswerTemplates";

export type ItemAnswerTemplateProps = Omit<AnswerTemplateProps, "id">;

export const useAnswerTemplates = () => {
  const [showNew, setShowNew] = useState(false);
  const [answerTemplates, setAnswerTemplates] = useState<AnswerTemplateProps[]>(
    []
  );
  const fetchAnswerTemplate = async () => {
    const result = await AnswerTemplatesApi.getAll();
    setAnswerTemplates(result);
  };
  const createAnswerTemplate = async (props: ItemAnswerTemplateProps) => {
    const result = await AnswerTemplatesApi.create(props);
    setAnswerTemplates([result, ...answerTemplates]);
    setShowNew(false);
  };
  const updateAnswerTemplate = async (props: AnswerTemplateProps) => {
    await AnswerTemplatesApi.edit(props.id, props);
    setAnswerTemplates(
      answerTemplates.map((el) => (el.id === props.id ? props : el))
    );
  };
  const deleteAnswerTemplate = async (id: number) => {
    await AnswerTemplatesApi.remove(id);
    setAnswerTemplates(answerTemplates.filter((el) => el.id !== id));
  };
  useEffect(() => {
    fetchAnswerTemplate();
  }, []);
  return {
    answerTemplates,
    createAnswerTemplate,
    showNew,
    setShowNew,
    deleteAnswerTemplate,
    updateAnswerTemplate,
  };
};
