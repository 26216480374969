import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ProductEdit from "./components/ProductEdit";
import { useParams } from "react-router";

const PriceMonitoringProductView = () => {
  const { id } = useParams<{ id: string }>();
  const idVal = id && !isNaN(+id) ? +id : undefined;
  return <Container>{/* <ProductEdit id={idVal} /> */}</Container>;
};
export default PriceMonitoringProductView;
