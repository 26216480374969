import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";
import {
  getAuthStatus,
  AuthStatus,
  AuthWB,
  AuthWBCode,
} from "../../api/MarketplaceAuth";

const AuthWb = () => {
  const [sellerIdNew, setSellerIdNew] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [cookie, setCookie] = useState("");
  const [publicToken, setPublicToken] = useState("");

  const updateData = () => {};

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h4 style={{ margin: "16px 0 32px" }}>Wildberries</h4>
          <Row>
            <Col>
              <Form.Label>
                Seller Id
                <br />
                (В шапке сайта)
              </Form.Label>
            </Col>
            <Col xs={8} md={8} lg={8}>
              <Form.Control
                placeholder="Seller Id"
                value={sellerIdNew}
                onChange={(e) => {
                  setSellerIdNew(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>
                Api token
                <br />
                (в настройках)
              </Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                placeholder="ApiToken"
                value={apiToken}
                onChange={(e) => {
                  setApiToken(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>Cookie</Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                placeholder="cookie"
                value={cookie}
                onChange={(e) => {
                  setCookie(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Form.Label>
                Token
                <br />
                (от публичной части)
              </Form.Label>
            </Col>
            <Col xs={8}>
              <Form.Control
                placeholder="token"
                value={publicToken}
                onChange={(e) => {
                  setPublicToken(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "16px" }}>
            <Col>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  updateData();
                }}
              >
                Сохранить
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default AuthWb;
