import React, { useEffect, useState } from "react";
import { Modal, Button, Input } from "antd";
import { Table, Container, Form } from "react-bootstrap";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ReviewsProps, getAnswerVariantsById, Variants } from "api/Reviews";

import styled from "styled-components";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Wrap = styled.div`
  overflow: auto;
  position: relative;
  height: 230px;
  width: 500px;
  border: 1px solid #ccc;
  box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  padding: 8px;
  overflow-y: scroll;
`;
const Content = styled.div`
  margin: 0px 4px 4px 4px;
  padding: 4px;
  border: 1px solid #ccc;
  &:hover {
    background-color: #ffffe0;
  }

  &:active {
    background-color: #fffacd;
  }
  user-select: none;
  cursor: pointer;
`;

const ChooseVariantItem = (props: {
  data: Variants[number];
  onSelect: (text: string) => void;
}) => {
  return (
    <Content
      onClick={() =>
        props.onSelect(
          props.data.error ? props.data.templateText : props.data.text!
        )
      }
    >
      {props.data.name}
      <br />
      {props.data.error ? (
        <>
          {props.data.error}
          <br />
          {props.data.templateText}
        </>
      ) : (
        <> {props.data.text}</>
      )}
    </Content>
  );
};
const ChooseVariant = (props: {
  id: number;
  onSelect: (text: string) => void;
}) => {
  const [variants, setVariants] = useState<Variants>([]);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    const fetch = async () => {
      setPending(true);
      const result = await getAnswerVariantsById({ id: props.id });
      setVariants(result);
      setPending(false);
    };
    fetch();
  }, []);
  return (
    <Wrap>
      {pending && <Spin indicator={antIcon} />}
      {variants.map((el) => (
        <ChooseVariantItem data={el} onSelect={props.onSelect} />
      ))}
    </Wrap>
  );
};

export default ChooseVariant;
