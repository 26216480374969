import fetchApi from "./fetch";
export type RemnatsProps = {
  article: string;
  name: string;
  is_combo: boolean;
  product_remnants: {
    remnant: number;
    reserved_in_ali: number;
    reserved_in_ozon: number;
    reserved_in_ozon_fresh: number;
    reserved_in_sber: number;
    reserved_in_wildberries: number;
    reserved_in_ya: number;
    reserved_in_combo: number;
    share_in_ali: string | null;
    share_in_ozon: string | null;
    share_in_sber: string | null;
    share_in_wildberries: string | null;
    share_in_ya: string | null;
    share_in_combo: string | null;
    show_for_distribution: boolean;
  };
  comboComposition?: { article: string; value: number }[];
  wb_data?: { disabled_for_send: boolean };
  ozon_data?: { disabled_for_send: boolean };
  ya_data?: { disabled_for_send: boolean };
  sber_data?: { disabled_for_send: boolean };
  ali_data?: { disabled_for_send: boolean };
};

export const getRemnants = async () => {
  const result = await fetchApi("/api/product_remnants", {
    method: "GET",
  });
  const data = (await result.json()) as RemnatsProps[];
  return data;
};

export const patchRemnants = async (
  props: { article: string; data: Partial<RemnatsProps["product_remnants"]> }[]
) => {
  const result = await fetchApi("/api/product_remnants", {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as RemnatsProps[];
  return data;
};

export const manualSend = async (props: { article: string }[]) => {
  const result = await fetchApi("/api/product_remnants/manual_send", {
    method: "post",
    body: JSON.stringify(props),
  });
  const data = await result.json();
  return data;
};

export const updateMPData = async (
  props: {
    article: string;
    wb_data?: { disabled_for_send: boolean };
    ozon_data?: { disabled_for_send: boolean };
    ya_data?: { disabled_for_send: boolean };
    sber_data?: { disabled_for_send: boolean };
    ali_data?: { disabled_for_send: boolean };
  }[]
) => {
  const result = await fetchApi("/api/product_remnants/updateMPData", {
    method: "post",
    body: JSON.stringify(props),
  });
  const data = await result.json();
  return data;
};

export const getRemnantsXlsx = async () => {
  const result = await fetchApi("/api/product_remnants/xlsx", {
    method: "get",
  });

  return result;
};
