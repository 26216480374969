import React from "react";
import ReactDOM from "react-dom";
import { modalRoot } from "../Portal";

export const asyncPopup = (children: React.ReactElement) => {
  const node = document.createElement("div");
  const unmount = () => {
    ReactDOM.unmountComponentAtNode(node);
    if (node?.parentNode) node.parentNode.removeChild(node);
  };
  modalRoot?.appendChild(node);

  return new Promise((res) => {
    const handleAction = (value: boolean) => {
      unmount();
      res(value);
    };
    const childrenProps = {
      ...children?.props,
      onClose: handleAction,
    };
    ReactDOM.render(React.cloneElement(children, childrenProps), node);
  });
};

export default asyncPopup;
