import React, { useRef, useState } from "react";
import { getGroups } from "api/PriceMonitoring";
import { Button, Col, Row } from "antd";
import useTvView from "./useTvView";
import Group from "./Group";
import { Space } from "shared/ui";
import { useNavigate } from "react-router";
import { ReactComponent as Start } from "./start.svg";
import { ReactComponent as Stop } from "./stop.svg";
import { ProductGroup } from "PriceMonitoring/modal";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
const price_monitoring_view_collapsed = "price_monitoring_view_collapsed";

const get = () =>
  JSON.parse(
    localStorage.getItem(price_monitoring_view_collapsed) || "{}"
  ) as Record<number, boolean>;
const record = (categoryId: number, value: boolean) => {
  localStorage.setItem(
    price_monitoring_view_collapsed,
    JSON.stringify({
      ...get(),
      [categoryId]: value,
    })
  );
};

const read = (categoryId: number): boolean => {
  const value = get();
  return value[categoryId] || false;
};

const dragStartAction = (drag: HTMLDivElement) => {};
const dragPrevOverAction = (slot: HTMLDivElement) => {
  slot.removeAttribute("data-slot");
};
const dragOverAction = (
  drag: HTMLDivElement,
  slot: HTMLDivElement,
  e: React.DragEvent
) => {
  const rect = slot.getBoundingClientRect();
  const start = rect.x;
  const end = rect.x + rect.width;

  const value = slot.getAttribute("data-slot");
  const newValue = e.pageX < start + rect.width / 2 ? "left" : "right";
  if (value !== newValue) {
    slot.setAttribute("data-slot", newValue);
  }
};
const Groups = (props: {
  categoryId: number;
  title: string;
  groups: ProductGroup[];
  timestamp: string;
  changeOrder: (id: number, targetId: number, position: string) => void;
}) => {
  const [collapsed, setCollapsed] = useState(read(props.categoryId));
  const handleCollapsed = () => {
    record(props.categoryId, !collapsed);
    setCollapsed(!collapsed);
  };
  const dragRef = useRef<HTMLDivElement | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const { groups, timestamp, changeOrder } = props;

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    dragRef.current = e.target as HTMLDivElement;
    dragStartAction(dragRef.current);
  };
  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (targetRef.current) {
      changeOrder(
        +dragRef.current?.getAttribute("data-group-id")!,
        +targetRef.current?.getAttribute("data-group-id")!,
        targetRef.current?.getAttribute("data-slot")!
      );
    }
    targetRef.current && dragPrevOverAction(targetRef.current);

    dragRef.current = null;
    targetRef.current = null;
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let target = (e.target as HTMLDivElement)?.closest(
      '[draggable="true"]'
    ) as HTMLDivElement | null;
    target =
      target?.getAttribute("data-category-id") ===
      dragRef.current?.getAttribute("data-category-id")
        ? target
        : null;
    if (target !== targetRef.current) {
      targetRef.current && dragPrevOverAction(targetRef.current);
      targetRef.current = target;
    }
    targetRef.current && dragOverAction(dragRef.current!, targetRef.current, e);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let target = (e.target as HTMLDivElement)?.closest(
      '[draggable="true"]'
    ) as HTMLDivElement | null;
    target =
      target?.getAttribute("data-category-id") ===
      dragRef.current?.getAttribute("data-category-id")
        ? target
        : null;
    if (target === targetRef.current) {
      targetRef.current && dragPrevOverAction(targetRef.current);
      targetRef.current = null;
    }
  };

  return (
    <>
      <div
        style={{ margin: "20px 0", fontSize: "1.4em", fontWeight: "bold" }}
        onClick={handleCollapsed}
      >
        {props.title} {!collapsed ? <DownOutlined /> : <UpOutlined />}
      </div>
      {!collapsed && (
        <div
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
        >
          <Row gutter={[8, 8]}>
            {groups.map((el) => (
              <Col span={6} key={el.id}>
                <Group
                  key={el.id}
                  data={el}
                  timestamp={timestamp}
                  categoryId={props.categoryId}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};
export default Groups;
