import styled from "styled-components";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

export const FieldWrap = styled.div`
  display: flex;
`;

export const FieldTitle = styled.div`
  padding-right: 8px;
  min-width: 120px;
  display: inline-block;
`;

export const LinkTitle = styled.a`
  overflow: hidden;
  flex: 1 1 auto;
  white-space: nowrap;
  margin-right: 16px;
  text-overflow: ellipsis;
`;

export const Check = styled(CheckOutlined)``;
export const Close = styled(CloseOutlined)``;
export const Delete = styled(DeleteOutlined)``;
export const Edit = styled(EditOutlined)``;
export const EyeInvisible = styled(EyeInvisibleOutlined)``;
export const Eye = styled(EyeOutlined)``;

export const RowWrap = styled.div`
  display: flex;
  align-items: center;
  ${Check}, ${Close}, ${Delete}, ${Edit},${EyeInvisible}, ${Eye} {
    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      color: #ccc;
    }
    margin-right: 4px;
    background: rgba(0, 0, 0, 0.05);
    padding: 4px;
    border-radius: 4px;
  }
  margin: 0 0 8px 0;
`;
