import { useEffect, useMemo, useReducer, useState } from "react";
import { DataRow, Product } from "../model";
import { getProducts } from "api/deliveries/Products";
import { useRemains } from "./useRemains";
import { Warehouse } from "api/deliveries/Warehouses";
import { usePaginator } from "shared/Paginator";

export const useStore = () => {
  const [pendingProduct, setPendingProduct] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const { remains, pendingRemains, updateWarehouseId } = useRemains();
  const [currentPage, setCurrentPage] = useState(1);
  const [warehouseFilter, setWarehouseFilter] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const data: DataRow[] = useMemo(() => {
    return products.map(
      (el) => ({ ...el, remains: remains?.[el.product_id] } as any)
    );
  }, [products, remains]);

  const totalPage = Math.ceil(data.length / 10) || 1;

  const rows: DataRow[] = useMemo(() => {
    const vewVal = [...data];
    vewVal.sort();
    return vewVal.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10);
  }, [data, currentPage]);

  useEffect(() => {
    (async () => {
      setPendingProduct(true);
      const result = await getProducts();

      setProducts(
        result.map((el) => ({
          product_id: el.id,
          article: el.article,
          name: el.name,
        }))
      );
      setPendingProduct(false);
    })();
  }, []);

  const updateWarehouseFilter = (val: { value: number; label: string }) => {
    console.log(val);
    updateWarehouseId(val.value);
    setWarehouseFilter(val);
  };
  return {
    data: rows,
    warehouseFilter,
    setWarehouseFilter: updateWarehouseFilter,
    currentPage,
    setCurrentPage,
    totalPage,
  };
};
