import styled from "styled-components";

export const ListWrap = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 90px - 32px);
`;

export const ItemWrap = styled.div<{ selected: boolean }>`
  padding: 8px 8px;
  border: 2px solid #ccc;
  cursor: pointer;
  ${(props) => props.selected && `border: 2px solid #3aafa9;`}
  &:hover {
    border: 2px solid #3aafa9;
  }
  border-radius: 8px;
`;

export const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ListTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;
