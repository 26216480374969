import React, { useEffect, useState } from "react";
import { Modal, Button, Input } from "antd";
import { Table, Container, Form } from "react-bootstrap";
import dayjs from "dayjs";

import { ReviewsProps, getAnswerVariantsById, Variants } from "api/Reviews";
import ChooseVariant from "./ChooseVariant";
import { Space } from "shared/ui";
import styled from "styled-components";
const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 4px;
`;
const ModalEdit = (props: {
  data: ReviewsProps;
  onUpdate: (value: string) => void;
  onClose: () => void;
}) => {
  const [text, setText] = useState(props.data.answer?.text || "");

  const handleSave = () => {
    props.onUpdate(text);
  };

  return (
    <Modal open onCancel={props.onClose} footer={[]} width={568}>
      <Title>Выбор шаблона</Title>
      <ChooseVariant onSelect={setText} id={props.data.id} />
      <Space axis="vertical" size={24} />
      <Title>Редактирование ответа</Title>
      <Input.TextArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        style={{ width: 500, height: 200 }}
      />
      <Space axis="vertical" size={8} />

      <div>
        <Button type="primary" onClick={handleSave} style={{ width: 140 }}>
          Сохранить
        </Button>
        <Space
          size={16}
          axis="horizontal"
          style={{ display: "inline-block" }}
        />
        <Button onClick={props.onClose} style={{ width: 140 }}>
          Отменить
        </Button>
      </div>
    </Modal>
  );
};

export default ModalEdit;
