import React from "react";
import { ListHeader } from "./ListHeader";
import { Item } from "./Item";

export const List = (props: { articles: any[] }) => {
  const items = props.articles.map((el) => el);
  return (
    <table>
      <ListHeader />
      {items.map((el) => (
        <Item />
      ))}
    </table>
  );
};
