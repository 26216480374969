import React from 'react';

enum SortDirection {
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface SortConfig<Fields> {
  key: keyof Fields;
  direction: SortDirection;
}

export const useSortableData = <Item>(items: Item[]) => {
  const [sortConfig, setSortConfig] = React.useState<SortConfig<Item>>();

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig) {
      const nullableData = sortableItems.filter((el) => el[sortConfig.key] === null);
      let notNullableData = sortableItems.filter((el) => el[sortConfig.key] !== null);

      notNullableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      sortableItems = [...notNullableData, ...nullableData];
    }
    return sortableItems;
  }, [items, sortConfig]);

  const toggleSort = (key: SortConfig<Item>['key']) => {
    if (sortConfig?.key !== key) {
      setSortConfig({ key, direction: SortDirection.Ascending });
    } else if (sortConfig.direction === SortDirection.Ascending) {
      setSortConfig({ key, direction: SortDirection.Descending });
    } else {
      setSortConfig(undefined);
    }
  };

  return { items: sortedItems, toggleSort, sortConfig };
};
