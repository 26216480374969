import React, { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import {
  getRemnants,
  RemnatsProps,
  patchRemnants,
  updateMPData,
} from "../api/ProductRemnants";
import {
  getDinamicRemnant,
  getEval,
  getSubRowsData,
  RemnantRowProps,
  PrepareRemnantRowProps,
  DisabledSend,
} from "./common";
import FullRemnants from "./FullRemnants";
import { TableTitle } from "./RemnantsStyled";
const MIN_THRESHOLD = 0;
const Remnants = () => {
  const pendingUpdate = useRef(false);
  const [remnants, setRemnants] = useState<PrepareRemnantRowProps[]>([]);
  useEffect(() => {
    const fet = async () => {
      const result = await getRemnants();
      const combos = result.filter((el) => el.is_combo);
      const articlesUseInCombo = Object.fromEntries(
        combos
          .map((el) =>
            el.comboComposition
              ? el.comboComposition.map((el) => el.article)
              : []
          )
          .flat()
          .map((el) => [el, el])
      );
      setRemnants(
        result.map((el) => ({
          ...el,
          ...el.product_remnants,
          useInCombo: !!articlesUseInCombo[el.article],
        }))
      );
    };
    fet();
  }, []);

  const calculatedRemnants = useMemo(() => {
    return remnants.map((el) => {
      const subRows = getSubRowsData({
        comboComposition: el.comboComposition,
        remnants: remnants,
      });
      const { remnantToCombo, remnantForSingle } = getDinamicRemnant(el);
      const rowNotError = subRows.every((el) => el.finded);
      let remnantValue = el.is_combo
        ? el.comboComposition?.length && rowNotError
          ? Math.min(...subRows.map((el) => el.remnantDevValue))
          : 0
        : el.useInCombo
        ? remnantForSingle
        : remnantForSingle +
          (typeof remnantToCombo === "number" ? remnantToCombo : 0);
      remnantValue = remnantValue <= MIN_THRESHOLD ? 0 : remnantValue;
      return {
        ...el,
        subRows,
        remnant: el.is_combo ? remnantValue : el.remnant,
        remnantValue,
        rowNotError,
        remnantToCombo,
        remnantForSingle,
        calculeted_wildberries: getEval(el.share_in_wildberries, remnantValue),
        calculeted_ozon: getEval(el.share_in_ozon, remnantValue),
        calculeted_ya: getEval(el.share_in_ya, remnantValue),
        calculeted_sber: getEval(el.share_in_sber, remnantValue),
        calculeted_ali: getEval(el.share_in_ali, remnantValue),
        disabled_send_to_wildberries: el.wb_data?.disabled_for_send ?? false,
        disabled_send_to_ozon: el.ozon_data?.disabled_for_send ?? false,
        disabled_send_to_ya: el.ya_data?.disabled_for_send ?? false,
        disabled_send_to_sber: el.sber_data?.disabled_for_send ?? false,
        disabled_send_to_ali: el.ali_data?.disabled_for_send ?? false,
      };
    });
  }, [remnants]);

  const update = async (
    props: {
      article: string;
      value: Partial<RemnatsProps["product_remnants"]>;
    }[]
  ) => {
    if (pendingUpdate.current) return false;
    pendingUpdate.current = true;
    try {
      await patchRemnants(
        props.map((el) => ({ article: el.article, data: el.value }))
      );

      setRemnants(
        remnants.map((el) => {
          const data = props.find((pr) => pr.article === el.article);
          if (!data) return el;
          console.log(data);
          return {
            ...el,
            ...data.value,
          };
        })
      );
    } catch (e) {
      console.log(e);

      pendingUpdate.current = false;
      return false;
    }
    pendingUpdate.current = false;
    return true;
  };

  const updateDisabledSend = async (
    props: {
      article: string;
      value: Partial<DisabledSend>;
    }[]
  ) => {
    if (pendingUpdate.current) return false;
    pendingUpdate.current = true;
    try {
      const forUpdate = props.map((el) => ({
        article: el.article,
        ...(typeof el.value.disabled_send_to_wildberries === "boolean"
          ? {
              wb_data: {
                disabled_for_send: el.value.disabled_send_to_wildberries,
              },
            }
          : {}),

        ...(typeof el.value.disabled_send_to_ozon === "boolean"
          ? {
              ozon_data: {
                disabled_for_send: el.value.disabled_send_to_ozon,
              },
            }
          : {}),

        ...(typeof el.value.disabled_send_to_ya === "boolean"
          ? {
              ya_data: {
                disabled_for_send: el.value.disabled_send_to_ya,
              },
            }
          : {}),

        ...(typeof el.value.disabled_send_to_sber === "boolean"
          ? {
              sber_data: {
                disabled_for_send: el.value.disabled_send_to_sber,
              },
            }
          : {}),

        ...(typeof el.value.disabled_send_to_ali === "boolean"
          ? {
              ali_data: {
                disabled_for_send: el.value.disabled_send_to_ali,
              },
            }
          : {}),
      }));
      await updateMPData(forUpdate);
      const updateData = remnants.map((el) => {
        const data = forUpdate.find((pr) => pr.article === el.article);
        if (!data) return el;
        console.log(data);
        return {
          ...el,
          ...data,
        };
      });
      console.log("updateData", updateData);
      setRemnants(updateData);
    } catch (e) {
      console.log(e);

      pendingUpdate.current = false;
      return false;
    }
    pendingUpdate.current = false;
    return true;
  };

  return (
    <Container>
      <TableTitle>Товары</TableTitle>
      <FullRemnants
        remnants={calculatedRemnants}
        update={update}
        updateDisabledSend={updateDisabledSend}
      />
    </Container>
  );
};

export default Remnants;
