import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { SeriasFilterState } from "./useSeriasFilterState";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { SaleOptions } from "../api/Sales";
import styled from "styled-components";
import Autocomplete from "@mui/material/Autocomplete";

const Wrap = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: #f1f1f1;
  margin: 8px 0;
  max-width: 800px;
`;
const FieldWrap = styled.div`
  margin-bottom: 16px;
`;
const FieldLabel = styled.div`
  margin-bottom: 4px;
`;
const ChartFilter = (props: {
  data: SeriasFilterState;
  update: (data: Partial<SeriasFilterState> & { id: number }) => void;
  remove: (id: number) => void;
  options: SaleOptions;
  disableRemove: boolean;
}) => {
  return (
    <Wrap>
      <Row>
        <Col xs={12} md={6}>
          <FieldWrap>
            <FieldLabel>Название серии</FieldLabel>
            <TextField
              style={{ width: "100%" }}
              value={props.data.title}
              onChange={(e) =>
                props.update({ title: e.target.value, id: props.data.id })
              }
            />
          </FieldWrap>
        </Col>
        <Col>
          <FieldWrap>
            <FieldLabel>Бренд</FieldLabel>
            <Select
              multiple
              style={{ width: "100%" }}
              value={props.data.brand}
              onChange={(e: any) =>
                props.update({ brand: e.target.value, id: props.data.id })
              }
              placeholder="Бренд"
            >
              {props.options.brands.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FieldWrap>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <FieldWrap>
            <FieldLabel>Маркетплейс</FieldLabel>
            <Select
              multiple
              style={{ width: "100%" }}
              value={props.data.marketplace}
              onChange={(e: any) =>
                props.update({ marketplace: e.target.value, id: props.data.id })
              }
              placeholder="Маркетплейс"
            >
              {props.options.marketplace.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FieldWrap>
        </Col>
        <Col>
          <FieldWrap>
            <FieldLabel>Категории</FieldLabel>
            <Autocomplete
              multiple
              options={props.options.categories}
              getOptionLabel={(option) => option.name}
              value={props.data.category}
              onChange={(e: any, value) => {
                props.update({ category: value, id: props.data.id });
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </FieldWrap>
        </Col>
      </Row>
      <Row>
        <Col>
          <FieldWrap>
            <FieldLabel></FieldLabel>
            <Autocomplete
              multiple
              options={props.options.names}
              getOptionLabel={(option) => option.name}
              value={props.data.name}
              onChange={(e: any, value) => {
                props.update({ name: value, id: props.data.id });
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </FieldWrap>
          <Button
            disabled={props.disableRemove}
            onClick={(e) => {
              e.preventDefault();
              if (!props.disableRemove) props.remove(props.data.id);
            }}
          >
            Удалить
          </Button>
        </Col>
      </Row>
    </Wrap>
  );
};
export default ChartFilter;
