import React, { useEffect, useState } from "react";
import AuthOzon from "./AuthOzon";
import AuthWb from "./AuthWb";
const AuthMarketplace = () => {
  return (
    <>
      <AuthOzon />
      <AuthWb />
    </>
  );
};

export default AuthMarketplace;
