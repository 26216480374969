import { Button, Input, Pagination, Select, Tooltip } from "antd";
import React from "react";
import { Space } from "shared/ui";
import styled from "styled-components";
import { Row, Col } from "antd";

const Line = styled.div`
  display: flex;
`;

export const RemainsHeader = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Line>
            <Input placeholder="Поиск" style={{ width: "200px" }} />
            <Space size={20} />
            <Select placeholder="Магазин" style={{ width: "200px" }} />
            <Space />
            <Tooltip title="Скачать текущее состояние таблицы">
              <Button>Скачать</Button>
            </Tooltip>
            <Space size={20} />
            <Tooltip title="Массовое обновление полей с распределением">
              <Button>Загрузить</Button>
            </Tooltip>
          </Line>
        </Col>
        <Col span={24}>
          <Pagination
            current={1}
            // onChange={(value) =>
            //   value !== props.filters.state.page &&
            //   props.filters.update({ page: value })
            // }
            total={10}
            pageSize={1}
            showSizeChanger={false}
            style={{ display: "inline-block" }}
          ></Pagination>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};
