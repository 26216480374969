import React, { useEffect, useState } from "react";
import AttachmentButton from "shared/GoodDataUploader/AttachmentButton";
import { Container } from "react-bootstrap";
import { getAll, uploadXlsx } from "api/Goods";
import ModalGoodsData from "shared/GoodDataUploader/ModalGoodsData";
import PriceList from "./components/PriceList";
import columnOptions from "./components/columnOptions";
import {
  GoodsWithPriceForUpdate,
  GoodsWithPriceProps,
  getPriceXlsxTemplate,
  getPrices,
  updatePriceFromXlsx,
  updatePrices,
  getScheduleTasks,
  ScheduleTask,
  createScheduleTask,
  updateScheduleTask,
  deleteScheduleTask,
} from "api/PriceLoader";
import { Space } from "shared/ui";
import FileSaver from "file-saver";
import { Button } from "antd";
import ScheduleList from "./components/ScheduleList";
import ScheduleItem from "./components/ScheduleItem";
import ScheduleItemModal from "./components/ScheduleItemModal";

const PriceLoader = () => {
  const [prices, setPrices] = useState<GoodsWithPriceProps[]>([]);
  const [tasks, setTasks] = useState<ScheduleTask[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [goodsOptions, setGoodsOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const fetchRows = async () => {
    const [prices, tasks, goods] = await Promise.all([
      getPrices(),
      getScheduleTasks(),
      getAll(),
    ]);
    const goodsOptions = goods.map((el) => ({
      value: el.article,
      label: el.article,
    }));
    goodsOptions.sort((a, b) =>
      a.value > b.value ? 1 : a.value < b.value ? -1 : 0
    );
    setPrices(prices);
    setTasks(tasks);
    setGoodsOptions(goodsOptions);
  };

  useEffect(() => {
    fetchRows();
  }, []);

  const handleUpdate = async (id: number, data: ScheduleTask) => {
    const result = await updateScheduleTask(id, data);
    setTasks(tasks.map((el) => (el.id === id ? result : el)));
  };
  const handleCreate = async (data: Omit<ScheduleTask, "id">) => {
    console.log(data);
    const result = await createScheduleTask(data);
    setTasks([result, ...tasks]);
    setShowModal(false);
  };
  return (
    <Container>
      <div>
        <Button onClick={() => setShowModal(true)}>Добавить задачу</Button>
      </div>
      <Space axis="vertical" size={40} />
      <ScheduleList>
        {tasks.map((el) => (
          <ScheduleItem
            goodsOptions={goodsOptions}
            key={el.id}
            data={el}
            onUpdate={handleUpdate}
            onDelete={() => {
              //
            }}
          />
        ))}
      </ScheduleList>
      {showModal && (
        <ScheduleItemModal
          goodsOptions={goodsOptions}
          onSubmit={handleCreate}
          onClose={() => setShowModal(false)}
        />
      )}
    </Container>
  );
};
export default PriceLoader;
