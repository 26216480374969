import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Space } from "shared/ui";

const Actions = (props: { addGroup: () => void }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Button type="primary" onClick={() => props.addGroup()}>
        Создать группу
      </Button>
      <Space size={16} inline />
      <Button type="default" onClick={() => navigate("./tv")}>
        К просмотру
      </Button>
    </div>
  );
};

export default Actions;
