import React, { Component, useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

const LightboxShow = (props: { images: string[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  return (
    <>
      <div
        onClick={() => setIsOpen(true)}
        style={{ color: "blue", cursor: "pointer" }}
      >
        Показать фото ({props.images.length})
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={props.images[photoIndex]}
          nextSrc={props.images[(photoIndex + 1) % props.images.length]}
          prevSrc={
            props.images[
              (photoIndex + props.images.length - 1) % props.images.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + props.images.length - 1) % props.images.length
            )
          }
          onImageLoad={() => {
            window.dispatchEvent(new Event("resize"));
          }}
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % props.images.length)
          }
        />
      )}
    </>
  );
};
export default LightboxShow;
