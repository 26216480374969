import fetchApiWithAuth from "./fetch";

export type ProductCategoryProps = {
  id: number;
  name: string;
  genitive: string;
  accusative: string;
  products: { id: number; marketplace: string }[];
  goods: {
    article: string;
    name: string;
    wb_data?: { alias?: string | null };
    ozon_data?: { alias?: string | null };
  }[];
};

export const getAll = async () => {
  const result = await fetchApiWithAuth("/api/product-categories");
  const data = (await result.json()) as ProductCategoryProps[];
  return data;
};

export const create = async (props: Omit<ProductCategoryProps, "id">) => {
  const result = await fetchApiWithAuth("/api/product-categories", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ProductCategoryProps;
  return data;
};

export const edit = async (
  id: number,
  props: Partial<ProductCategoryProps>
) => {
  const result = await fetchApiWithAuth(`/api/product-categories/${id}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ProductCategoryProps;
  return data;
};

export const remove = async (id: number) => {
  const result = await fetchApiWithAuth(`/api/product-categories/${id}`, {
    method: "DELETE",
  });

  return result;
};
