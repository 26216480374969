import React, { useEffect, useMemo, useState } from "react";
import { TvView } from "./useTvView";
import { getGroup } from "api/PriceMonitoring";
import { ProductGroup } from "PriceMonitoring/modal";
import { Row } from "antd";
import { getPassedTime, sortProducts } from "PriceMonitoring/helpers";

import {
  ColRightAlign,
  GroupTitle,
  HeadrCol,
  MainCol,
  StyledRow,
  Wrap,
  Table,
  Link,
} from "./styled";
import wb from "./wb.png";
import ozon from "./ozon.png";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

const round = (price: number) => Math.round(price * 10) / 10;
const GroupItem = (
  props: React.PropsWithChildren<{
    data: ProductGroup["products"][number];
  }>
) => {
  const curentTime = new Date();
  const links = props.data.links.map((el) => ({
    ...el,
    lastTimeUpdate: el.last_price_log?.completed_date
      ? getPassedTime(new Date(el.last_price_log?.completed_date), curentTime)
      : "",
  }));

  return (
    <StyledRow internal={props.data.internal_product}>
      {links.map((el) => (
        <Row gutter={[6, 12]}>
          <HeadrCol span={10}>
            <Link href={el.link} target="_blank" rel="noreferrer">
              {props.data.brand}
            </Link>
          </HeadrCol>
          <MainCol span={2}>
            <img
              src={
                el.link.match(/ozon/)
                  ? ozon
                  : el.link.match(/wildberries/)
                  ? wb
                  : undefined
              }
              alt=""
              style={{ height: 16 }}
            />
          </MainCol>
          <ColRightAlign span={5}>
            {el.last_price_log?.price && `${el.last_price_log?.price}`}
          </ColRightAlign>
          <ColRightAlign span={5}>
            {el.last_price_log?.price &&
              `${round(el.last_price_log?.price / (props.data.quantity || 1))}`}
          </ColRightAlign>
          <ColRightAlign span={2}>
            {el.showArrow === "upper" ? (
              <ArrowUpOutlined style={{ color: "red", fontWeight: "bold" }} />
            ) : el.showArrow === "down" ? (
              <ArrowDownOutlined style={{ color: "red", fontWeight: "bold" }} />
            ) : (
              ""
            )}
          </ColRightAlign>
        </Row>
      ))}
    </StyledRow>
  );
};
export default GroupItem;
