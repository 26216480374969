import { Button, Input, Modal, Select } from "antd";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Space } from "shared/ui";
import { ErrorField, TableWrap } from "./styled";
import { HeaderRow } from "./Header";
import { Row } from "./Row";
import { useRowsData } from "./useRowsData";

const ModalGoodsData = (props: {
  data: { headers: string[]; rows: (string | null)[][] };
  columnOptions: { value: string; label: string }[];
  onSubmit: (props: { headers: string[]; rows: (string | null)[][] }) => void;
  onClose: () => void;
}) => {
  const ref = useRef(window.innerWidth - 100);
  const tableRef = useRef<HTMLDivElement>(null);
  const [errorText, setErrorText] = useState("");

  const [columns, setColumns] = useState<string[]>([]);
  const {
    onChange,
    onSubmit,
    ref: refRows,
    timestampUpdate,
  } = useRowsData(props.data.rows, (rows) =>
    props.onSubmit({ headers: columns, rows: rows })
  );

  useEffect(() => {
    setColumns(
      props.data.headers.map(
        (el) =>
          props.columnOptions.find((c) => c.label === el || c.value === el)
            ?.value || el
      )
    );
  }, [props.data.headers]);
  const onSubmitWithCheckError = () => {
    const fields: NodeListOf<HTMLDivElement> | undefined =
      tableRef.current?.querySelectorAll("[data-error=error]");
    if (fields?.length) {
      const cells = Array.from(fields).map((el) => [
        +el.dataset.row! + 1,
        +el.dataset.cell! + 2,
      ]);
      setErrorText(
        "Ошибка. Ячейки: " +
          cells.map((el) => `[ ${el.join(", ")} ]`).join(", ")
      );
      return;
    }
    onSubmit();
  };
  return (
    <Modal footer={[]} width={ref.current} open onCancel={props.onClose}>
      <TableWrap ref={tableRef}>
        <HeaderRow
          colunmNames={columns}
          columnOptions={props.columnOptions}
          onChange={([value, index]: [string, number]) => {
            setColumns(columns.map((el, i) => (i === index ? value : el)));
          }}
        />
        {refRows.current.map((el, i) => (
          <Row
            rowIndex={i}
            values={el}
            headers={columns}
            onChange={onChange}
            key={i + timestampUpdate}
          />
        ))}
      </TableWrap>
      <Space size={24} axis="vertical" />
      <ErrorField>{errorText}</ErrorField>
      <div>
        <Button onClick={() => onSubmitWithCheckError()} type="primary">
          Применить
        </Button>
        <Space size={24} inline />
        <Button onClick={() => props.onClose()}>Отменить</Button>
      </div>
    </Modal>
  );
};

export default ModalGoodsData;
