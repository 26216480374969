import React, { useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "antd";
import useTvView from "./useTvView";
import { Space } from "shared/ui";
import { useNavigate } from "react-router";
import { ReactComponent as Start } from "./start.svg";
import { ReactComponent as Stop } from "./stop.svg";
import { ProductGroup } from "PriceMonitoring/modal";
import Groups from "./Groups";

const TvView = () => {
  const navigate = useNavigate();
  const { started, startTimer, stopTimer, groups, timestamp, changeOrder } =
    useTvView();

  const groupsByCategory = useMemo(() => {
    const result = groups.reduce((acc, el) => {
      const category = el.category || { id: 0, name: "Без категории" };
      return {
        ...acc,
        [category.id]: acc[category.id]
          ? { ...acc[category.id], groups: [...acc[category.id].groups, el] }
          : {
              categoryId: category.id,
              categoryName: category.name,
              groups: [el],
            },
      };
    }, {} as Record<number, { categoryId: number; categoryName: string; groups: ProductGroup[] }>);
    const withoutCategory = result[0];
    delete result[0];
    const filtered = Object.values(result);
    filtered.sort((a, b) => (a.categoryName < b.categoryName ? -1 : 1));
    if (withoutCategory) {
      filtered.push(withoutCategory);
    }
    return filtered;
  }, [groups]);

  return (
    <>
      <div>
        {!started && (
          <Button onClick={startTimer}>
            <Start style={{ margin: "-2px 4px 0 0" }} />
            Включить автообновление
          </Button>
        )}
        {started && (
          <Button onClick={stopTimer}>
            <Stop style={{ margin: "-2px 4px 0 0" }} />
            Остановить автообновление
          </Button>
        )}
        <Space size={16} inline />
        <Button type="default" onClick={() => navigate("..")}>
          К редактированию
        </Button>
      </div>
      <Space size={32} axis="vertical" />
      <div>
        {groupsByCategory.map((el) => (
          <Groups
            key={el.categoryId}
            groups={el.groups}
            title={el.categoryName}
            changeOrder={changeOrder}
            timestamp={timestamp}
            categoryId={el.categoryId}
          />
        ))}
      </div>
    </>
  );
};
export default TvView;
