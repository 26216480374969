import { useState, useEffect } from "react";
const keyDefault = "remnants_page_size";

const record = (props: number) => {
  localStorage.setItem(keyDefault, JSON.stringify(props));
};

const read = (): number => {
  const value = localStorage.getItem(keyDefault);
  try {
    if (!value) throw new Error();
    const parsed = JSON.parse(value);
    if (typeof parsed !== "number") throw new Error();

    return parsed;
  } catch (e) {}
  return 50;
};

export const usePageSize = () => {
  const [pageSize, setPageSize] = useState(read());

  useEffect(() => {
    record(pageSize);
  }, [pageSize]);

  return { pageSize, setPageSize };
};
