import { useReducer } from "react";
import { sortingOptions, statusOptions } from "./filterOptions";
type Option = { value: string; label: string };

export type FilterProps = {
  status: Option[];
  marketplace: Option[];
  rating: number[];
  search: string;
  sorting: Option;
  page: number;
};

const defaultFilterProps: FilterProps = {
  status: statusOptions.slice(0, -2),
  marketplace: [],
  rating: [],
  search: "",
  sorting: sortingOptions[0],
  page: 1,
};

function reducer(
  state: FilterProps,
  action:
    | { type: "update"; payload: Partial<FilterProps> }
    | { type: "set"; payload: FilterProps }
) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "update":
      return { ...state, page: 1, ...action.payload };
    default:
      throw new Error();
  }
}

export const useFilterState = () => {
  const [state, dispatch] = useReducer(reducer, defaultFilterProps);

  const set = (props: FilterProps) => {
    dispatch({ type: "set", payload: props });
  };

  const update = (props: Partial<FilterProps>) => {
    dispatch({ type: "update", payload: props });
  };

  return { state, set, update };
};

export type FilterState = ReturnType<typeof useFilterState>;
