import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { AnswerTemplateProps } from "../api/AnswerTemplates";
import { ItemAnswerTemplateProps } from "./useAnswerTemplates";

const defaultValues: ItemAnswerTemplateProps = {
  name: "",
  text: "",
  marketplace: "any",
  useForAutogenerate: false,
  useForRating: "",
};
export const AnswerTemplatesItem = ({
  id,
  data = defaultValues,
  ...props
}: {
  id?: number;
  data?: ItemAnswerTemplateProps;
  onCreate?: (props: ItemAnswerTemplateProps) => void;
  onUpdate?: (props: AnswerTemplateProps) => void;
  onDelete?: (id: number) => void;
}) => {
  const [marketplace, setMarketplace] = useState(data.marketplace);
  const [name, setName] = useState(data.name);
  const [text, setText] = useState(data.text);
  const [useForAutogenerate, setUseForAutogenerate] = useState(
    data.useForAutogenerate
  );
  const [useForRating, setUseForRating] = useState(data.useForRating || "");
  console.log(data);
  const validForm = useMemo(() => {
    return !!name && !!text;
  }, [name, text]);
  const handleDelete = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = window.confirm("Действительно хотите удалить?");
    if (!result) return;
    props.onDelete!(id!);
  };
  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validForm) return;
    if (id) {
      props.onUpdate!({
        id,
        name,
        text,
        marketplace,
        useForAutogenerate,
        useForRating,
      });
    } else {
      props.onCreate!({
        name,
        text,
        marketplace,
        useForAutogenerate,
        useForRating,
      });
    }
  };
  const reset = () => {
    setName(data.name);
    setMarketplace(data.marketplace);
    setText(data.text);
    setUseForAutogenerate(data.useForAutogenerate);
  };

  useEffect(() => {
    reset();
  }, [data]);

  return (
    <Form>
      <Row>
        <Col md={6} xs={12}>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Название</Form.Label>
            <Form.Control
              placeholder="Название"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Текст</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Текст ответа"
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{ height: 193, resize: "none" }}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Маркетплейс</Form.Label>
            <Form.Select
              value={marketplace}
              onChange={(e) => setMarketplace(e.target.value)}
            >
              <option value="any">Любой</option>
              <option value="ozon">ozon</option>
              <option value="wildberries">wildberries</option>
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Для рейтинга: </Form.Label>
            {[5, 4, 3, 2, 1].map((el) => (
              <Form.Check
                type="checkbox"
                style={{ display: "inline-block", margin: "0px 8px" }}
                checked={useForRating.includes(el + "")}
                onChange={() =>
                  setUseForRating(
                    useForRating.includes(el + "")
                      ? useForRating.replace(el + "", "")
                      : useForRating + el
                  )
                }
                label={el}
              />
            ))}
          </Form.Group>
          <Form.Check
            type="checkbox"
            checked={useForAutogenerate}
            onChange={() => setUseForAutogenerate(!useForAutogenerate)}
            label={`Использовать для автогенерации ответа`}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 0" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!validForm}
          >
            Сохранить
          </Button>
          {!!id && (
            <Button
              style={{ margin: "16px 16px" }}
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            >
              Cбросить изменения
            </Button>
          )}
          {!!id && (
            <Button
              style={{ margin: "16px 16px 16px 0" }}
              variant="secondary"
              type="submit"
              onClick={handleDelete}
            >
              Удалить
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};
