import React from "react";
import { MainHeaderTr, SubHeaderTr, Th, Thead } from "./ListStyled";
import { Tooltip } from "@mui/material";

export const ListHeader = () => {
  return (
    <Thead>
      <MainHeaderTr style={{ paddingBottom: 4 }}>
        <Th></Th>
        <Th>Артикул</Th>
        <Th style={{ width: 250 }}>Бренд / Название</Th>
        <Th colSpan={2}>Остаток</Th>
        <Th colSpan={4}>Распределение</Th>
        <Th colSpan={4}>Действия</Th>
      </MainHeaderTr>
      <SubHeaderTr style={{ paddingTop: 4 }}>
        <Th></Th>
        <Th></Th>
        <Th></Th>
        <Th className="align-left">На 1с</Th>
        <Th>
          <Tooltip title="Остаток 1с минус резерв со всех МП">
            <span>Свобод</span>
          </Tooltip>
        </Th>
        <Th className="align-left" style={{ width: 125 }}>
          МП/Комбо
        </Th>
        <Th>Резерв</Th>
        <Th>Формула</Th>
        <Th>Расчет</Th>
        <Th>Обновление</Th>
      </SubHeaderTr>
    </Thead>
  );
};
