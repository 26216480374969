import styled from "styled-components";
import { Table, Container, Form } from "react-bootstrap";
import { ReactComponent as ArrowUp } from "./arrowUp.svg";
export const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
`;
export const StyledTable = styled(Table)`
  width: 100%;
  td,
  th {
    padding: 8px 4px;
    &:first-child {
      position: sticky;
      left: 0;
      background: #fff;
      z-index: 1;
    }
  }
  tbody {
    font-size: 12px;
  }
  .input {
    font-size: 12px;
    padding: 4px;
  }
  td[data-selected="true"] {
    background: #ccc;
  }
`;
export const SmallTd = styled.td`
  font-size: 12px;
  width: 50px;
`;
export const SmallTd90 = styled(SmallTd)<{ hover?: boolean }>`
  width: 70px;
`;
export const SmallTd190 = styled(SmallTd90)`
  width: 190px;
`;
export const TableTitle = styled.div`
  font-size: 24px;
  line-height: 1.2;
  margin: 24px 0 12px;
`;

export const SubTr = styled.tr<{ state: string }>`
  background: ${(props) =>
    props.state === "absent"
      ? "rgba(255, 97, 112, 0.35)"
      : props.state === "minimal"
      ? "rgb(220, 251, 231, 0.6)"
      : ""};
`;
export const ArrowUpIcon = styled(ArrowUp)<{ down: boolean }>`
  ${(props) => props.down && `transform: rotate(180deg);`};
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
