import { ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
export const modalRoot = document.querySelector('body > #modal')

const Portal = ({ children }: { children?: ReactNode }) => {
  const nodeRef = useRef(document.createElement('div'))
  useEffect(() => {
    const node = nodeRef.current
    modalRoot?.appendChild(nodeRef.current)
    return () => {
      if (node.parentNode) node.parentNode.removeChild(node)
    }
  }, [])
  return ReactDOM.createPortal(children, nodeRef.current)
}

export default Portal
