import fetchApiWithAuth from "../fetch";

export type Warehouse = {
  id: number;
  external_id: string;
  name: string;
  cluster: string;
};

export const getWarehouses = async () => {
  const result = await fetchApiWithAuth("/deliveries/api/warehouses", {});
  const data = (await result.json()) as Warehouse[];
  return data;
};
