import React, { useEffect, useState } from "react";

import { Button, Col, Input, Row } from "antd";
import {
  Check,
  Edit,
  FieldTitle,
  FieldWrap,
  Close,
  ColStyled,
  Delete,
} from "./styled";

import { Product, ProductGroup } from "PriceMonitoring/modal";
import { getPassedTime } from "./helpers";
import { Space } from "shared/ui";

const ProductListItemRow = (props: {
  onChangeProduct: (val: number) => void;
  onDeleteProduct: (val: number) => void;
  name?: string;
  id: number;
  marketpalce?: string;
  price?: string | number;
  lastUpdate?: string;
  even: boolean;
  internalProduct: boolean;
}) => {
  return (
    <Row
      gutter={[16, 16]}
      style={{
        background: props.internalProduct
          ? "rgba(255,219,139,0.25)"
          : props.even
          ? "rgba(0,0,0,0.05)"
          : "",
      }}
    >
      <ColStyled span={8} title={props.name}>
        {props.name}
      </ColStyled>
      <ColStyled span={2}>{props.marketpalce}</ColStyled>
      <ColStyled span={4}>{props.price}</ColStyled>
      <ColStyled span={4}>{props.lastUpdate}</ColStyled>
      <ColStyled span={2}>
        {props.name && (
          <>
            <Edit onClick={() => props.onChangeProduct(props.id)} />
            <Space size={8} inline />
            <Delete onClick={() => props.onDeleteProduct(props.id)} />
          </>
        )}
      </ColStyled>
    </Row>
  );
};

const ProductListItem = (props: {
  data: Product;
  onChangeProduct: (val: number) => void;
  onDeleteProduct: (val: number) => void;
  even: boolean;
}) => {
  const endTime = new Date();

  return props.data.links?.length ? (
    <>
      {props.data.links.map((el, i) => (
        <ProductListItemRow
          key={props.data.id + "  " + el.id}
          id={props.data.id}
          onChangeProduct={props.onChangeProduct}
          onDeleteProduct={props.onDeleteProduct}
          internalProduct={props.data.internal_product}
          name={
            i === 0 ? `${props.data.brand || ""} ${props.data.name}` : undefined
          }
          price={el.last_price_log?.price ?? "-"}
          lastUpdate={
            (el.last_price_log?.completed_date &&
              getPassedTime(
                new Date(el.last_price_log?.completed_date),
                endTime
              )) ||
            "-"
          }
          marketpalce={el.link.match(/https?:\/\/.*\.([^\/]*)\.[\w]*\//)?.[1]}
          even={props.even}
        />
      ))}
    </>
  ) : (
    <ProductListItemRow
      id={props.data.id}
      onChangeProduct={props.onChangeProduct}
      onDeleteProduct={props.onDeleteProduct}
      name={`${props.data.brand} ${props.data.name}`}
      even={props.even}
      internalProduct={props.data.internal_product}
    />
  );
  // <Row gutter={[16, 16]}>
  //   <Col span={8}>
  //     {props.data.brand} {props.data.name}
  //   </Col>
  //   <Col span={4}>Link</Col>
  //   <Col span={4}>2000</Col>
  //   <Col span={4}>30m</Col>
  //   <Col span={2}>
  //     <Edit onClick={() => props.onChangeProduct(props.data.id)} />
  //   </Col>
  // </Row>
};
export default ProductListItem;
