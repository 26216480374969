import React, { useEffect, useState } from "react";
import fetchApiWithAuth from "../api/fetch";
import { Context } from "./authContext";
import Routing from "./Routing";

const App = () => {
  const [authorized, setAuthorized] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const check = async () => {
    try {
      const result = await fetchApiWithAuth("/api/auth/whoami");
      const data = await result.json();
      if (data.statusCode !== 401) setAuthorized(true);
    } catch (e) {}
    setLoaded(true);
  };
  useEffect(() => {
    check();
  }, []);
  return (
    <Context.Provider value={{ authorized, setAuthorized }}>
      {loaded ? <Routing /> : <>Загрузка...</>}
    </Context.Provider>
  );
};

export default App;
