import fetchApiWithAuth from "./fetch";

export type ClassifierProps = {
  id: number;
  name: string;
  description: string;
  children: ClassifierProps[];
  // parent: ClassifierProps | null;
};

export const getAll = async () => {
  const result = await fetchApiWithAuth("/api/classifier");
  const data = (await result.json()) as ClassifierProps[];
  return data;
};

export const create = async (
  props: Omit<ClassifierProps, "id"> & { parentId: number | null }
) => {
  const result = await fetchApiWithAuth("/api/classifier", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ClassifierProps;
  return data;
};

export const edit = async (id: number, props: Partial<ClassifierProps>) => {
  const result = await fetchApiWithAuth(`/api/classifier/${id}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ClassifierProps;
  return data;
};

export const remove = async (id: number) => {
  const result = await fetchApiWithAuth(`/api/classifier/${id}`, {
    method: "DELETE",
  });

  return result;
};
