import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { GoodsProps } from "../api/Goods";
type GoodsState = {};
const defaultValues: GoodsProps = {
  article: "",
  name: "",
  category: null,
  brand: null,
  is_combo: false,
  comboComposition: [],
  wb_data: null,
  ozon_data: null,
  ya_data: null,
  sber_data: null,
  ali_data: null,
};

export const GoodsItem = ({
  isNew,
  data = defaultValues,
  ...props
}: {
  isNew?: boolean;
  article?: string;
  data?: GoodsProps;
  onCreate?: (props: GoodsProps) => void;
  onUpdate?: (props: GoodsProps) => void;
  onDelete?: (article: string) => void;
  brands: { name: string }[];
  productCategories: { id: number; name: string }[];
}) => {
  const [edit, setEdit] = useState(isNew);
  const [article, setArticle] = useState(data.article);
  const [name, setName] = useState(data.name);
  const [brand, setBrand] = useState(data.brand);
  const [category, setCategory] = useState(data.category);
  const [showForDistribution, setShowForDistribution] = useState(
    data.product_remnants?.show_for_distribution || false
  );
  const [isCombo, setIsCombo] = useState(data.is_combo);
  const [comboComposition, setComboComposition] = useState(
    data.comboComposition?.map((el) => ({
      ...el,
      value: el.value.toString(),
    })) || []
  );

  const [showAlias, setShowAlias] = useState(
    !!data.wb_data?.alias ||
      !!data.ozon_data?.alias ||
      !!data.ya_data?.alias ||
      !!data.sber_data?.alias ||
      !!data.ali_data?.alias
  );
  const [aliasWb, setAliasWb] = useState(data.wb_data?.alias || "");
  const [aliasOzon, setAliasOzon] = useState(data.ozon_data?.alias || "");
  const [aliasYa, setAliasYa] = useState(data.ya_data?.alias || "");
  const [aliasSber, setAliasSber] = useState(data.sber_data?.alias || "");
  const [aliasAli, setAliasAli] = useState(data.ali_data?.alias || "");
  const [barcode, setBarcode] = useState(data.wb_data?.barcode || "");

  const validForm = useMemo(() => {
    return (
      !!name &&
      !!article &&
      !!brand &&
      comboComposition.every((el) => !!el.article && !isNaN(parseInt(el.value)))
    );
  }, [name, article, brand, comboComposition]);

  const handleDelete = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const result = window.confirm("Действительно хотите удалить?");
    if (!result) return;
    props.onDelete!(article);
  };

  const handleSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validForm) return;
    if (!isNew) {
      props.onUpdate!({
        article,
        name,
        brand,
        category,
        product_remnants: { show_for_distribution: showForDistribution },
        is_combo: isCombo,
        comboComposition: isCombo
          ? comboComposition.map((el) => ({ ...el, value: parseInt(el.value) }))
          : undefined,
        wb_data: {
          alias: aliasWb ? aliasWb : null,
          barcode: barcode ? barcode : null,
        },
        ozon_data: { alias: aliasOzon ? aliasOzon : null },
        ya_data: { alias: aliasYa ? aliasYa : null },
        sber_data: { alias: aliasSber ? aliasSber : null },
        ali_data: { alias: aliasAli ? aliasAli : null },
      });
      setEdit(false);
    } else {
      props.onCreate!({
        article,
        name,
        brand,
        category,
        is_combo: isCombo,
        comboComposition: isCombo
          ? comboComposition.map((el) => ({ ...el, value: parseInt(el.value) }))
          : undefined,
        product_remnants: { show_for_distribution: showForDistribution },
        wb_data: {
          alias: aliasWb ? aliasWb : null,
          barcode: barcode ? barcode : null,
        },
        ozon_data: { alias: aliasOzon ? aliasOzon : null },
        ya_data: { alias: aliasYa ? aliasYa : null },
        sber_data: { alias: aliasSber ? aliasSber : null },
        ali_data: { alias: aliasAli ? aliasAli : null },
      });
    }
  };
  const reset = () => {
    setName(data.name);
    setArticle(data.article);
    setBrand(data.brand);
    setCategory(data.category);
  };

  useEffect(() => {
    reset();
  }, [data]);

  if (!edit) {
    return (
      <Row style={{ marginTop: 8, marginBottom: 8 }}>
        <Col md={6} xs={6}>
          {article} {name}
        </Col>
        <Col md={3} xs={3}>
          {category?.name || "без категории"}
        </Col>
        <Col md={3} xs={3}>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setEdit(true);
            }}
          >
            редактировать
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Form
      style={{
        border: "1px solid #ccc",
        borderRadius: 16,
        padding: 16,
        margin: "16px 0",
      }}
    >
      <Row>
        <Col md={6} xs={12}>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Артикул</Form.Label>
            <Form.Control
              placeholder="Артикул"
              value={article}
              onChange={(e) => setArticle(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Название</Form.Label>
            <Form.Control
              placeholder="Название"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Бренд</Form.Label>
            <Form.Select
              value={brand?.name}
              onChange={(e) =>
                setBrand(
                  props.brands.find((el) => el.name === e.target.value) || null
                )
              }
            >
              <option value={""}>Выбрать</option>
              {props.brands.map((el) => (
                <option value={el.name}>{el.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label>Категория</Form.Label>
            <Form.Select
              value={category?.id}
              onChange={(e) =>
                setCategory(
                  props.productCategories.find(
                    (el) => el.id.toString() === e.target.value
                  ) || null
                )
              }
            >
              <option value={""}>Выбрать</option>
              {props.productCategories.map((el) => (
                <option value={el.id.toString()}>{el.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Label style={{ display: "block" }}>WB barcode</Form.Label>
            <Form.Control
              style={{ width: 170, display: "inline-block" }}
              placeholder="WB barcode"
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Check
              type={"checkbox"}
              checked={showForDistribution}
              onChange={(e) => setShowForDistribution(!showForDistribution)}
              label={`Участвует в распределении остатков FBS`}
            ></Form.Check>
          </Form.Group>

          <Form.Group style={{ marginBottom: "8px" }}>
            <Form.Check
              type={"checkbox"}
              checked={isCombo}
              onChange={(e) => setIsCombo(!isCombo)}
              label={`Комбо товар`}
            ></Form.Check>
          </Form.Group>
          {isCombo && (
            <>
              {comboComposition.map((el, i) => {
                const setArticle = (value: string) =>
                  setComboComposition(
                    comboComposition.map((el, j) =>
                      j === i ? { ...el, article: value } : el
                    )
                  );
                const setValue = (value: string) =>
                  setComboComposition(
                    comboComposition.map((el, j) =>
                      j === i ? { ...el, value: value } : el
                    )
                  );
                const deleteItem = () => {
                  setComboComposition(
                    comboComposition.filter((el, j) => j !== i)
                  );
                };
                return (
                  <Row style={{ marginBottom: 8 }}>
                    <Col>
                      <Form.Control
                        placeholder="Артикул набора"
                        value={el.article}
                        onChange={(e) => setArticle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        placeholder="Количество единиц"
                        value={el.value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          deleteItem();
                        }}
                      >
                        удалить
                      </Button>
                    </Col>
                  </Row>
                );
              })}
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setComboComposition([
                    ...comboComposition,
                    { article: "", value: "" },
                  ]);
                }}
              >
                добавить артикул в набор
              </Button>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={6} xs={12}>
          <Form.Label onClick={() => setShowAlias(!showAlias)} style={{}}>
            Псевдонимы артикула {showAlias ? <>&#9650;</> : <>&#9660;</>}
          </Form.Label>
          {showAlias && (
            <Form.Group style={{ marginLeft: "16px" }}>
              <Form.Group style={{ marginBottom: "8px" }}>
                <Form.Label style={{ width: 70, display: "inline-block" }}>
                  WB
                </Form.Label>
                <Form.Control
                  style={{ width: 170, display: "inline-block" }}
                  placeholder="WB"
                  value={aliasWb}
                  onChange={(e) => setAliasWb(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "8px" }}>
                <Form.Label style={{ width: 70, display: "inline-block" }}>
                  Ozon
                </Form.Label>
                <Form.Control
                  style={{ width: 170, display: "inline-block" }}
                  placeholder="ozon"
                  value={aliasOzon}
                  onChange={(e) => setAliasOzon(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "8px" }}>
                <Form.Label style={{ width: 70, display: "inline-block" }}>
                  Ya
                </Form.Label>
                <Form.Control
                  style={{ width: 170, display: "inline-block" }}
                  placeholder="ya"
                  value={aliasYa}
                  onChange={(e) => setAliasYa(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "8px" }}>
                <Form.Label style={{ width: 70, display: "inline-block" }}>
                  Sber
                </Form.Label>
                <Form.Control
                  style={{ width: 170, display: "inline-block" }}
                  placeholder="Sber"
                  value={aliasSber}
                  onChange={(e) => setAliasSber(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "8px" }}>
                <Form.Label style={{ width: 70, display: "inline-block" }}>
                  Ali
                </Form.Label>
                <Form.Control
                  style={{ width: 170, display: "inline-block" }}
                  placeholder="Ali"
                  value={aliasAli}
                  onChange={(e) => setAliasAli(e.target.value)}
                />
              </Form.Group>
            </Form.Group>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 0" }}
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!validForm}
          >
            Сохранить
          </Button>
          {!isNew && (
            <Button
              style={{ margin: "16px 16px" }}
              variant="secondary"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                reset();
              }}
            >
              Cбросить изменения
            </Button>
          )}
          {!isNew && (
            <Button
              style={{ margin: "16px 16px 16px 0" }}
              variant="secondary"
              type="submit"
              onClick={handleDelete}
            >
              Удалить
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};
