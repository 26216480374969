import { useMemo, useReducer, useState } from "react";
import {
  getReviewsByFilter,
  updateReview as updateReviewRequest,
  ReviewsProps,
} from "api/Reviews";

function reducer(
  state: ReviewsProps[],
  action:
    | {
        type: "removeFromList";
        payload: Pick<ReviewsProps, "id" | "external_id">;
      }
    | { type: "update"; payload: Partial<ReviewsProps> }
    | { type: "set"; payload: ReviewsProps[] }
) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "update":
      return state.map((el) =>
        el.id === action.payload.id ? { ...el, ...action.payload } : el
      );

    case "removeFromList":
      return state.filter(
        (el) =>
          (el.id || el.external_id) !==
          (action.payload.id || action.payload.external_id)
      );

    default:
      throw new Error();
  }
}
export const useReviewsState = () => {
  const [pending, setPending] = useState(false);
  const [rawuseReviewsState, dispatch] = useReducer<any>(reducer, []);
  const reviews = rawuseReviewsState as ReviewsProps[];
  const [totalPage, setTotalPage] = useState(1);
  const [total, setTotal] = useState(0);

  const setReviews = (props: ReviewsProps[]) => {
    (dispatch as any)({ type: "set", payload: props });
  };

  const updateReview = async (id: number, data: Partial<ReviewsProps>) => {
    const result = await updateReviewRequest({ id, data });

    (dispatch as any)({
      type: "update",
      payload: result,
    });
  };

  const removeFromList = (props: Pick<ReviewsProps, "id" | "external_id">) => {
    (dispatch as any)({ type: "removeFromList", payload: props });
  };

  const fetchData = async (filters: {
    marketplace?: string[] | undefined;
    rating?: number[] | undefined;
    article?: string | undefined;
    status?: string[] | undefined;
    sorting: string;
    page: number;
  }) => {
    try {
      setPending(true);
      const data = await getReviewsByFilter(filters);
      setReviews(data.data);
      setTotalPage(data.total_page);
      setTotal(data.total);
    } catch (e) {}
    setPending(false);
  };

  return {
    reviews,
    updateReview,
    removeFromList,
    totalPage,
    setTotalPage,
    total,
    fetchData,
    pending,
  };
};

export type ReviewsState = ReturnType<typeof useReviewsState>;
