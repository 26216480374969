import styled from "styled-components";
import { Col } from "antd";
import { DragOutlined } from "@ant-design/icons";

export const Handle = styled(DragOutlined)`
  position: absolute;
  opacity: 0;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Wrap = styled.div`
  border-radius: 4px;
  background: #f9fbff;
  color: #000000;
  border: 1px solid #d2d3de;
  position: relative;
  user-select: text;
  &[data-slot="left"] {
    /* transform: translateX(24px); */
    &:before {
      content: "";
      width: 0;
      position: absolute;
      display: inline-block;
      left: -9px;
      top: -8px;
      height: 100%;
      height: calc(100% + 16px);
      border: 4px solid greenyellow;
    }
  }
  &[data-slot="right"] {
    /* transform: translateX(-24px); */
    &:before {
      content: "";
      width: 0;
      position: absolute;
      display: inline-block;
      top: -8px;
      right: -9px;
      height: calc(100% + 16px);
      border: 4px solid greenyellow;
    }
  }
  transform: translateX(0);
  transition: transform 0.3s;
  &:hover ${Handle} {
    opacity: 0.5;
  }
`;

export const GroupTitle = styled.div`
  padding: 10px 16px;
  font-size: 1.2em;
  line-height: 1.8;
  border-bottom: 1px solid #d2d3de;
  color: #3cada7;
  font-weight: bold;
  height: 80px;
  display: -webkit-box;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: text;
`;

export const MainCol = styled(Col)`
  font-size: 1.2em !important;
  line-height: 1.8;

  white-space: nowrap;
`;
export const HeadrCol = styled(MainCol)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const StyledRow = styled.div<{ internal: boolean }>`
  padding: 0 16px;
  margin: 4px 0;
  ${(props) =>
    props.internal &&
    `
    background: rgba(60, 173, 167, 0.1);
    font-weight: bold;

  `}
`;
export const ColRightAlign = styled(MainCol)`
  text-align: right;
`;

export const Table = styled.div``;

export const Link = styled.a`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
    color: black;
  }

  &:visited {
    color: black;
  }
`;
