import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  display: flex;
`;
export const SidePanel = styled.div`
  flex: 1 0 auto;
  width: 270px;
`;
export const MainPanel = styled.div``;

export const DisablePage = styled.div<{ disabled: boolean }>`
  position: relative;

  ${(props) =>
    props.disabled &&
    css`
      &:after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        position: absolute;
        z-index: 1;
      }
    `}
`;
