import styled from "styled-components";
import { Input } from "antd";

export const Td = styled.td`
  min-height: 40px;
  padding: 8px;
`;

export const Tr = styled.tr<{ last?: boolean }>`
  &:hover {
    background: #f9fbff;
  }
  ${Td} {
    padding: 8px 8px 4px;
  }
  ${(props) =>
    props.last && `border-bottom: 1px solid #ccc;  ${Td}{padding-bottom: 8px;}`}
`;

export const SubTr = styled.tr<{ last?: boolean }>`
  &:hover {
    background: #f9fbff;
  }
  ${Td} {
    padding: 4px 8px;
  }
  ${(props) =>
    props.last && `border-bottom: 1px solid #ccc; ${Td}{padding-bottom: 12px;}`}
`;

export const ValueTd = styled(Td)`
  width: 70px;
  text-align: right;
`;

export const Th = styled.td`
  padding: 8px;
`;

export const FlexWrap = styled.div`
  display: flex;
`;

export const Thead = styled.thead`
  background: #f9fbff;
  font-size: 14px;
`;

export const MainHeaderTr = styled.tr`
  ${Th} {
    padding-bottom: 4px;
  }
`;

export const SubHeaderTr = styled.tr`
  ${Th} {
    padding-top: 4px;
  }
  color: rgba(0, 0, 0, 0.5);

  text-align: right;

  ${Th}.align-left {
    text-align: left;
  }
`;

export const Title = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledInput = styled(Input)`
  padding: 3px 7px;
`;
