import React, { useMemo, useState } from "react";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import Chart from "./Chart";
import useSeriasFilterState from "./useSeriasFilterState";
import useChartData from "./useChartData";
import ChartFilter from "./ChartFilter";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import SerialTable from "./Table";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const Sales = () => {
  const [chartType, setChartType] = useState("orderCount");
  const seriasFilter = useSeriasFilterState();
  const { state, fetchXlsx } = useChartData(
    seriasFilter.state.serias,
    seriasFilter.startDate,
    seriasFilter.endDate
  );

  const serias = useMemo(() => {
    return state.map((el) => ({
      name: el.title,
      data: el.values,
    }));
  }, [state]);

  return (
    <Container>
      <Chart data={serias} type={chartType} />
      <div>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={(e, value) => setChartType(value)}
        >
          <ToggleButton value="orderCount">Продажи, шт.</ToggleButton>
          <ToggleButton value="revenue">Продажи, ₽</ToggleButton>
        </ToggleButtonGroup>
        <Button onClick={fetchXlsx} style={{ marginLeft: 24 }}>
          СКАЧАТЬ ОТЧЕТ
        </Button>
      </div>
      <SerialTable data={serias} type={chartType} />
      <div style={{ margin: "16px 0" }}>
        <span style={{ marginBottom: 16 }}>Укажите период времени</span>
        <DatePicker
          selected={seriasFilter.startDate}
          onChange={seriasFilter.onChangeDate}
          startDate={seriasFilter.startDate}
          endDate={seriasFilter.endDate}
          selectsRange
          locale={ru}
          dateFormat={"dd.MM.yyyy"}
        />
      </div>
      {seriasFilter.state.serias.map((el) => (
        <ChartFilter
          key={el.id}
          data={el}
          update={seriasFilter.update}
          remove={seriasFilter.remove}
          options={seriasFilter.options}
          disableRemove={seriasFilter.state.serias.length === 1}
        />
      ))}
      <Row>
        <Col>
          <Button
            onClick={(e) => {
              e.preventDefault();
              seriasFilter.create();
            }}
          >
            Добавить
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
export default Sales;
