import {
  Product,
  ProductEdit,
  ProductGroup,
  ProductGroupCreate,
  ProductLink,
  ProductLinkEditor,
  ProductLinkEditorEdit,
  ProductLinkOnSave,
  ProductSave,
} from "PriceMonitoring/modal";
import fetchApiWithAuth from "./fetch";
const prefix = "/api/price_monitoring";

export const getAll = async () => {
  const result = await fetchApiWithAuth(prefix);
  const data = (await result.json()) as Product[];
  return data;
};

export const getProduct = async (id: number) => {
  const result = await fetchApiWithAuth(prefix + "/" + id);
  const data = (await result.json()) as Product;
  return data;
};

export const createProduct = async (props: Partial<ProductSave>) => {
  const result = await fetchApiWithAuth(prefix, {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as Product;
  return data;
};

export const editProduct = async (id: number, props: Partial<ProductSave>) => {
  const result = await fetchApiWithAuth(`${prefix}/${id}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as Product;
  return data;
};

export const deleteProduct = async (id: number) => {
  const result = await fetchApiWithAuth(`${prefix}/${id}`, {
    method: "DELETE",
  });
  const data = (await result.json()) as { id: number };
  return data;
};

export const getProductLinks = async (id: number) => {
  const result = await fetchApiWithAuth(prefix + "/" + id + "/links");
  const data = (await result.json()) as ProductLinkEditor[];
  return data;
};

export const createProductLink = async (
  id: number,
  props: Partial<ProductLinkEditorEdit>
) => {
  const result = await fetchApiWithAuth(prefix + "/" + id + "/links", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ProductLinkEditor;
  return data;
};

export const editProductLink = async (
  id: number,
  linkId: number,
  props: Partial<ProductLinkEditorEdit>
) => {
  const result = await fetchApiWithAuth(`${prefix}/${id}/links/${linkId}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as ProductLinkEditor;
  return data;
};

export const removeProductLink = async (id: number, linkId: number) => {
  const result = await fetchApiWithAuth(`${prefix}/${id}/links/${linkId}`, {
    method: "DELETE",
  });

  return result;
};

export const updateLog = async (id: number) => {
  const result = await fetchApiWithAuth(prefix + "/" + id + "/update_log", {
    method: "POST",
    body: JSON.stringify({}),
  });
  const data = (await result.json()) as ProductLinkEditor;
  return data;
};

export const getGroups = async () => {
  const result = await fetchApiWithAuth(prefix + "/groups", {
    method: "GET",
  });
  const data = (await result.json()) as ProductGroup[];
  return data;
};

export const getGroup = async (id: number) => {
  const result = await fetchApiWithAuth(prefix + "/groups/" + id, {
    method: "GET",
  });
  const data = (await result.json()) as ProductGroup;
  return data;
};

export const createGroup = async (newGroupData?: ProductGroupCreate) => {
  const result = await fetchApiWithAuth(prefix + "/groups", {
    method: "POST",
    body: JSON.stringify(newGroupData || {}),
  });
  const data = (await result.json()) as ProductGroup;
  return data;
};

export const editGroup = async (id: number, newGroupData: ProductGroup) => {
  const result = await fetchApiWithAuth(prefix + "/groups/" + id, {
    method: "PATCH",
    body: JSON.stringify(newGroupData),
  });
  const data = (await result.json()) as ProductGroup;
  return data;
};

export const deleteGroup = async (id: number) => {
  const result = await fetchApiWithAuth(prefix + "/groups/" + id, {
    method: "DELETE",
  });
  const data = (await result.json()) as { id: number };
  return data;
};
