import fetchApiWithAuth from "../fetch";

export type Remains = {
  lastUpdate: string;
  remains: { [productId: string]: number };
};

export const getRemains = async (warehouseId: number) => {
  const result = await fetchApiWithAuth("/deliveries/api/remains", {
    body: JSON.stringify({ warehouseId }),
    method: "POST",
  });
  const data = (await result.json()) as Remains;
  return data;
};
