import React, { useEffect, useState, useMemo, FormEvent } from "react";
import { Col, Row, Form, Button, Tabs, Tab } from "react-bootstrap";
import { AnswerTemplateProps } from "../api/AnswerTemplates";
import { ItemAnswerTemplateProps } from "./useAnswerTemplates";
import * as actions from "../api/Logger";
import { LogProps } from "../api/Logger";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "styled-components";
import MenuItem from "@mui/material/MenuItem";

const fecthLogs = async (
  setLogs: (values: LogProps[]) => void,
  lastId: number | undefined,
  setLastId: (v: number) => void
) => {
  const result = await actions.getLogs({ after_id: lastId });
  setLogs(result.logs);
  if (result.logs.length) {
    setLastId(result.logs[0].id);
  }
};

const useOptions = (logs: LogProps[]) => {
  return useMemo(() => {
    const data = logs.reduce(
      (acc, el) => {
        if (!acc[0].includes(el.source)) {
          acc[0].push(el.source);
        }
        if (!acc[1].includes(el.type)) {
          acc[1].push(el.type);
        }
        return acc;
      },
      [[], []] as string[][]
    );
    data[0].sort();
    data[1].sort();
    return data;
  }, [logs]);
};
const FieldWrap = styled.div`
  margin-bottom: 16px;
`;
const FieldLabel = styled.div`
  margin-bottom: 4px;
`;
const Wrap = styled.pre`
  max-width: 800px;
  background-color: #f0f0f0;
  padding: 3px 15px;
  margin: 3px;
`;
const Logs = () => {
  const [logs, setLogs] = useState<LogProps[]>([]);
  const [sourceOptions, typeOptions] = useOptions(logs);
  const [sources, setSource] = useState<string[]>([]);
  const [types, setTypes] = useState<string[]>([]);
  const [lastId, setLastId] = useState<number>();
  const items = useMemo(() => {
    return logs.filter((el) => {
      if (sources.length && !sources.includes(el.source)) {
        return false;
      }
      if (types.length && !types.includes(el.type)) {
        return false;
      }
      return true;
    });
  }, [types, sources, logs]);

  const updateList = () => {
    fecthLogs(
      (val: LogProps[]) => setLogs([...val, ...logs]),
      lastId,
      setLastId
    );
  };
  useEffect(() => {
    updateList();
  }, []);
  useEffect(() => {
    const timer = setTimeout(updateList, 5000);
    return () => clearTimeout(timer);
  }, [logs]);
  return (
    <>
      <Row>
        <Col xs={6} md={3}>
          <FieldWrap>
            <FieldLabel>Источник</FieldLabel>
            <Select
              multiple
              style={{ width: "100%" }}
              value={sources}
              onChange={(e: any) => setSource(e.target.value)}
              placeholder="Источник"
            >
              {sourceOptions.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FieldWrap>
        </Col>
        <Col xs={6} md={3}>
          <FieldWrap>
            <FieldLabel>Тип</FieldLabel>
            <Select
              multiple
              style={{ width: "100%" }}
              value={types}
              onChange={(e: any) => setTypes(e.target.value)}
              placeholder="Тип"
            >
              {typeOptions.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FieldWrap>
        </Col>
      </Row>
      <Wrap>
        {items.map(
          (el) =>
            `${new Date(el.created_at)
              .toJSONWithoutTimezone()
              .slice(0, 10)} ${new Date(el.created_at)
              .toJSONWithoutTimezone()
              .slice(11, 19)} ${el.source} ${el.type} ${el.message} \n`
        )}
      </Wrap>
    </>
  );
};

export default Logs;
