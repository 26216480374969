import React, { useMemo, useState } from "react";

import { Pagination } from "react-bootstrap";
export const getPageNumers = (total: number, current: number) => {
  if (total < 6)
    return Array(total)
      .fill(1)
      .map((el, i) => i + 1);
  if (current < 4) {
    return [1, 2, 3, 4, skipPages, total];
  }
  if (current > total - 3) {
    return [
      1,
      skipPages,
      ...new Array(4).fill(1).map((el, i) => total - 3 + i),
    ];
  }

  return [
    1,
    skipPages,
    ...new Array(3).fill(1).map((el, i) => current - 1 + i),
    skipPages,
    total,
  ];
};

const skipPages = "skip";

export const usePaginator = <T extends any[]>(
  items: T,
  paginationOffset: number = 50
) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const paginatorItems = useMemo(() => {
    return getPageNumers(
      Math.ceil(items.length / paginationOffset) || 1,
      currentPageNumber
    );
  }, [items, currentPageNumber, paginationOffset]);

  const pageItems = useMemo(
    () =>
      items.slice(
        (currentPageNumber - 1) * paginationOffset,
        currentPageNumber * paginationOffset
      ) as T,
    [currentPageNumber, items, paginationOffset]
  );

  return { paginatorItems, pageItems, currentPageNumber, setCurrentPageNumber };
};

export const Paginator = (props: {
  currentPageNumber: number;
  setCurrentPageNumber: (value: number) => void;
  items: (string | number)[];
  style?: React.CSSProperties;
}) => {
  return (
    <Pagination style={props.style}>
      {props.items.map((el) =>
        el === skipPages || typeof el === "string" ? (
          <Pagination.Ellipsis></Pagination.Ellipsis>
        ) : (
          <Pagination.Item
            key={el}
            active={el === props.currentPageNumber}
            onClick={() => props.setCurrentPageNumber(el)}
          >
            {el}
          </Pagination.Item>
        )
      )}
    </Pagination>
  );
};
