import { getWarehouses } from "api/deliveries/Warehouses";
import { useEffect, useState } from "react";

export const useWarehouses = () => {
  const [pendingWarehouses, setPendingWarehouses] = useState(false);
  const [warehouses, setWarehouses] = useState<
    { value: number; label: string }[]
  >([]);

  useEffect(() => {
    (async () => {
      setPendingWarehouses(true);
      const result = await getWarehouses();
      setWarehouses(
        result
          .map((el) => ({ value: el.id, label: el.name }))
          .sort((a, b) => (a.label < b.label ? -1 : 1))
      );
      setPendingWarehouses(false);
    })();
  }, []);

  return {
    warehouses,
    pendingWarehouses,
  };
};
