import React from "react";
import styled, { css } from "styled-components";
import colors from "../../shared/colors";

const TabItem = styled.div<{ isActive: boolean }>`
  position: relative;
  border: 2px solid ${colors.main};
  padding: 8px 16px;
  border-radius: 22px;
  cursor: pointer;

  > span {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${colors.black};
  }
  ${(props) =>
    props.isActive &&
    css`
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: -7px;
        background: ${colors.main};
        transform: translate(-50%) rotate(45deg);
        left: 50%;
      }
      background: ${colors.main};
      > span {
        color: ${colors.white};
      }
    `}
`;
export const Tabs = styled.div`
  display: flex;

  ${TabItem} {
    margin-right: 16px;
  }
`;
export const Tab = (
  props: React.PropsWithChildren<{
    key: string;
    isActive: boolean;
    onSelect: (value: string) => void;
  }>
) => {
  return (
    <TabItem
      isActive={props.isActive}
      onClick={() => props.onSelect(props.key)}
    >
      <span>{props.children}</span>
    </TabItem>
  );
};
