import { Button, Input, Pagination, Select, Tooltip } from "antd";
import React from "react";
import { Space } from "shared/ui";
import styled from "styled-components";
import { Row, Col } from "antd";
import { useWarehouses } from "./hooks/useWarehouses";

const Line = styled.div`
  display: flex;
  align-items: center;
`;

export const RemainsHeader = (props: {
  totalPage: number;
  currentPage: number;
  setCurrentPage: (val: number) => void;
  warehouseFilter: {
    value: number;
    label: string;
  } | null;
  setWarehouseFilter: (val: { value: number; label: string }) => void;
}) => {
  const { warehouses } = useWarehouses();
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Line>
            Период продаж <Space size={20} />
            <Input placeholder="Дни" style={{ width: "70px" }} maxLength={3} />
            <Space size={20} />
            Склад
            <Space size={20} />
            <Select
              placeholder="Склад"
              style={{ width: "250px" }}
              options={warehouses}
              onChange={(_, value: any) => props.setWarehouseFilter(value)}
              value={props.warehouseFilter}
            />
            <Space />
            <Space size={20} />
            <Tooltip title="">
              <Button>Показать нормы поставки</Button>
            </Tooltip>
            <Space size={20} />
            <Tooltip title="">
              <Button>Загрузить нормы поставки и кванты</Button>
            </Tooltip>
          </Line>
        </Col>
        <Col span={24}>
          <Line>
            {" "}
            <Pagination
              current={props.currentPage}
              onChange={(value) => props.setCurrentPage(value)}
              total={props.totalPage}
              pageSize={1}
              showSizeChanger={false}
              style={{ display: "inline-block" }}
            ></Pagination>
            <Space size={20} />
            <Input placeholder="Поиск" style={{ width: "200px" }} />
          </Line>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};
