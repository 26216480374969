import React from "react";
import Portal from "../Portal";
import styled, { css } from "styled-components/macro";
import { ReactComponent as CloseCross } from "./closeCross.svg";

const body = document.querySelector("body");
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
  overflow: auto;
`;

const ModalWrap = styled.div`
  padding: 25px 20px 25px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  min-height: 100%;
  background: rgba(9, 21, 38, 0.6);
`;
const ModalWindow = styled.div<any>`
  background: #fff;
  border-radius: 10px;
  position: relative;
  ${({ withoutPadding }) =>
    !withoutPadding &&
    css`
      padding: 25px 20px 25px 30px;
    `};
`;
export default class Modal extends React.Component<any> {
  innerRef = { current: null } as { current: HTMLElement | null };
  mousedownTarget = null;
  mouseupTarget = null;
  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };
  handleEscape = (e: KeyboardEvent) => {
    if (e.keyCode === 27) this.handleClose();
  };
  handleClickOverlay = (e: any) => {
    if (e.type === "mousedown") {
      this.mousedownTarget = e.target;
    } else if (e.type === "mouseup") {
      this.mouseupTarget = e.target;
    } else if (
      e.currentTarget === this.mousedownTarget &&
      e.currentTarget === this.mouseupTarget
    ) {
      this.handleClose();
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleEscape);
    body?.classList.add("noScrollBody");
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEscape);
    body?.classList.remove("noScrollBody");
  }
  setRef = (ref: HTMLElement) => {
    this.innerRef.current = ref;
    if (this.props.modalRef) {
      this.props.modalRef.current = ref;
    }
  };
  render() {
    const { withoutPadding } = this.props;
    const MW = this.props.customModalWindow || ModalWindow;
    return (
      <Portal>
        <Overlay>
          <ModalWrap
            onMouseDown={this.handleClickOverlay}
            onMouseUp={this.handleClickOverlay}
            onClick={this.handleClickOverlay}
          >
            <MW withoutPadding={withoutPadding} ref={this.setRef}>
              {this.props.onClose && (
                <CloseCross
                  style={{ position: "absolute", right: -30, top: 7 }}
                  onClick={this.handleClose}
                />
              )}
              {this.props.children}
            </MW>
          </ModalWrap>
        </Overlay>
      </Portal>
    );
  }
}
