import React, { useMemo, useRef } from "react";
import { RemnatsProps, manualSend } from "../api/ProductRemnants";
import { StyledTable, SmallTd90, SmallTd, TableWrap } from "./RemnantsStyled";
import {
  Field,
  getDinamicRemnant,
  getEval,
  FieldWithButton,
  RemnantRowProps,
  DisabledSend,
} from "./common";
import { Button } from "react-bootstrap";
import usePinRemnans from "./usePinRemnans";
import useSelectCell from "./useSelectCell";
import FullRemnantsHeader from "./FullRemnantsHeader";
import { useColumnControl } from "./useColumnControl";
import { useSortableData } from "./useSortableData";
import FullRemnantsItem from "./FullRemnantsItem";
import { usePaginator } from "../shared/Paginator";
import { usePageSize } from "./usePagination";
import { useFilter } from "./useFilter";

const FullRemnants = (props: {
  remnants: RemnantRowProps[];
  update: (
    props: {
      article: string;
      value: Partial<RemnatsProps["product_remnants"]>;
    }[]
  ) => Promise<boolean>;
  updateDisabledSend: (
    props: {
      article: string;
      value: Partial<DisabledSend>;
    }[]
  ) => Promise<boolean>;
}) => {
  const columnControl = useColumnControl();
  const filters = useFilter();

  const remnants = useMemo(
    () =>
      props.remnants.filter(
        (el) =>
          ((filters.showCombo && el.is_combo) ||
            (filters.showSingle && !el.is_combo)) &&
          (!filters.search ||
            el.article.toLowerCase().includes(filters.search.toLowerCase()))
      ),
    [filters, props.remnants]
  );
  console.log(remnants);
  const { items, toggleSort, sortConfig } = useSortableData(remnants);
  const { pageSize, setPageSize } = usePageSize();
  const paginator = usePaginator(items, pageSize);
  const { onClick, cells } = useSelectCell(paginator.pageItems);
  const update = async (propsLocal: {
    article: string;
    value: Partial<RemnatsProps["product_remnants"]>;
  }) => {
    const keys = Object.keys(propsLocal.value);
    if (!keys.length) return false;
    const value = (propsLocal.value as any)[keys[0]] as any;
    const result = cells.reduce((acc, el) => {
      return {
        ...acc,
        [el.row]: {
          article: el.row,
          value: {
            ...(acc[el.row]?.value || {}),
            ...((el.column === "wb" && { share_in_wildberries: value }) ||
              (el.column === "ozon" && { share_in_ozon: value }) ||
              (el.column === "ya" && { share_in_ya: value }) ||
              (el.column === "sber" && { share_in_sber: value }) ||
              (el.column === "ali" && { share_in_ali: value }) ||
              (el.column === "combo" && { share_in_combo: value }) ||
              {}),
          },
        },
      };
    }, {} as any);

    return props.update(
      cells.length ? [...(Object.values(result as any) as any)] : [propsLocal]
    );
  };
  return (
    <TableWrap>
      <StyledTable striped bordered hover onClick={onClick}>
        <FullRemnantsHeader
          columnControl={columnControl}
          toggleSort={toggleSort}
          sortConfig={sortConfig}
          paginator={paginator}
          pageSize={pageSize}
          setPageSize={setPageSize}
          filters={filters}
        />
        <tbody>
          {paginator.pageItems.map((el) => (
            <FullRemnantsItem
              key={el.article}
              data={el}
              remnants={props.remnants}
              columnControl={columnControl}
              update={update}
              hoverCells={cells}
              updateDisabledSend={props.updateDisabledSend}
            />
          ))}
        </tbody>
      </StyledTable>
    </TableWrap>
  );
};

export default FullRemnants;
