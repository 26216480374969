import React from "react";
import styled from "styled-components/macro";
import Modal from "../Modal";
import { Button, ButtonColor } from "../ui";
import { asyncPopup } from "../AsyncPopup";

const Content = styled.div`
  padding: 40px;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 32px;
  }
`;

interface ConfirmOptions {
  submit?: { text?: string; color?: ButtonColor };
}

const ConfirmPopup = (
  props: React.PropsWithChildren<{
    options?: ConfirmOptions;
    onClose?: (value: boolean) => void;
  }>
) => {
  const onSubmit = () => props.onClose!(true);
  const onClose = () => props.onClose!(false);
  return (
    <Modal withoutPadding onClose={props.onClose}>
      <Content>
        {props.children}
        <ButtonWrap>
          <Button color={"azure"} onClick={onClose}>
            Отменить
          </Button>
          <Button
            onClick={onSubmit}
            color={props.options?.submit?.color || "red"}
          >
            {props.options?.submit?.text || "Удалить"}
          </Button>
        </ButtonWrap>
      </Content>
    </Modal>
  );
};

export const confirmPopup = (
  children: React.ReactElement,
  options?: ConfirmOptions
) => {
  return asyncPopup(<ConfirmPopup options={options}>{children}</ConfirmPopup>);
};

export default confirmPopup;
