export const getTimeBetweenDates = (startDate: Date, endDate: Date) => {
  const seconds = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return {
    seconds: seconds - minutes * 60,
    minutes: minutes - hours * 60,
    hours: hours - days * 24,
    days,
  };
};

export const getPassedTime = (startDate: Date, endDate: Date) => {
  const value = getTimeBetweenDates(startDate, endDate);

  return `${(value.days && `${value.days}d `) || ""}${
    (value.hours && `${value.hours}h `) || ""
  }${(value.minutes && `${value.minutes}m `) || ""}`;
};
