import { Button, Col, Form, Input, Modal, Select, Row, Switch } from "antd";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Space } from "shared/ui";
import { ErrorField, TableWrap } from "../../shared/GoodDataUploader/styled";
import { HeaderRow } from "../../shared/GoodDataUploader/Header";

import { useRowsData } from "../../shared/GoodDataUploader/useRowsData";
import useFormStateWithErrors from "shared/utils/useFormStateWithErrors";
import { ScheduleTask } from "api/PriceLoader";
import styled from "styled-components";
const Wrap = styled.div`
  .ant-select-selector {
    overflow: scroll;
    max-height: 170px;
  }
  .ant-form-item-extra {
    color: #ff4d4f;
  }
`;
type State = {
  name: string;
  started: boolean;
  goods: { value: string; label: string }[];
  startMinute: string;
  startHour: string;
  discountAmount: string;
};
const defaultValue = {
  name: "",
  started: false,
  goods: [],
  startMinute: "",
  startHour: "",
  discountAmount: "",
};
const defaultError = {
  name: "",
  started: "",
  goods: "",
  startMinute: "",
  startHour: "",
  discountAmount: "",
};

const convertToForm = (props: ScheduleTask): State => {
  return {
    name: props.name,
    started: props.started,
    goods: props.goods.map((el) => ({ value: el.article, label: el.article })),
    startMinute: props.startMinute + "",
    startHour: props.startHour,
    discountAmount: props.discountAmount + "",
  };
};

const convertToBack = (props: State): Omit<ScheduleTask, "id"> => {
  return {
    name: props.name,
    started: props.started,
    goods: props.goods.map((el) => ({ article: el.value })),
    startMinute: +props.startMinute,
    startHour: props.startHour,
    discountAmount: parseFloat(props.discountAmount),
  };
};

const useScheduleState = () =>
  useFormStateWithErrors<State>(defaultValue, defaultError);

const validateForm = (props: ReturnType<typeof useScheduleState>): boolean => {
  let valid = true;
  const values = props.values;
  if (!values.name) {
    valid = false;
    props.setError("name")("Обязательное поле");
  }
  if (!values.discountAmount) {
    valid = false;
    props.setError("discountAmount")("Обязательное поле");
  } else if (
    values.discountAmount.match(/[^\d\.]+/) ||
    parseFloat(values.discountAmount) + "" !== values.discountAmount
  ) {
    valid = false;
    props.setError("discountAmount")("Невалидное значение");
  }
  if (!values.startHour) {
    valid = false;
    props.setError("startHour")("Обязательное поле");
  } else {
    const startHour = values.startHour
      .replace(/\s/g, "")
      .split(",")
      .map((el) => {
        if (el.match(/^\d+-\d+$/)) {
          const s = el.split("-").map((el) => +el);
          if (s[0] < s[1]) {
            return new Array(s[1] - s[0] + 1).fill(0).map((el, i) => s[0] + i);
          }
          return null;
        }
        if (el.match(/\d+/)) {
          return +el;
        }
        return null;
      })
      .flat();

    if (startHour.some((el) => el === null || el < 0 || el > 23)) {
      valid = false;
      props.setError("startHour")("Невалидное значение");
    }
  }
  if (!values.startMinute) {
    valid = false;
    props.setError("startMinute")("Обязательное поле");
  } else if (
    parseInt(values.startMinute) + "" !== values.startMinute ||
    !(parseInt(values.startMinute) >= 0 && parseInt(values.startMinute) < 60)
  ) {
    valid = false;
    props.setError("startMinute")("Невалидное значение");
  }

  return valid;
};
const ScheduleItemModal = (props: {
  goodsOptions: { value: string; label: string }[];

  data?: ScheduleTask;
  onSubmit: (props: Omit<ScheduleTask, "id">) => void;
  onClose: () => void;
}) => {
  const form = useScheduleState();

  useEffect(() => {
    if (props.data) {
      form.setValues(convertToForm(props.data));
    }
  }, [props.data]);

  const onSubmit = () => {
    const result = validateForm(form);
    if (!result) return;
    props.onSubmit(convertToBack(form.values));
  };

  return (
    <Modal footer={[]} style={{ minWidth: 800 }} open onCancel={props.onClose}>
      <Wrap>
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Название задачи" extra={form.errors.name}>
                <Input
                  placeholder="например: скидка на подгузники"
                  value={form.values.name}
                  onChange={(e) => form.setValue("name")(e.target.value)}
                  status={(form.errors.name && "error") || undefined}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Включено">
                <Switch
                  checked={form.values.started}
                  onChange={(e) => form.setValue("started")(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item label="Часы выполнения" extra={form.errors.startHour}>
                <Input
                  placeholder="например: 2-11,15,5"
                  value={form.values.startHour}
                  onChange={(e) => form.setValue("startHour")(e.target.value)}
                  status={(form.errors.startHour && "error") || undefined}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Минуты выполнения"
                extra={form.errors.startMinute}
              >
                <Input
                  placeholder="возможные значения: 0-59, например: 10"
                  value={form.values.startMinute}
                  onChange={(e) => form.setValue("startMinute")(e.target.value)}
                  status={(form.errors.startMinute && "error") || undefined}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Товары" extra={form.errors.goods}>
                <Select
                  style={{ maxHeight: 200 }}
                  listHeight={200}
                  placeholder="Выбрать из списка"
                  mode="multiple"
                  options={props.goodsOptions}
                  value={form.values.goods}
                  onChange={(_, e) => {
                    form.setValue("goods")(e as any);
                  }}
                  status={(form.errors.goods && "error") || undefined}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label="Величина изменения"
                extra={form.errors.discountAmount}
              >
                <Input
                  placeholder="например: 1.20 или 0.8"
                  value={form.values.discountAmount}
                  onChange={(e) =>
                    form.setValue("discountAmount")(e.target.value)
                  }
                  status={(form.errors.discountAmount && "error") || undefined}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Button onClick={onSubmit} type="primary">
          Применить
        </Button>
        <Space size={24} inline />
        <Button onClick={() => props.onClose()}>Отменить</Button>
      </Wrap>
    </Modal>
  );
};

export default ScheduleItemModal;
