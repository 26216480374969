import React, { useMemo, useState } from "react";
import PriceItem from "./PriceItem";
import { GoodsWithPriceForUpdate, GoodsWithPriceProps } from "api/PriceLoader";
import { Col, Header, InnerCol, Row, SmallButton, Wrap } from "./styled";
import { Input } from "antd";

const PriceList = (props: {
  data: GoodsWithPriceProps[];
  update: (props: GoodsWithPriceForUpdate) => void;
  fetchRows: () => void;
}) => {
  const [articleFilter, setArticleFilter] = useState("");
  const data = useMemo(
    () =>
      articleFilter
        ? props.data.filter((el) => el.article.includes(articleFilter))
        : props.data,
    [articleFilter, props.data]
  );
  return (
    <Wrap>
      <Header>
        <Row>
          <Col>Артикул</Col>
          <Col>WB</Col>
          <Col>OZON</Col>
          <Col>YA</Col>
          <Col>SBER</Col>
          <Col>ALI</Col>
          <Col></Col>
        </Row>
        <Row style={{ fontSize: "14px" }}>
          <Col>
            <Input
              value={articleFilter}
              onChange={(e) => setArticleFilter(e.target.value)}
              onBlur={(e) => setArticleFilter(e.target.value)}
              placeholder="Фильтр"
              style={{ border: 0, padding: "0 4px" }}
            ></Input>
          </Col>
          <Col>
            <InnerCol>Базовая</InnerCol>
            <InnerCol>Со Скидкой</InnerCol>
          </Col>
          <Col>
            <InnerCol>Базовая</InnerCol>
            <InnerCol>Со Скидкой</InnerCol>
          </Col>
          <Col>
            <InnerCol>Базовая</InnerCol>
            <InnerCol>Со Скидкой</InnerCol>
          </Col>
          <Col>
            <InnerCol>Базовая</InnerCol>
            <InnerCol>Со Скидкой</InnerCol>
          </Col>
          <Col>
            <InnerCol>Базовая</InnerCol>
            <InnerCol>Со Скидкой</InnerCol>
          </Col>
          <Col>
            <SmallButton type="primary" onClick={props.fetchRows}>
              Обновить таблицу
            </SmallButton>
          </Col>
        </Row>
      </Header>
      {data.map((el) => (
        <PriceItem key={el.article} data={el} update={props.update} />
      ))}
    </Wrap>
  );
};

export default PriceList;
