import React from "react";
import { Container, Row } from "react-bootstrap";
import PriceMonitoringView from "../PriceMonitoring/view";
import Layout from "../shared/Layout";

const PriceMonitoringPage = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <PriceMonitoringView />
        </Row>
      </Container>
    </Layout>
  );
};

export default PriceMonitoringPage;
