import React from "react";
import { Remains } from "../Remains";
import Layout from "../shared/Layout";

const FBSPage = () => {
  return (
    <Layout>
      <Remains />
    </Layout>
  );
};

export default FBSPage;
