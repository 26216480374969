import styled from "styled-components";
import { Input, Select } from "antd";

export const TableWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: calc(100vh - 300px);
`;
export const Cell = styled.div`
  border: 1px solid #ccc;
  width: 100%;
  max-width: 200px;
  flex: 0 0 auto;
`;
export const InputCell = styled(Input)<{ error?: boolean }>`
  border: 1px solid #ccc;
  width: 100%;
  max-width: 200px;
  padding: 0;
  border-radius: 0;
  flex: 0 0 auto;
  padding: 0 8px;
  &[data-error="error"] {
    background-color: #ff0000;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  ${Cell}:first-child, ${InputCell}:first-child {
    border-right: 2px solid rgba(0, 0, 0, 0.5);

    z-index: 1;
    position: sticky;
    left: 0;
    background-color: #fff;
    padding: 0 8px;
  }
  &.header > * {
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    background-color: #fff;
  }
  &.header {
    position: sticky;
    top: 0;
    z-index: 2;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  max-width: 200px;
  .ant-select-selector {
    border: 0 !important;
    width: 100%;
    max-width: 200px;
    padding: 0;
    border-radius: 0;
    padding: 0;
  }
`;

export const ErrorField = styled.div`
  margin-bottom: 8px;
  color: #ff0000;
`;
