import React, { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { createGroup, getAll, getGroup, updateLog } from "api/PriceMonitoring";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Check, Edit, FieldTitle, FieldWrap, Close, ColStyled } from "./styled";
import { Space } from "shared/ui";
import Title from "antd/es/typography/Title";
import { useGroupEditorState } from "./useGroupEditorState";
import TextArea from "antd/es/input/TextArea";
import { usePriceMonitoringContext } from "../Contex";
import { Product } from "PriceMonitoring/modal";
import ProductListItem from "./ProductListItem";
import { sortProducts } from "PriceMonitoring/helpers";

const ProductList = (props: {
  data?: Product[];
  onChangeProduct: (val: number) => void;
  onDeleteProduct: (val: number) => void;
}) => {
  const items = useMemo(() => {
    if (!props.data) return [];
    const items = sortProducts(props.data);
    return items;
  }, [props.data]);
  return (
    <>
      <Row gutter={[16, 16]}>
        <ColStyled span={8}>Название</ColStyled>
        <ColStyled span={2}></ColStyled>
        <ColStyled span={4}>Цена</ColStyled>
        <ColStyled span={4}>Последнее обновление</ColStyled>
        <ColStyled span={2}></ColStyled>
      </Row>
      {items.map((el, i) => (
        <ProductListItem
          data={el}
          onChangeProduct={props.onChangeProduct}
          onDeleteProduct={props.onDeleteProduct}
          even={i % 2 === 0}
        />
      ))}
    </>
  );
};
export default ProductList;
