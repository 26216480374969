import React, { useState } from "react";
import { Filter } from "./components/Filters";
import { Reviews } from "./components/Reviews";
import { SidePanel, MainPanel, Container } from "./components/styled";
import { Space } from "shared/ui";
import { useFilterState } from "./components/Filters/useFilterState";

const Answers = () => {
  const filters = useFilterState();

  return (
    <Container>
      <SidePanel>
        <Filter filters={filters}></Filter>
      </SidePanel>
      <Space size={32} />
      <MainPanel>
        <Reviews filters={filters} />
      </MainPanel>
    </Container>
  );
};
export default Answers;
