import { Input } from "antd";
import { ClassifierProps } from "api/Classifier";
import React, {
  Children,
  ReactComponentElement,
  useEffect,
  useState,
} from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";

export type Props = {
  space: number;
  title: string;
  id: number;
  onEdit: (props: { id: number; title: string }) => void;
  onCreate: (props: { parentId: number }) => void;
};
const TreeNode: React.FunctionComponent<Props> = (props: Props) => {
  const [title, setTitle] = useState(props.title);
  const [edit, setEdit] = useState(false);
  const handleBlur = () => {
    setEdit(false);
    if (title !== props.title) {
      props.onEdit({ ...props, title: title });
    }
  };
  return (
    <div>
      <div>
        {new Array(props.space).fill(1).map((el) => "0")}{" "}
        {edit ? (
          <Input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={handleBlur}
            autoFocus
          />
        ) : (
          <span onDoubleClick={() => setEdit(true)}>{props.title}</span>
        )}
        <span onClick={() => props.onCreate({ parentId: props.id })}>+</span>
      </div>
    </div>
  );
};

export default TreeNode;
