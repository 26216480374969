import React from "react";
import { FlexWrap, StyledInput, Td, Title, Tr, ValueTd } from "./ListStyled";
import { Tooltip } from "@mui/material";
import { Input } from "antd";
import { Space } from "shared/ui";
import { ItemIntegration } from "./ItemIntegration";

export const Item = () => {
  return (
    <>
      {" "}
      <Tr>
        <Td>K</Td>
        <Td>NK45272e44</Td>
        <Td>
          <Title>Mykiddo / Гель для душа Reva Beauty Care, Perfumed, 5л</Title>
        </Td>
        <ValueTd>123</ValueTd>
        <ValueTd>
          <span>{234}</span>
        </ValueTd>
        <Td>Комбо</Td>
        <ValueTd>0</ValueTd>
        <Td>
          <StyledInput style={{ width: 70 }} />
        </Td>
        <ValueTd>10</ValueTd>
        <ValueTd></ValueTd>
      </Tr>
      {["wb", "ozon", "ya_market_223455"].map((el, i) => (
        <ItemIntegration last={i === 2} data={{ name: el, value: "" }} />
      ))}
    </>
  );
};
