import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Router, Navigate } from "react-router";
import LoginPage from "../pages/login";

import useAuthContext from "./useAuthContext";
import { BrowserRouter, Link } from "react-router-dom";
import AnswersPage from "../pages/answers";
import SettingsPage from "../pages/settings";
import SalesPage from "../pages/sales";
import RemnantsPage from "../pages/remnants";

import PriceLoaderPage from "pages/price_loader";
import PriceMonitoringPage from "pages/price_monitoring";
import PriceMonitoringGroupPage from "pages/price_monitoring_group";
import PriceMonitoringProductPage from "pages/price_monitoring_product";
import PriceMonitorinTvViewPage from "pages/price_monitoring_tv_view";
import FBSPage from "pages/fbs";
import DeliveriesPage from "pages/deliveries";
import AnswersBadGoodsPage from "pages/answers_bad_goods";

const Routing = () => {
  const { authorized } = useAuthContext();

  return (
    <BrowserRouter>
      {authorized ? (
        <Routes>
          <Route path="/answers">
            <Route path="bad_goods" element={<AnswersBadGoodsPage />}></Route>
            <Route index element={<AnswersPage />}></Route>
          </Route>
          <Route path="/settings/*" element={<SettingsPage />}></Route>
          <Route path="/sales" element={<SalesPage />}></Route>
          <Route path="/remnants" element={<RemnantsPage />}></Route>
          <Route path="/deliveries" element={<DeliveriesPage />}></Route>
          <Route path="/fbs" element={<FBSPage />}></Route>
          <Route path="/price_loader/">
            <Route
              path="discount_schedule"
              element={<PriceLoaderPage path="discount_schedule" />}
            ></Route>
            <Route index element={<PriceLoaderPage path="index" />}></Route>
          </Route>
          <Route path="/price_monitoring/">
            <Route
              path="groups/:id"
              element={<PriceMonitoringGroupPage />}
            ></Route>
            <Route
              path="products/:id"
              element={<PriceMonitoringProductPage />}
            ></Route>
            <Route path="tv" element={<PriceMonitorinTvViewPage />}></Route>
            <Route index element={<PriceMonitoringPage />}></Route>
          </Route>
          <Route index element={<Navigate to="/answers" replace />}></Route>

          <Route
            path="*"
            element={
              <>
                Такой страницы нет <Link to="/">на главную</Link>
              </>
            }
          ></Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Routing;
