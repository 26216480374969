import fetchApi from "./fetch";
export type SalesProps = {
  date: number;
  orderCount: number;
  revenue: number;
}[];

export type SaleOptions = {
  brands: string[];
  categories: { id: number; name: string }[];
  names: { article: string; name: string }[];
  marketplace: string[];
};

export const getSales = async (props: {
  brand?: string[] | null;
  article?: string[] | null;
  dateFrom?: string;
  dateTo?: string;
  marketplace?: string[] | null;
  categories?: number[] | null;
}) => {
  const result = await fetchApi("/api/sales", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as SalesProps;
  return data;
};

export const getSalesOptions = async () => {
  const result = await fetchApi("/api/sales/options", {
    method: "GET",
  });
  const data = (await result.json()) as SaleOptions;
  console.log(data);
  return data;
};

export const getSalesXlsx = async (
  props: {
    title: string;
    brand?: string[] | null;
    article?: string[] | null;
    dateFrom?: string;
    dateTo?: string;
    marketplace?: string[] | null;
    categories?: number[] | null;
  }[]
) => {
  const result = await fetchApi("/api/sales/xlsx", {
    method: "POST",
    body: JSON.stringify(props),
  });

  return result;
};
