import React from "react";
import { FlexWrap, StyledInput, Td, Title, SubTr, ValueTd } from "./ListStyled";
import { Tooltip } from "@mui/material";
import { Input, Switch } from "antd";
import { Space } from "shared/ui";

export const ItemIntegration = (props: {
  last: boolean;
  data: { name: string; value: string };
}) => {
  return (
    <SubTr last={props.last}>
      <Td colSpan={5}></Td>
      <Td>{props.data.name}</Td>
      <ValueTd>0</ValueTd>
      <Td>
        <StyledInput style={{ width: 70 }} />
      </Td>
      <ValueTd>10</ValueTd>
      <ValueTd>
        <Switch />
      </ValueTd>
    </SubTr>
  );
};
