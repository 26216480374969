import fetchApiWithAuth from "./fetch";

export type AnswerTemplateProps = {
  id: number;
  name: string;
  text: string;
  useForAutogenerate: boolean;
  marketplace: string;
  useForRating: string;
};

export const getAll = async () => {
  const result = await fetchApiWithAuth("/api/answer-templates");
  const data = (await result.json()) as AnswerTemplateProps[];
  return data;
};

export const create = async (props: Omit<AnswerTemplateProps, "id">) => {
  const result = await fetchApiWithAuth("/api/answer-templates", {
    method: "POST",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as AnswerTemplateProps;
  return data;
};

export const edit = async (id: number, props: Partial<AnswerTemplateProps>) => {
  const result = await fetchApiWithAuth(`/api/answer-templates/${id}`, {
    method: "PATCH",
    body: JSON.stringify(props),
  });
  const data = (await result.json()) as AnswerTemplateProps;
  return data;
};

export const remove = async (id: number) => {
  const result = await fetchApiWithAuth(`/api/answer-templates/${id}`, {
    method: "DELETE",
  });

  return result;
};
