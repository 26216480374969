import { useEffect, useState } from "react";
import * as ProductCategoriesApi from "../api/ProductCategories";
import { ProductCategoryProps } from "../api/ProductCategories";
import { getAll, GoodsProps } from "../api/Goods";

export type ItemProductCategoryProps = Omit<ProductCategoryProps, "id">;

export const useProductCategoies = () => {
  const [showNew, setShowNew] = useState(false);
  const [categories, setCategories] = useState<ProductCategoryProps[]>([]);
  const [goods, setGoods] = useState<GoodsProps[]>([]);
  const fetchCategories = async () => {
    const result = await ProductCategoriesApi.getAll();
    setGoods(await getAll());
    setCategories(result.sort((a, b) => (a.name > b.name ? 1 : -1)));
  };
  const createCategory = async (props: ItemProductCategoryProps) => {
    const result = await ProductCategoriesApi.create(props);
    setCategories([result, ...categories]);
    setShowNew(false);
  };
  const updateCategory = async (props: ProductCategoryProps) => {
    await ProductCategoriesApi.edit(props.id, props);
    setCategories(categories.map((el) => (el.id === props.id ? props : el)));
  };
  const deleteCategory = async (id: number) => {
    await ProductCategoriesApi.remove(id);
    setCategories(categories.filter((el) => el.id !== id));
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return {
    categories,
    createCategory,
    showNew,
    setShowNew,
    deleteCategory,
    updateCategory,
    goods,
  };
};
