import React from "react";

import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DataRow } from "../model";

const columns: ColumnsType<DataRow> = [
  {
    title: "article",
    width: 30,
    dataIndex: "article",
    key: "article",
    fixed: "left",
    sorter: true,
  },
  {
    title: "name",
    width: 100,
    dataIndex: "name",
    key: "name",
    fixed: "left",
    sorter: true,
  },
  {
    title: "remains",
    width: 100,
    dataIndex: "remains",
    key: "remains",
    sorter: true,
  },
];

const DeliveriesTable = (props: {
  data: DataRow[];
  onSort: (
    props:
      | {
          column?: any;
          columnKey?: any;
          field?: any;
          order?: "ascend" | "descend" | null;
        }
      | {
          column?: any;
          columnKey?: any;
          field?: any;
          order?: "ascend" | "descend" | null;
        }[]
  ) => void;
}) => {
  return (
    <Table
      columns={columns}
      dataSource={props.data}
      pagination={false}
      onChange={(_, __, a, b) => {
        if (b.action === "sort") props.onSort(a);
      }}
    />
  );
};

export default DeliveriesTable;
