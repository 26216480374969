import React, { useEffect, useState } from "react";
import {
  ProductLink,
  ProductLinkEditor,
  ProductLinkOnSave,
} from "PriceMonitoring/modal";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  createProductLink,
  getProductLinks,
  removeProductLink,
} from "api/PriceMonitoring";
import { Input } from "antd";
import {
  FieldWrap,
  LinkTitle,
  RowWrap,
  Check,
  Close,
  Delete,
  Edit,
  Eye,
  EyeInvisible,
} from "./styled";
import { editProductLink } from "api/PriceMonitoring";
import { confirmPopup } from "shared/ConfirmPopup";

type ProductLinkEditorItemProps = {
  data?: ProductLinkEditor;
  onSave: (value: Partial<ProductLinkEditor>) => void;
  onDelete?: () => void;
};

export function LinkEditorItem({
  data,
  onSave,
  onDelete,
}: ProductLinkEditorItemProps) {
  const [edit, setEdit] = useState(!data);
  const [link, setLink] = useState(data?.link || "");

  const handleSave = () => {
    onSave({ link });
    data && setEdit(false);
  };

  const handleHidden = (value: boolean) => {
    onSave({ hidden: value });
  };

  return (
    <div style={{ maxWidth: 500 }}>
      {!edit && (
        <RowWrap>
          <LinkTitle href={data?.link || ""} target="blank">
            {data?.link}
          </LinkTitle>
          <Edit onClick={() => setEdit(true)} />
          {data?.hidden ? (
            <EyeInvisible onClick={() => handleHidden(false)} />
          ) : (
            <Eye onClick={() => handleHidden(true)} />
          )}
          <Delete onClick={onDelete} />
        </RowWrap>
      )}
      {edit && (
        <RowWrap>
          <Input
            value={link}
            onChange={(e) => setLink(e.target.value)}
            style={{ width: "100%", marginRight: 16 }}
            placeholder="Ссылка на товар на МП"
          />
          <Check onClick={handleSave} />
          {data && <Close onClick={() => setEdit(false)} />}
        </RowWrap>
      )}
    </div>
  );
}

export function LinkEditor(props: { id?: number }) {
  const [pending, setPending] = useState(false);
  const [productLinks, setProductLinks] = useState<ProductLinkEditor[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const result = await getProductLinks(props.id!);
      setProductLinks(result);
      setPending(false);
    };
    if (props.id) {
      fetchData();
    }
  }, [props.id]);

  const onSave: ProductLinkOnSave = async ({ id, data }) => {
    if (!id) {
      const result = await createProductLink(props.id!, data);
      console.log(result);
      setProductLinks([...productLinks, result]);
    } else {
      await editProductLink(props.id!, id, data);
      setProductLinks(
        productLinks.map((el) => (el.id === id ? { ...el, ...data } : el))
      );
    }
  };
  const onDelete = async (id: number) => {
    const result = await confirmPopup(<>Хотите удалить ссылку?</>);
    if (result) {
      await removeProductLink(props.id!, id);
      setProductLinks(productLinks.filter((el) => el.id !== id));
    }
  };
  return (
    <div>
      {productLinks.map((el) => (
        <LinkEditorItem
          key={el.id}
          data={el}
          onSave={(value) => onSave({ id: el.id, data: value })}
          onDelete={() => onDelete(el.id)}
        />
      ))}
      <LinkEditorItem onSave={(value) => onSave({ data: value })} />
    </div>
  );
}
