import React, { useEffect, useState } from "react";
import { LinkEditor } from "./LinkEditor";
import { Button, Checkbox, Col, Form, Input, Row, Typography } from "antd";
import { FieldTitle, FieldWrap } from "./styled";
import { Space } from "shared/ui";
import { useProductEditorState } from "./useProductEditorState";
import { getProduct, createProduct, editProduct } from "api/PriceMonitoring";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

type Props = { id?: number; toPrev: () => void };
const { Title } = Typography;
export default function ProductEdit({ id, toPrev }: Props) {
  const form = useProductEditorState();
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const result = await getProduct(id!);
      form.setValues({
        brand: result.brand,
        internal_product: result.internal_product,
        name: result.name,
        groups: [],
        quantity:result.quantity? result.quantity+"":'',
        boundary_enable: result.price_boundary?.enable || false,
        boundary_lower_bound: result.price_boundary?.lower_bound
          ? result.price_boundary?.lower_bound + ""
          : "",
        boundary_upper_bound: result.price_boundary?.upper_bound
          ? result.price_boundary?.upper_bound + ""
          : "",
      });
      setPending(false);
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const price_boundary = {
      enable: form.values.boundary_enable,
      lower_bound: parseInt(form.values.boundary_lower_bound),
      upper_bound: parseInt(form.values.boundary_upper_bound),
    };
    setPending(true);
    if (id) {
      const result = await editProduct(id, {
        brand: form.values.brand,
        name: form.values.name,
        internal_product: form.values.internal_product,
        quantity: parseFloat(form.values.quantity),
        price_boundary: price_boundary,
      });
      console.log(result);
    } else {
      const result = await createProduct({
        brand: form.values.brand,
        name: form.values.name,
        internal_product: form.values.internal_product,
        quantity: parseFloat(form.values.quantity),
        price_boundary: price_boundary,
      });
      console.log(result);
    }
    setPending(false);
  };
  return (
    <div>
      <Button onClick={toPrev}>
        <LeftOutlined
          style={{
            verticalAlign: "middle",
            marginBottom: 2,
          }}
        />
        Назад
      </Button>
      <Space size={16} axis="vertical" />
      <Title level={4}>Информация о товаре {id && `№ ${id}`}</Title>
      <Space size={16} axis="vertical" />
      <FieldWrap>
        <FieldTitle>Бренд</FieldTitle>
        <Input
          value={form.values.brand}
          onChange={(e) => form.setValue("brand")(e.target.value)}
        />
      </FieldWrap>
      <Space size={16} axis="vertical" />
      <FieldWrap>
        <FieldTitle>Название</FieldTitle>
        <Input
          value={form.values.name}
          onChange={(e) => form.setValue("name")(e.target.value)}
        />
      </FieldWrap>
      <Space size={16} axis="vertical" />
      <FieldWrap>
        <FieldTitle>шт/ед.объема</FieldTitle>
        <Input
          type="number"
          min="0"
          value={form.values.quantity}
          onChange={(e) => form.setValue("quantity")(e.target.value)}
        />
      </FieldWrap>
      <Space size={16} axis="vertical" />
      <FieldWrap>
        <FieldTitle>Наш товар</FieldTitle>
        <Checkbox
          checked={form.values.internal_product}
          onChange={(e) => form.setValue("internal_product")(e.target.checked)}
        />
      </FieldWrap>
      <Space size={24} axis="vertical" />
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <FieldWrap>
            <FieldTitle>Коридор цен</FieldTitle>
            <Form layout="vertical">
              <Form.Item label="Включить">
                <Checkbox
                  checked={form.values.boundary_enable}
                  onChange={(e) =>
                    form.setValue("boundary_enable")(e.target.checked)
                  }
                />
              </Form.Item>
            </Form>
          </FieldWrap>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <Form.Item label="Верхняя граница, %">
              <Input
                type="number"
                value={form.values.boundary_upper_bound}
                onChange={(e) =>
                  form.setValue("boundary_upper_bound")(e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <Form.Item label="Нижняя граница, %">
              <Input
                type="number"
                value={form.values.boundary_lower_bound}
                onChange={(e) =>
                  form.setValue("boundary_lower_bound")(e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      {/* <Space size={16} axis="vertical" /> */}
      {/* <FieldWrap>
        <FieldTitle>Группа</FieldTitle>
        <Input />
      </FieldWrap> */}
      <Space size={16} axis="vertical" />
      <Button type="primary" onClick={onSave}>
        Сохранить
      </Button>
      <Space size={16} style={{ display: "inline-block" }} />
      <Button>Отменить</Button>
      <Space size={64} axis="vertical" />
      <Title level={4}>Ссылки на товар на маркетплейсах</Title>
      <Space size={16} axis="vertical" />
      <LinkEditor id={id} />
    </div>
  );
}
