const userTimezoneOffset = new Date().getTimezoneOffset() * 60000

Date.prototype.toJSONWithoutTimezone = function () {
  return new Date(this.getTime() - userTimezoneOffset).toJSON()
}

Date.prototype.setDateWithoutTimezone = function (dateString: string) {
  return new Date(new Date(dateString).getTime() + userTimezoneOffset)
}

export {}
