import React from "react";
import { Container, Row } from "react-bootstrap";
import PriceLoader from "../PriceLoader";
import DiscountSchedule from "../PriceLoader/DiscountSchedule";
import Layout from "../shared/Layout";
import { Tab, Tabs } from "shared/SwitchTabs";
import { Route, Routes, useNavigate } from "react-router";
import { Space } from "shared/ui";

const PriceLoaderPage = (props: { path: string }) => {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container>
        <Row>
          <Tabs>
            <Tab
              key={"index"}
              isActive={props.path === "index"}
              onSelect={() => {
                props.path !== "index" && navigate("..");
              }}
            >
              Загрузка цен
            </Tab>
            <Tab
              key={"discount_schedule"}
              isActive={props.path === "discount_schedule"}
              onSelect={() => {
                props.path !== "discount_schedule" &&
                  navigate("discount_schedule");
              }}
            >
              Расписание скидок
            </Tab>
          </Tabs>
          <Space size={32} axis="vertical" />
          {props.path === "index" && <PriceLoader />}
          {props.path === "discount_schedule" && <DiscountSchedule />}
        </Row>
      </Container>
    </Layout>
  );
};

export default PriceLoaderPage;
