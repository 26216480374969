import { useEffect, useReducer, useState } from "react";
import { SaleOptions, getSalesOptions } from "../api/Sales";

export type SeriasFilterState = {
  id: number;
  brand: string[];
  name: { article: string; name: string }[];
  category: { id: number; name: string }[];
  marketplace: string[];
  title: string;
};
const getStateDefault = (props: {
  id: number;
  title: string;
}): SeriasFilterState => ({
  id: props.id,
  brand: [],
  name: [],
  category: [],
  marketplace: [],
  title: props.title,
});
type Action =
  | { type: "create" }
  | { type: "update"; data: Partial<SeriasFilterState> & { id: number } }
  | { type: "delete"; id: number }
  | { type: "upload"; data: SeriesState };

type SeriesState = { serias: SeriasFilterState[]; counter: number };

const formReducer = (
  state: SeriesState,
  action: Action
): { serias: SeriasFilterState[]; counter: number } => {
  switch (action.type) {
    case "create": {
      const id = state.counter + 1;
      return {
        counter: id,
        serias: [
          ...state.serias,
          getStateDefault({ id, title: "Серия " + id }),
        ],
      };
    }
    case "update": {
      return {
        ...state,
        serias: state.serias.map((el) =>
          el.id === action.data.id ? Object.assign({}, el, action.data) : el
        ),
      };
    }
    case "delete": {
      return {
        ...state,
        serias: state.serias.filter((el) => el.id !== action.id),
      };
    }
    case "upload": {
      return {
        ...state,
        ...action.data,
        serias: action.data?.serias
          ? action.data.serias.map((el) => ({
              ...el,
              marketplace: el.marketplace || [],
              category: el.category || [],
            }))
          : state.serias,
      };
    }
    default:
      return state;
  }
};
const stateDefault: SeriesState = {
  counter: 0,
  serias: [],
};
const key = "last_series";
type Storage = {
  state: SeriesState;
  startDate: string | null;
  endDate: string | null;
};
const record = (props: Storage) => {
  localStorage.setItem(key, JSON.stringify(props));
};

const read = (): Storage | null => {
  const value = localStorage.getItem(key);
  return value ? (JSON.parse(value) as Storage) : null;
};

const useSeriasFilterState = () => {
  const [state, stateDispatch] = useReducer(formReducer, stateDefault);
  const [loaded, setLoaded] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const updateLocalStorage = () => {
    record({
      state: state,
      startDate: startDate && startDate.toJSONWithoutTimezone().slice(0, 10),
      endDate: endDate && endDate.toJSONWithoutTimezone().slice(0, 10),
    });
  };

  const [options, setOptions] = useState<SaleOptions>({
    brands: [],
    categories: [],
    names: [],
    marketplace: [],
  });

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await getSalesOptions();

      setOptions(result);
    };
    fetchOptions();
  }, []);

  const create = () => {
    stateDispatch({ type: "create" });
  };
  const update = (value: Partial<SeriasFilterState> & { id: number }) => {
    stateDispatch({ type: "update", data: value });
  };
  const remove = (id: number) => {
    stateDispatch({ type: "delete", id });
  };
  const upload = (data: SeriesState) => {
    stateDispatch({ type: "upload", data });
  };

  useEffect(() => {
    const value = read();
    if (value && value.state) {
      upload(value.state);
      setStartDate((value.startDate && new Date(value.startDate)) || null);
      setEndDate((value.endDate && new Date(value.endDate)) || null);
    } else {
      create();
      const startDate = new Date();
      const endDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      setStartDate(startDate);
      setEndDate(endDate);
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    updateLocalStorage();
  }, [state, startDate, endDate]);

  return {
    state,
    create,
    update,
    remove,
    upload,
    options,
    startDate,
    endDate,
    onChangeDate,
  };
};
export type SeriasFilterStateProps = ReturnType<typeof useSeriasFilterState>;
export default useSeriasFilterState;
