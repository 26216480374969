import React from "react";
import { Container, Row } from "react-bootstrap";
import Sales from "../Sales";
import Layout from "../shared/Layout";

const SalesPage = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <Sales />
        </Row>
      </Container>
    </Layout>
  );
};

export default SalesPage;
