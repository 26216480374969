import { ConsoleSqlOutlined } from "@ant-design/icons";
import { Remains } from "Deliveries/model";
import { getRemains } from "api/deliveries/Remains";
import { useEffect, useState } from "react";

export const useRemains = () => {
  const [pendingRemains, setPendingRemains] = useState(false);
  const [remains, setRemains] = useState<Remains>({});
  const [warehouseId, setWarehouseId] = useState<number | null>(null);

  const updateWarehouseId = (value: number) => {
    setWarehouseId(value);
  };
  useEffect(() => {
    (async () => {
      if (!warehouseId) {
        setRemains({});
        return;
      }
      setPendingRemains(true);
      const result = await getRemains(warehouseId);
      setRemains(result.remains);
      setPendingRemains(false);
    })();
  }, [warehouseId]);

  return {
    remains,
    pendingRemains,
    updateWarehouseId,
  };
};
