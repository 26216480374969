import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

interface SpaceProps {
  size?: number;
  inline?: boolean;
  axis?: "horizontal" | "vertical";
}

function getHeight({ axis, size }: SpaceProps) {
  return axis === "horizontal" ? 1 : size;
}
function getWidth({ axis, size }: SpaceProps) {
  return axis === "vertical" ? 1 : size;
}

export const Text = styled.div<{ Grey?: boolean }>`
  font-size: 16px;
  line-height: 22px;
  color: ${(props) =>
    props.Grey ? `rgba(9, 21, 38, 0.6)` : "rgba(9, 21, 38, 0.85)"};
`;

export const H5 = styled.h5`
  font-size: 20px;
  line-height: 28px;
  color: rgba(9, 21, 38, 0.85);
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
`;
export const ContentWrap = styled.div`
  width: 100%;
  flex: 0 1 100%;
`;
export const ContentContainer = styled.div`
  width: 100%;
  padding: 0 28px 28px 80px;
  box-sizing: border-box;
  position: relative;
`;
export const H4 = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: var(--color-black-85);
`;
export const Space = styled.div.attrs(
  (props: SpaceProps): SpaceProps => ({
    ...props,
    axis: props.axis || "horizontal",
  })
)<SpaceProps>`
  display: ${({ inline }) => (inline ? "inline-block" : "block")};

  ${(props) =>
    props.size &&
    css`
      width: ${getWidth}px;
      min-width: ${getWidth}px;
      height: ${getHeight}px;
      min-height: ${getHeight}px;
    `}

  ${(props) =>
    !props.size &&
    css`
      flex: 1 0 auto;
    `}
`;

const Circle = styled(Link)`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
`;
const TitleWithArrowWrap = styled.div`
  display: inline-flex;
  ${Circle} {
    margin-right: 12px;
    flex: 1 0 auto;
  }
`;

export type ButtonColor =
  | "red"
  | "blue"
  | "white"
  | "azure"
  | "green"
  | "light_green"
  | "transparent";

const backgroundColor: Record<
  ButtonColor,
  { default: string; disabled: string; hover: string }
> = {
  red: { default: "#FF6170", disabled: "#FFC9CF", hover: "#FB3E55" },
  blue: {
    default: "#12B7E7",
    disabled: "rgba(9, 21, 38, 0.07)",
    hover: "#00a8d9",
  },
  white: { default: "#FFFFFF", disabled: "#FFFFFF", hover: "#FFFFFF" },
  azure: { default: "#DEEFF3", disabled: "#E3F4F9", hover: "#BDE3E8" },
  green: { default: "#27AE60", disabled: "#6FCF97", hover: "#219653" },
  light_green: { default: "#D7F5E9", disabled: "#D7F5E9", hover: "#D7F5E9" },
  transparent: {
    default: "transparent",
    disabled: "transparent",
    hover: "transparent",
  },
};

const color: Record<ButtonColor, { default: string; disabled: string }> = {
  red: { default: "#ffffff", disabled: "#ffffff" },
  blue: { default: "#ffffff", disabled: "rgba(9, 21, 38, 0.35)" },
  white: {
    default: "rgba(9, 21, 38, 0.85)",
    disabled: "rgba(9, 21, 38, 0.85)",
  },
  azure: {
    default: "rgba(9, 21, 38, 0.85)",
    disabled: "rgba(9, 21, 38, 0.85)",
  },
  green: { default: "#ffffff", disabled: "rgba(255, 255, 255, 0.8)" },
  light_green: {
    default: "rgba(9, 21, 38, 0.85)",
    disabled: "rgba(9, 21, 38, 0.85)",
  },
  transparent: {
    default: "rgba(9, 21, 38, 0.85)",
    disabled: "rgba(9, 21, 38, 0.35)",
  },
};

export const Button = styled.button<{
  isDisabled?: boolean;
  color?: ButtonColor;
}>`
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  padding: 0 16px;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  &,
  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: ${(props) =>
      color[props.color || "blue"][props.isDisabled ? "disabled" : "default"]};
  }
  background-color: ${(props) =>
    backgroundColor[props.color || "blue"][
      props.isDisabled ? "disabled" : "default"
    ]};

  &:hover {
    background-color: ${(props) =>
      backgroundColor[props.color || "blue"].hover};
    ${(props) =>
      props.color === "white" &&
      `box-shadow: 0px 2px 4px rgba(57, 78, 127, 0.2);`};
  }
  pointer-events: ${(props) => (!props.isDisabled ? "auto" : "none")};
`;
export const LinkButton = styled.button<{ isDisabled?: boolean }>`
  border: none;
  box-shadow: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: transparent;
  color: ${(props) =>
    !props.isDisabled ? "rgba(9, 21, 38, 0.85)" : "rgba(9, 21, 38, 0.35)"};
  pointer-events: ${(props) => (!props.isDisabled ? "auto" : "none")};
`;

export const FieldsRow = styled.div`
  display: flex;
`;
export const FieldWrap = styled.div``;
export const FieldTitle = styled(Text)`
  margin-bottom: 12px;
`;

export const LinkStyled = styled.a`
  color: #12b7e7;
  &:active,
  &:hover,
  &:visited {
    color: #12b7e7;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const Hr = styled.div`
  width: 100%;
  height: 1px;
  background: #c4c4c4;
`;
