import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
export type Seria = {
  data: { date: number; orderCount: number; revenue: number }[];
  name: string;
};

const setupChart = (
  root: am5.Root,
  serias: Seria[],
  type: "orderCount" | "revenue" = "orderCount"
) => {
  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      maxTooltipDistance: 0,
      pinchZoomX: true,
    })
  );

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit: "day",
        count: 1,
      },
      markUnitChange: true,
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  );

  (xAxis.get("periodChangeDateFormats") as any)["day"] = "dd-MM";
  (xAxis.get("periodChangeDateFormats") as any)["week"] = "dd-MM";
  (xAxis.get("dateFormats") as any)["week"] = "dd-MM";
  (xAxis.get("dateFormats") as any)["day"] = "dd-MM";
  // xAxis.get("markUnitChange") = false;
  console.log(
    xAxis.get("periodChangeDateFormats"),
    xAxis.get("markUnitChange"),
    xAxis.get("dateFormats")
  );
  var yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  );

  serias.forEach((el) => {
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: el.name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: type,
        valueXField: "date",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{valueY}",
        }),
      })
    );

    series.data.setAll(el.data);
    series.appear();
    series.bullets.push(function (root) {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 4,
          fill: series.get("fill"),
        }),
      });
    });
  });

  var cursor = chart.set(
    "cursor",
    am5xy.XYCursor.new(root, {
      behavior: "none",
    })
  );

  cursor.lineY.set("visible", false);

  // chart.set(
  //   "scrollbarX",
  //   am5.Scrollbar.new(root, {
  //     orientation: "horizontal",
  //   })
  // );

  // chart.set(
  //   "scrollbarY",
  //   am5.Scrollbar.new(root, {
  //     orientation: "vertical",
  //   })
  // );

  // Add legend
  // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
  var legend = chart.rightAxesContainer.children.push(
    am5.Legend.new(root, {
      width: 200,
      paddingLeft: 15,
      height: am5.percent(100),
    })
  );

  // When legend item container is hovered, dim all the series except the hovered one
  legend.itemContainers.template.events.on("pointerover", function (e) {
    const itemContainer = e.target;

    // As series list is data of a legend, dataContext is series
    const series = itemContainer?.dataItem?.dataContext;

    chart.series.each((chartSeries: any) => {
      if (chartSeries !== series) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 0.15,
          stroke: am5.color(0x000000),
        });
      } else {
        chartSeries.strokes.template.setAll({
          strokeWidth: 3,
        });
      }
    });
  });

  legend.itemContainers.template.events.on("pointerout", function (e) {
    chart.series.each(function (chartSeries: any) {
      chartSeries.strokes.template.setAll({
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: chartSeries.get("fill"),
      });
    });
  });

  legend.itemContainers.template.set("width", am5.p100);
  legend.valueLabels.template.setAll({
    width: am5.p100,
    textAlign: "right",
  });

  legend.data.setAll(chart.series.values);

  chart.appear(1000, 100);
};
const Chart = (props: { data: Seria[]; type: string }) => {
  useEffect(() => {
    const root = am5.Root.new("salesChart");
    root.setThemes([am5themes_Animated.new(root)]);
    setupChart(root, props.data, props.type as "orderCount" | "revenue");
    return () => {
      root.dispose();
    };
  }, [props.data, props.type]);
  return (
    <Row>
      <Col>
        <div id="salesChart" style={{ height: 500 }}></div>
      </Col>
    </Row>
  );
};
export default Chart;
