import React, { useEffect } from "react";
import styled from "styled-components";
import { Seria } from "./Chart";
import dayjs from "dayjs";
export const numberWithSpaces = (number: number) =>
  number >= 10000
    ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    : number.toString();

const Wrap = styled.div`
  overflow: auto;
  width: 100%;
  position: relative;
  margin: 32px 0;
  overscroll-behavior-x: contain;
`;
const Table = styled.table`
  position: relative;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
`;

const Td = styled.td`
  border: 1px solid #222222;
  padding: 4px;
  min-width: 120px;
  font-size: 12px;
  background: #ffffff;
`;
const Tr = styled.tr`
  ${Td}:nth-child(1) {
    position: sticky;
    left: 0;
  }
  ${Td}:nth-child(2) {
    position: sticky;
    left: 120px;
  }
`;
const Th = styled.th``;
const Row = (props: { data: string[]; header?: boolean }) => {
  const Col = props.header ? Th : Td;

  return (
    <Tr>
      {props.data.map((el) => (
        <Col>{el}</Col>
      ))}
    </Tr>
  );
};

const SerialTable = (props: { data: Seria[]; type: string }) => {
  const type = props.type as "orderCount" | "revenue";
  const dates = props.data
    .map((el) => el.data.map((el) => el.date))
    .flat()
    .filter((el, i, arr) => arr.indexOf(el) === i)
    .sort();
  console.log(props.data);
  return (
    <Wrap>
      <Table>
        <Row
          data={[
            "Серия",
            "Сумма",
            ...dates.map((el) => dayjs(el).format("DD-MM-YYYY")),
          ]}
        />
        {props.data.map((el) => (
          <Row
            data={[
              el.name,
              numberWithSpaces(
                el.data.reduce((acc, value) => {
                  return acc + value[type];
                }, 0)
              ),
              ...dates.map((date) => {
                const value = el.data.find((data) => data.date === date)?.[
                  type
                ];
                return value ? numberWithSpaces(value) : "";
              }),
            ]}
          />
        ))}
      </Table>
    </Wrap>
  );
};

export default SerialTable;
