import React, { useMemo, useRef, useState } from "react";
import { RemnatsProps, manualSend } from "../api/ProductRemnants";
import {
  StyledTable,
  SmallTd90,
  SmallTd,
  SubTr,
  ArrowUpIcon,
} from "./RemnantsStyled";
import {
  Field,
  getDinamicRemnant,
  getEval,
  FieldWithButton,
  RemnantRowProps,
  getSubRowsData,
  DisabledSend,
} from "./common";
import { Button, Form } from "react-bootstrap";
import { ColumnControl } from "./useColumnControl";

const FullRemnantsItem = (props: {
  data: RemnantRowProps;
  remnants: RemnantRowProps[];
  columnControl: ColumnControl;
  update: (props: {
    article: string;
    value: Partial<RemnatsProps["product_remnants"]>;
  }) => Promise<boolean>;
  hoverCells: { column: string; row: string }[];
  updateDisabledSend: (
    props: {
      article: string;
      value: Partial<DisabledSend>;
    }[]
  ) => Promise<boolean>;
}) => {
  const columnControl = props.columnControl;
  const manulsendPending = useRef(false);
  const [squeezed, setSqueezed] = useState(true);
  const remnant = props.data;
  const handleClick = async () => {
    if (manulsendPending.current) return;
    manulsendPending.current = true;
    await manualSend([{ article: props.data.article }]);
    manulsendPending.current = false;
  };
  const hoveredCells = useMemo(() => {
    return props.hoverCells.filter((el) => el.row === props.data.article);
  }, [props.hoverCells, props.data.article]);
  const subRows = props.data.subRows;

  const rowNotError = props.data.rowNotError;
  const remnantValue = props.data.remnantValue;

  return (
    <>
      <SubTr
        state={
          props.data.is_combo
            ? !rowNotError || !props.data.comboComposition?.length
              ? "absent"
              : ""
            : ""
        }
      >
        <td data-column="all" data-row={props.data.article}>
          {props.data.is_combo && (
            <ArrowUpIcon
              down={squeezed}
              onClick={() => setSqueezed(!squeezed)}
            />
          )}{" "}
          {props.data.article} {props.data.name}
        </td>
        {columnControl.remnantsChecked && (
          <td>
            {remnant.is_combo ? props.data.remnantValue : remnant.remnant}
          </td>
        )}
        {columnControl.reservedChecked && (
          <>
            <SmallTd>{remnant.reserved_in_wildberries}</SmallTd>
            <SmallTd>{remnant.reserved_in_ozon}</SmallTd>
            <SmallTd>{remnant.reserved_in_ya}</SmallTd>
            <SmallTd>{remnant.reserved_in_sber}</SmallTd>
            <SmallTd>{remnant.reserved_in_ali}</SmallTd>
            <SmallTd>{remnant.reserved_in_combo}</SmallTd>
          </>
        )}
        {columnControl.comboDistributionChecked && (
          <>
            <SmallTd90
              data-column={!remnant.is_combo && "combo"}
              data-row={!remnant.is_combo && props.data.article}
              data-selected={
                !remnant.is_combo &&
                !!hoveredCells.find((el) => el.column === "combo")
              }
            >
              {!remnant.is_combo && (
                <Field
                  value={remnant.share_in_combo || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_combo: v },
                    })
                  }
                />
              )}
            </SmallTd90>
            <SmallTd90>
              {!remnant.is_combo ? (
                props.data.useInCombo ? (
                  props.data.remnantToCombo
                ) : (
                  <span style={{ color: "#666666" }}>нет в комбо</span>
                )
              ) : null}
            </SmallTd90>
            <SmallTd90>
              {!remnant.is_combo
                ? props.data.remnantForSingle +
                  (!props.data.useInCombo &&
                  typeof props.data.remnantToCombo === "number"
                    ? props.data.remnantToCombo
                    : 0)
                : null}
            </SmallTd90>
          </>
        )}
        {columnControl.distributionChecked && (
          <>
            <SmallTd90
              data-column={"wb"}
              data-row={props.data.article}
              data-selected={!!hoveredCells.find((el) => el.column === "wb")}
            >
              {!props.data.disabled_send_to_wildberries && (
                <Field
                  value={remnant.share_in_wildberries || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_wildberries: v },
                    })
                  }
                />
              )}
            </SmallTd90>
            <SmallTd90
              data-column={"ozon"}
              data-row={props.data.article}
              data-selected={!!hoveredCells.find((el) => el.column === "ozon")}
            >
              {!props.data.disabled_send_to_ozon && (
                <Field
                  value={remnant.share_in_ozon || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_ozon: v },
                    })
                  }
                />
              )}
            </SmallTd90>
            <SmallTd90
              data-column={"ya"}
              data-row={props.data.article}
              data-selected={!!hoveredCells.find((el) => el.column === "ya")}
            >
              {!props.data.disabled_send_to_ya && (
                <Field
                  value={remnant.share_in_ya || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_ya: v },
                    })
                  }
                />
              )}
            </SmallTd90>
            <SmallTd90
              data-column={"sber"}
              data-row={props.data.article}
              data-selected={!!hoveredCells.find((el) => el.column === "sber")}
            >
              {!props.data.disabled_send_to_sber && (
                <Field
                  value={remnant.share_in_sber || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_sber: v },
                    })
                  }
                />
              )}
            </SmallTd90>
            <SmallTd90
              data-column={"ali"}
              data-row={props.data.article}
              data-selected={!!hoveredCells.find((el) => el.column === "ali")}
            >
              {!props.data.disabled_send_to_ali && (
                <Field
                  value={remnant.share_in_ali || ""}
                  onUpdate={(v) =>
                    props.update({
                      article: props.data.article,
                      value: { share_in_ali: v },
                    })
                  }
                />
              )}
            </SmallTd90>
          </>
        )}
        {columnControl.calculationChecked && (
          <>
            <SmallTd90>
              {!props.data.disabled_send_to_wildberries
                ? props.data.calculeted_wildberries
                : ""}
            </SmallTd90>
            <SmallTd90>
              {!props.data.disabled_send_to_ozon
                ? props.data.calculeted_ozon
                : ""}
            </SmallTd90>
            <SmallTd90>
              {!props.data.disabled_send_to_ya ? props.data.calculeted_ya : ""}
            </SmallTd90>
            <SmallTd90>
              {!props.data.disabled_send_to_sber
                ? props.data.calculeted_sber
                : ""}
            </SmallTd90>
            <SmallTd90>
              {!props.data.disabled_send_to_ali
                ? props.data.calculeted_ali
                : ""}
            </SmallTd90>
          </>
        )}
        {columnControl.actionsChecked && (
          <>
            <SmallTd90>
              <Button size={"sm"} onClick={handleClick}>
                Обновить в МП
              </Button>
            </SmallTd90>
          </>
        )}
        {columnControl.disabledSendByMarketplaceChecked && (
          <>
            <SmallTd90>
              <Form.Check
                type={"checkbox"}
                checked={props.data.disabled_send_to_wildberries || false}
                onChange={(e) =>
                  props.updateDisabledSend([
                    {
                      article: props.data.article,
                      value: {
                        disabled_send_to_wildberries:
                          !props.data.disabled_send_to_wildberries,
                      },
                    },
                  ])
                }
                style={{ marginRight: 16 }}
              ></Form.Check>
            </SmallTd90>
            <SmallTd90>
              <Form.Check
                type={"checkbox"}
                checked={props.data.disabled_send_to_ozon || false}
                onChange={(e) =>
                  props.updateDisabledSend([
                    {
                      article: props.data.article,
                      value: {
                        disabled_send_to_ozon:
                          !props.data.disabled_send_to_ozon,
                      },
                    },
                  ])
                }
                style={{ marginRight: 16 }}
              ></Form.Check>
            </SmallTd90>
            <SmallTd90>
              <Form.Check
                type={"checkbox"}
                checked={props.data.disabled_send_to_ya || false}
                onChange={(e) =>
                  props.updateDisabledSend([
                    {
                      article: props.data.article,
                      value: {
                        disabled_send_to_ya: !props.data.disabled_send_to_ya,
                      },
                    },
                  ])
                }
                style={{ marginRight: 16 }}
              ></Form.Check>
            </SmallTd90>
            <SmallTd90>
              <Form.Check
                type={"checkbox"}
                checked={props.data.disabled_send_to_sber || false}
                onChange={(e) =>
                  props.updateDisabledSend([
                    {
                      article: props.data.article,
                      value: {
                        disabled_send_to_sber:
                          !props.data.disabled_send_to_sber,
                      },
                    },
                  ])
                }
                style={{ marginRight: 16 }}
              ></Form.Check>
            </SmallTd90>
            <SmallTd90>
              <Form.Check
                type={"checkbox"}
                checked={props.data.disabled_send_to_ali || false}
                onChange={(e) =>
                  props.updateDisabledSend([
                    {
                      article: props.data.article,
                      value: {
                        disabled_send_to_ali: !props.data.disabled_send_to_ali,
                      },
                    },
                  ])
                }
                style={{ marginRight: 16 }}
              ></Form.Check>
            </SmallTd90>
          </>
        )}
      </SubTr>
      {!squeezed ? (
        props.data.comboComposition && props.data.comboComposition.length ? (
          subRows.map((el) => (
            <SubTr
              state={
                !el.finded
                  ? "absent"
                  : el.remnantDevValue === remnantValue
                  ? "minimal"
                  : ""
              }
            >
              <td style={{ paddingLeft: 24 }}>{el.name}</td>
              <td colSpan={19}>
                {el.remnantToCombo} / {el.value} = {el.remnantDevValue}
              </td>
            </SubTr>
          ))
        ) : (
          <SubTr state={"absent"}>
            <td colSpan={20}>Не указан состав комбо набора</td>
          </SubTr>
        )
      ) : null}
    </>
  );
};

export default FullRemnantsItem;
