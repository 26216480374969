import React from "react";
import { Col, Container, Row, Button, Tabs, Tab } from "react-bootstrap";
import { AnswerTemplatesItem } from "./AnswerTemplatesItem";
import { ProductCategoiesItem } from "./ProductCategoiesItem";
import { useAnswerTemplates } from "./useAnswerTemplates";

const AnswerTemplates = () => {
  const {
    answerTemplates,
    createAnswerTemplate,
    showNew,
    setShowNew,
    deleteAnswerTemplate,
    updateAnswerTemplate,
  } = useAnswerTemplates();

  return (
    <Container>
      <Row>
        <Col>
          <Button
            style={{ margin: "16px 0" }}
            onClick={() => setShowNew(!showNew)}
          >
            {!showNew ? "Создать шаблон" : "Скрыть форму"}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {showNew && <AnswerTemplatesItem onCreate={createAnswerTemplate} />}
          {answerTemplates.map((el) => (
            <AnswerTemplatesItem
              data={el}
              id={el.id}
              onUpdate={updateAnswerTemplate}
              onDelete={deleteAnswerTemplate}
            />
          ))}
          {!answerTemplates.length && "Нет созданных шаблонов"}
        </Col>
      </Row>
    </Container>
  );
};

export default AnswerTemplates;
